import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ElesAppConst } from '../../@core/utils/eles-app-constant';

@Injectable({
  providedIn: 'root'
})
export class AllroomsinfoService {
  private roomsData = new BehaviorSubject({});
  roomsDataObservable = this.roomsData.asObservable();


  constructor(private http: HttpClient) { }

  changeRoomsDataObservable(data: any) {
    console.log('changeRoomsDataObservable from service',data);
    this.roomsData.next(data);
  }


  SaveVideoRoomsData(RoomsData,doctorId): Observable<any> {
    console.log('SaveVideoRoomsData',RoomsData[0])
    return this.http.post(ElesAppConst.SAVE_VIDEOROOMS_DATA, {
      isPasswordProtected : RoomsData[0].isPasswordProtected,
      isRoomFull: RoomsData[0].isRoomFull,
      participants: RoomsData[0].participants,
      session: RoomsData[0].session,
      sessionid: RoomsData[0].sessionid,
      maxParticipantsAllowed: RoomsData[0].maxParticipantsAllowed,
      isFull: RoomsData[0].isFull,
      doctorId
         });
}
}
