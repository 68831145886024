import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ElesAppConst } from '../@core/utils/eles-app-constant';
import { StringConst } from '../@core/utils/string-constants';

@Injectable({
    providedIn: 'root'
})


export class VideoService {

  private roomdata = new BehaviorSubject(null);
  roomdataObserver = this.roomdata.asObservable();



    constructor(public http: HttpClient) { }

  changeroomdataObserver(data){
    console.log('changeRoomdataObserver',data)
    this.roomdata.next(data);
  }
    // callTurnServer(): Observable<any> {
    //     return this.http.get(ElesAppConst.CALL_TURN_SERVER);
    // }

    videoCall(params): Observable<any> {
      return this.http.post(ElesAppConst.VIDEO_CALL, params);
  }

  videoCallOpen(params): Observable<any> {
    return this.http.post(ElesAppConst.VIDEO_CALL_OPEN, params);
}
  makeRoomComplete(params): Observable<any> {
    return this.http.post(ElesAppConst.MAKE_ROOM_COMPLETE, params);
}

inviteOtherUser(params): Observable<any> {
  return this.http.post(ElesAppConst.INVITE_OTHER_USER, params);
}

getChatsWithAppointmentId(params): Observable<any> {
  return this.http.post(ElesAppConst.GET_CHAT_WITH_APPOINTMENT_ID, params);
}

getChatsWithAppointmentIdOpen(params): Observable<any> {
  return this.http.post(ElesAppConst.GET_CHAT_WITH_APPOINTMENT_ID_OPEN, params);
}

getRecordingsList(params): Observable<any> {
  return this.http.post(ElesAppConst.GET_RECORDINGS_LIST, params);
}

downloadRecording(params): Observable<any> {
  const headers = new HttpHeaders({
    'content-type': 'application/json',
    'Content-Type': 'application/json', 
    'Accept': 'video/mp4'
  });

  return this.http.post(ElesAppConst.DOWNLOAD_RECORDING, params, {headers : headers, responseType: 'blob' });
}

viewRecording(params): Observable<any> {
  return this.http.post(ElesAppConst.PLAY_RECORDING, params);
}

deleteRecordings(params): Observable<any> {
  return this.http.post(ElesAppConst.DELETE_RECORDINGS, params);
}

  }
