import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Modal } from '../models/users';
import { NotificationService } from "../../@core/services/notification.service";
import { StringConst } from '../utils/string-constants';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  comment = "";
  notifyBy = "email";
  reason = "";
  countryList = StringConst.countryList;
  userType = "";
  userTypes = [{name: "Lafiya User", value: "lafiyauser"},
               {name: "Other", value: "other"}];
  countryCode ="";
  phoneNumber = "";
  userName = "";
  userEmail = "";
  constructor(private notificationService: NotificationService,  public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any) {

    }

  ngOnInit() {
  }
  handleClick() {
    if (this.config.eventFire) {
      this.config.eventFire();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  approveUser(){
    let obj ={comment: this.comment, notifyBy: this.notifyBy, isApprove: true}
    this.dialogRef.close(obj);
  }
  rejectUser(){
    let obj ={comment: this.comment, notifyBy: this.notifyBy, isApprove: false}
    this.dialogRef.close(obj);
  }

  reportIssue(){
    if(!this.reason || this.reason == ""){
      this.notificationService.errorToast("please enter reason");
      return;
    }
    let obj ={reason: this.reason, isSuccess: false}
    this.dialogRef.close(obj);
  }

  endSessionSuccess(){
    let obj ={isSuccess: true}
    this.dialogRef.close(obj);
  }
  inviteUser(){
    if(!this.userType){
      return;
    }
    if(this.userType && this.userType == 'other'){
      if(!this.userName || !this.phoneNumber || !this.countryCode){
        this.notificationService.errorToast("please fill details");
        return;
      }
    } 
    else if(this.userType && this.userType == 'lafiyauser'){
      if(!this.userEmail ){
        this.notificationService.errorToast("please fill details");
        return;
      }
    }

    let obj ={ type: this.userType == 'other' ? 'phone' : 'email'}
    if(this.userType == 'other'){
      obj['name'] =  'phone_' + this.userName;
      obj['to'] = this.countryCode + this.phoneNumber
    }
    else if(this.userType == 'lafiyauser'){
      obj['email'] = this.userEmail;
    }
    this.dialogRef.close(obj);
  }
}
