import CryptoJS from 'crypto-js';
export class SharedData {
    private keySize = 256;
    private ivSize = 128;
    private iterations = 100;
    private SECRET = 'lthp@$12&*01';
    encriptData(encText) {
        try {
            const salt = CryptoJS.lib.WordArray.random(128 / 8);
            const key = CryptoJS.PBKDF2(this.SECRET, salt, {
                keySize: this.keySize / 32,
                iterations: this.iterations
              });
            const iv = CryptoJS.lib.WordArray.random(128 / 8);
            const encrypted = CryptoJS.AES.encrypt(encText, key, {
                iv: iv,
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC
            });
            const encryptedData = salt.toString() + iv.toString() + encrypted.toString();
            return encryptedData;
        } catch (e) {
            return e;
        }
    }
    decriptData(decText) {
        try {
            const salt = CryptoJS.enc.Hex.parse(decText.substr(0, 32));
            const iv = CryptoJS.enc.Hex.parse(decText.substr(32, 32));
            const encrypted = decText.substring(64);
            const key = CryptoJS.PBKDF2(this.SECRET, salt, {
                keySize: this.keySize / 32,
                iterations: this.iterations
                });

            const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
                iv: iv,
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC
            });
        return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            return error;
        }
    }
}
