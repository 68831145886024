import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
import { MaterialModule } from "./core-module/material.module";
import { InterceptorService } from "./@core/services/authentication-interceptor.service";
import { ForgetPasswordComponent } from "./auth/forget-password/forget-password.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AllroomsinfoService } from "./shared/services/allroomsinfo.service";
import { PaymentsuccessComponent } from "./paymentsuccess/paymentsuccess.component";
import { PaymenterrComponent } from "./paymenterr/paymenterr.component";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { AuthGuard } from "./@core/guards/auth.guard";
import { TeleconsultationGuard } from "./@core/guards/teleconsultation.guard";
import { CacheRouteReuseStrategy } from "./@core/utils/cache-route-reuse.strategy";
import { RouteReuseStrategy } from "@angular/router";
import { AccessGuard } from "./@core/guards/access.guard";
import { PasswordComponent } from "./password/password.component";
// import { AngularSignaturePadModule } from "angular-signature-pad";
// import { SignaturePadModule } from "angular2-signaturepad";

import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { SignaturePadModule } from "angular2-signaturepad";
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {OpenConsultationComponent} from "./layout/users/open-consultation/open-consultation.component"
import { environment } from '../environments/environment';
import {OpenTeleconsultationGuard} from './@core/guards/teleconsultation-open.guard';
import {MatMenuModule} from '@angular/material';
import {CookieService} from 'ngx-cookie-service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {RequestVisitDoctorComponent} from 'src/app/layout/users/doctor-list/request-visit-doctor/request-visit-doctor'
import {ReminderVisitComponent} from 'src/app/layout/users/appointments/appointment-notification/reminder-visit'
import { USGScannerComponent } from "./layout/users/usg_scanner/usg_scanner_component";

const config: SocketIoConfig = { url: environment.apiUrl, options: {} };

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http); 
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SignaturePadModule,
    SocketIoModule.forRoot(config),
   
    //AngularSignaturePadModule.forRoot(),
    // AppStoreModule,
    // EffectsModule.forRoot([AppEffects]),
    MatMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      },
      
    })
  ],
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    ForgetPasswordComponent,
    PaymentsuccessComponent,
    PaymenterrComponent,
    PasswordComponent,
    OpenConsultationComponent,
    RequestVisitDoctorComponent,
    ReminderVisitComponent,
    USGScannerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy },
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    AllroomsinfoService,
    JwtHelperService,
    AccessGuard,
    AuthGuard,
    TeleconsultationGuard,
    OpenTeleconsultationGuard,
    CookieService
  ],
  bootstrap: [AppComponent],
  entryComponents:[RequestVisitDoctorComponent, ReminderVisitComponent]
})
export class AppModule {}
