export const qualifications = [
    { value: 'RN', viewValue: 'RN' },
    { value: 'BSN', viewValue: 'BSN' },
    { value: 'MSN', viewValue: 'MSN' },
    { value: 'PharmB', viewValue: 'PharmB' },
    { value: 'MD', viewValue: 'MD' },
    { value: 'DO', viewValue: 'DO' },
    { value: 'MBBS', viewValue: 'MBBS' },
    { value: 'MBChB', viewValue: 'MBChB' },
    { value: 'PharmM', viewValue: 'PharmM' },
    { value: 'DNP', viewValue: 'DNP' },
    { value: 'DMD', viewValue: 'DMD' },
    { value: 'DDS', viewValue: 'DDS' },
    { value: 'DPM', viewValue: 'DPM' },
    { value: 'EdD', viewValue: 'EdD' },
    { value: 'PsyD', viewValue: 'PsyD' },
    { value: 'PhD', viewValue: 'PhD' },
    { value: 'PharmD', viewValue: 'PharmD' },
];

export const appointmentTypes = [
    {
      name: 'Video & Voice Call + Chat',
      check: false,
    },
    {
      name: 'Voice Call & Chat',
      check: false,
    },
    {
      name: 'Voice Call Only',
      check: false,
    },
    {
      name: 'Chat Only',
      check: false,
    },
  ];

export const specializations = [
    { value: 'ADHD And Autism', viewValue: 'ADHD And Autism' },
    { value: 'Addiction Medicine', viewValue: 'Addiction Medicine' },
    { value: 'Adolescent Medicine', viewValue: 'Adolescent Medicine' },
    { value: 'Aerospace Medicine', viewValue: 'Aerospace Medicine' },
    { value: 'Aesthetic Medicine', viewValue: 'Aesthetic Medicine' },
    { value: 'Allergy', viewValue: 'Allergy' },
    { value: 'Allergy And Immunology', viewValue: 'Allergy And Immunology' },
    {
        value: 'Anesthesiology',
        viewValue: 'Anesthesiology',
    },
    { value: 'Anti-Aging Medicine', viewValue: 'Anti-Aging Medicine' },
    { value: 'Bariatrics', viewValue: 'Bariatrics' },
    { value: 'Breast Surgery', viewValue: 'Breast Surgery' },
    { value: 'Cancer', viewValue: 'Cancer' },
    {
        value: 'Cardiac Electrophysiology',
        viewValue: 'Cardiac Electrophysiology',
    },
    { value: 'Cardiology', viewValue: 'Cardiology' },

    {
        value: 'Cardiothoracic Surgery',
        viewValue: 'Cardiothoracic Surgery',
    },
    { value: 'Child Psychiatry', viewValue: 'Child Psychiatry' },
    { value: 'Clinical Genetics', viewValue: 'Clinical Genetics' },
    { value: 'Clinical Lipidology', viewValue: 'Clinical Lipidology' },
    {
        value: 'Clinical Neurophysiology',
        viewValue: 'Clinical Neurophysiology',
    },
    { value: 'Clinical Psychology', viewValue: 'Clinical Psychology' },
    {
        value: 'Colon And Rectal Surgery',
        viewValue: 'Colon And Rectal Surgery',
    },
    { value: 'Cosmetic Dentistry', viewValue: 'Cosmetic Dentistry' },
    { value: 'Critical Care', viewValue: 'Critical Care' },

    { value: 'Cytopathology', viewValue: 'Cytopathology' },

    { value: 'Dentistry', viewValue: 'Dentistry' },
    {
        value: 'Dermatology',
        viewValue: 'Dermatology',
    },
    { value: 'Dermatopathology', viewValue: 'Dermatopathology' },
    {
        value: 'Developmental And Behavioral Pediatrics',
        viewValue: 'Developmental And Behavioral Pediatrics',
    },

    { value: 'Diabetology', viewValue: 'Diabetology' },
    { value: 'Diagnostic Radiology', viewValue: 'Diagnostic Radiology' },

    {
        value: 'ENT And Head And Neck Surgery',
        viewValue: 'ENT And Head And Neck Surgery',
    },
    {
        value: 'Ear, Nose And Throat Surgery',
        viewValue: 'Ear, Nose And Throat Surgery',
    },
    { value: 'Emergency Medicine', viewValue: 'Emergency Medicine' },
    { value: 'Endocrinology', viewValue: 'Endocrinology' },
    { value: 'Endodontics', viewValue: 'Endodontics' },
    { value: 'Environmental Health', viewValue: 'Environmental Health' },
    { value: 'Facial Plastic Surgery', viewValue: 'Facial Plastic Surgery' },
    { value: 'Family Medicine', viewValue: 'Family Medicine' },
    { value: 'Fertility Medicine', viewValue: 'Fertility Medicine' },
    { value: 'Forensic Pathology', viewValue: 'Forensic Pathology' },
    { value: 'Functional Medicine', viewValue: 'Functional Medicine' },
    { value: 'Gastroenterology', viewValue: 'Gastroenterology' },
    { value: "General Practitioners", viewValue: "General Practitioners" },
    { value: 'General Surgery', viewValue: 'General Surgery' },
    { value: 'Genetics Counseling', viewValue: 'Genetics Counseling' },
    { value: 'Geriatric Psychiatry', viewValue: 'Geriatric Psychiatry' },
    { value: 'Geriatrics', viewValue: 'Geriatrics' },
    { value: 'Gynecologic Oncology', viewValue: 'Gynecologic Oncology' },
    { value: 'Gynecology', viewValue: 'Gynecology' },
    { value: 'Hair Restoration', viewValue: 'Hair Restoration' },
    { value: 'Hand Surgery', viewValue: 'Hand Surgery' },
    { value: 'Head And Neck Surgery', viewValue: 'Head And Neck Surgery' },
    { value: 'Healthcare Professional', viewValue: 'Healthcare Professional' },
    { value: 'Hematology', viewValue: 'Hematology' },
    { value: 'Hematology And Oncology', viewValue: 'Hematology And Oncology' },
    { value: 'Hepatology', viewValue: 'Hepatology' },
    { value: 'Histopathology', viewValue: 'Histopathology' },
    { value: 'Holistic Medicine', viewValue: 'Holistic Medicine' },
    { value: 'Hospital-Based Practice', viewValue: 'Hospital-Based Practice' },
    { value: 'Immunology', viewValue: 'Immunology' },
    { value: 'Infectious Disease', viewValue: 'Infectious Disease' },
    { value: 'Integrative Medicine', viewValue: 'Integrative Medicine' },
    { value: 'Internal Medicine', viewValue: 'Internal Medicine' },
    {
        value: 'Interventional Cardiology',
        viewValue: 'Interventional Cardiology',
    },
    {
        value: 'Interventional Pulmonology',
        viewValue: 'Interventional Pulmonology',
    },
    { value: 'Legal Medicine', viewValue: 'Legal Medicine' },
    { value: 'Maternal-Fetal Medicine', viewValue: 'Maternal-Fetal Medicine' },
    { value: 'Medical Oncology', viewValue: 'Medical Oncology' },
    { value: 'Medical Ophthalmology', viewValue: 'Medical Ophthalmology' },
    { value: 'Medical Psychotherapy', viewValue: 'Medical Psychotherapy' },
    { value: 'Neonatology', viewValue: 'Neonatology' },
    { value: 'Nephrology And Dialysis', viewValue: 'Nephrology And Dialysis' },
    { value: 'Neurology', viewValue: 'Neurology' },
    { value: 'Neuromuscular Medicine', viewValue: 'Neuromuscular Medicine' },
    { value: 'Neuropathology', viewValue: 'Neuropathology' },
    { value: 'Neuroradiology', viewValue: 'Neuroradiology' },
    { value: 'Neurosurgery', viewValue: 'Neurosurgery' },
    { value: 'Nuclear Medicine', viewValue: 'Nuclear Medicine' },
    { value: 'Nutritionist', viewValue: 'Nutritionist' },
    { value: 'Obstetric Medicine', viewValue: 'Obstetric Medicine' },
    {
        value: 'Obstetrics And Gynecology',
        viewValue: 'Obstetrics And Gynecology',
    },

    { value: 'Occupational Medicine', viewValue: 'Occupational Medicine' },
    { value: 'Oncology', viewValue: 'Oncology' },
    { value: 'Ophthalmology', viewValue: 'Ophthalmology' },
    { value: 'Optometry', viewValue: 'Optometry' },
    {
        value: 'Oral Facial Pain Management',
        viewValue: 'Oral Facial Pain Management',
    },
    { value: 'Oral Surgery', viewValue: 'Oral Surgery' },
    {
        value: 'Oral And Maxillofacial Surgery',
        viewValue: 'Oral And Maxillofacial Surgery',
    },
    { value: 'Orthodontics', viewValue: 'Orthodontics' },
    {
        value: 'Orthopedic Foot And Ankle Surgery',
        viewValue: 'Orthopedic Foot And Ankle Surgery',
    },
    {
        value: 'Orthopedic Reconstructive Surgery',
        viewValue: 'Orthopedic Reconstructive Surgery',
    },
    {
        value: 'Orthopedic Spine Surgery',
        viewValue: 'Orthopedic Spine Surgery',
    },
    { value: 'Orthopedic Surgery', viewValue: 'Orthopedic Surgery' },
    { value: 'Pain Management', viewValue: 'Pain Management' },
    { value: 'Palliative Care', viewValue: 'Palliative Care' },
    { value: 'Pathology', viewValue: 'Pathology' },
    { value: 'Pediatric Allergy', viewValue: 'Pediatric Allergy' },
    {
        value: 'Pediatric Allergy And Asthma',
        viewValue: 'Pediatric Allergy And Asthma',
    },
    {
        value: 'Pediatric Anesthesiology',
        viewValue: 'Pediatric Anesthesiology',
    },
    { value: 'Pediatric Cardiology', viewValue: 'Pediatric Cardiology' },
    { value: 'Pediatric Critical Care', viewValue: 'Pediatric Critical Care' },
    { value: 'Pediatric Dentistry', viewValue: 'Pediatric Dentistry' },
    { value: 'Pediatric Dermatology', viewValue: 'Pediatric Dermatology' },
    {
        value: 'Pediatric ENT And Head And Neck Surgery',
        viewValue: 'Pediatric ENT And Head And Neck Surgery',
    },
    {
        value: 'Pediatric Electrophysiology',
        viewValue: 'Pediatric Electrophysiology',
    },
    {
        value: 'Pediatric Emergency Medicine',
        viewValue: 'Pediatric Emergency Medicine',
    },
    { value: 'Pediatric Endocrinology', viewValue: 'Pediatric Endocrinology' },
    {
        value: 'Pediatric Gastroenterology',
        viewValue: 'Pediatric Gastroenterology',
    },
    {
        value: 'Pediatric Hematology And Oncology',
        viewValue: 'Pediatric Hematology And Oncology',
    },
    {
        value: 'Pediatric Infectious Disease',
        viewValue: 'Pediatric Infectious Disease',
    },
    {
        value: 'Pediatric Nephrology And Dialysis',
        viewValue: 'Pediatric Nephrology And Dialysis',
    },
    { value: 'Pediatric Neurology', viewValue: 'Pediatric Neurology' },
    { value: 'Pediatric Oncology', viewValue: 'Pediatric Oncology' },
    {
        value: 'Pediatric Orthopedic Surgery',
        viewValue: 'Pediatric Orthopedic Surgery',
    },
    { value: 'Pediatric Pulmonology', viewValue: 'Pediatric Pulmonology' },
    {
        value: 'Pediatric Rehabilitation Medicine',
        viewValue: 'Pediatric Rehabilitation Medicine',
    },
    { value: 'Pediatric Rheumatology', viewValue: 'Pediatric Rheumatology' },
    {
        value: 'Pediatric Sports Medicine',
        viewValue: 'Pediatric Sports Medicine',
    },
    { value: 'Pediatric Surgery', viewValue: 'Pediatric Surgery' },
    { value: 'Pediatric Urology', viewValue: 'Pediatric Urology' },
    { value: 'Pediatrics', viewValue: 'Pediatrics' },
    { value: 'Periodontics', viewValue: 'Periodontics' },
    { value: 'Pharmacology', viewValue: 'Pharmacology' },
    { value: 'Phlebology', viewValue: 'Phlebology' },
    {
        value: 'Physical Medicine And Rehabilitation',
        viewValue: 'Physical Medicine And Rehabilitation',
    },
    { value: 'Plastic Surgery', viewValue: 'Plastic Surgery' },
    { value: 'Podiatric Surgery', viewValue: 'Podiatric Surgery' },
    { value: 'Podiatry', viewValue: 'Podiatry' },
    { value: 'Preventive Medicine', viewValue: 'Preventive Medicine' },
    { value: 'Proctology', viewValue: 'Proctology' },
    { value: 'Prosthetics and Orthotics', viewValue: 'Prosthetics and Orthotics' },
    { value: 'Prosthodontics', viewValue: 'Prosthodontics' },

    { value: 'Psychiatry', viewValue: 'Psychiatry' },
    { value: 'Public Health', viewValue: 'Public Health' },
    { value: 'Pulmonary Critical Care', viewValue: 'Pulmonary Critical Care' },
    { value: 'Pulmonology', viewValue: 'Pulmonology' },
    { value: 'Radiation Oncology', viewValue: 'Radiation Oncology' },
    { value: 'Radiology', viewValue: 'Radiology' },
    { value: 'Retinal Surgery', viewValue: 'Retinal Surgery' },
    { value: 'Rheumatology', viewValue: 'Rheumatology' },
    {
        value: 'Sexual Health Practitioner',
        viewValue: 'Sexual Health Practitioner',
    },
    { value: 'Sleep Medicine', viewValue: 'Sleep Medicine' },
    { value: 'Sports Medicine', viewValue: 'Sports Medicine' },
    { value: 'Surgical Oncology', viewValue: 'Surgical Oncology' },

    { value: 'Thoracic Surgery', viewValue: 'Thoracic Surgery' },
    { value: 'Toxicology', viewValue: 'Toxicology' },
    { value: 'Transfusion Medicine', viewValue: 'Transfusion Medicine' },
    { value: 'Transplant Surgery', viewValue: 'Transplant Surgery' },
    { value: 'Trauma Surgery', viewValue: 'Trauma Surgery' },
    {
        value: 'Trauma And Orthopaedic Surgery',
        viewValue: 'Trauma And Orthopaedic Surgery',
    },
    { value: 'Travel Medicine', viewValue: 'Travel Medicine' },
    {
        value: 'Undersea And Hyperbaric Medicine',
        viewValue: 'Undersea And Hyperbaric Medicine',
    },
    { value: 'Urgent Care', viewValue: 'Urgent Care' },

    { value: 'Urogynecology', viewValue: 'Urogynecology' },
    { value: 'Urologic Oncology', viewValue: 'Urologic Oncology' },
    { value: 'Urology', viewValue: 'Urology' },
    { value: 'Vascular Surgery', viewValue: 'Vascular Surgery' },
    { value: 'Wilderness Medicine', viewValue: 'Wilderness Medicine' },
    { value: 'Wound Care', viewValue: 'Wound Care' },
];

export const languageList = [
    'Abkhaz',
    'Afar',
    'Afrikaans',
    'Akan',
    'Albanian',
    'Amharic',
    'Arabic',
    'Aragonese',
    'Armenian',
    'Assamese',
    'Avaric',
    'Avestan',
    'Aymara',
    'Azerbaijani',
    'Bambara',
    'Bashkir',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bihari',
    'Bislama',
    'Bosnian',
    'Breton',
    'Bulgarian',
    'Burmese',
    'Catalan; Valencian',
    'Chamorro',
    'Chechen',
    'Chichewa; Chewa; Nyanja',
    'Chinese',
    'Chuvash',
    'Cornish',
    'Corsican',
    'Cree',
    'Croatian',
    'Czech',
    'Danish',
    'Divehi; Dhivehi; Maldivian;',
    'Dutch',
    'English',
    'Esperanto',
    'Estonian',
    'Ewe',
    'Faroese',
    'Fijian',
    'Finnish',
    'French',
    'Fula; Fulah; Pulaar; Pular',
    'Galician',
    'Georgian',
    'German',
    'Greek, Modern',
    'Guaraní',
    'Gujarati',
    'Haitian; Haitian Creole',
    'Hausa',
    'Hebrew (modern)',
    'Herero',
    'Hindi',
    'Hiri Motu',
    'Hungarian',
    'Interlingua',
    'Indonesian',
    'Interlingue',
    'Irish',
    'Igbo',
    'Inupiaq',
    'Ido',
    'Icelandic',
    'Italian',
    'Inuktitut',
    'Japanese',
    'Javanese',
    'Kalaallisut, Greenlandic',
    'Kannada',
    'Kanuri',
    'Kashmiri',
    'Kazakh',
    'Khmer',
    'Kikuyu, Gikuyu',
    'Kinyarwanda',
    'Kirghiz, Kyrgyz',
    'Komi',
    'Kongo',
    'Korean',
    'Kurdish',
    'Kwanyama, Kuanyama',
    'Latin',
    'Luxembourgish, Letzeburgesch',
    'Luganda',
    'Limburgish, Limburgan, Limburger',
    'Lingala',
    'Lao',
    'Lithuanian',
    'Luba-Katanga',
    'Latvian',
    'Manx',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Māori',
    'Marathi (Marāṭhī)',
    'Marshallese',
    'Mongolian',
    'Nauru',
    'Navajo, Navaho',
    'Norwegian Bokmål',
    'North Ndebele',
    'Nepali',
    'Ndonga',
    'Norwegian Nynorsk',
    'Norwegian',
    'Nuosu',
    'South Ndebele',
    'Occitan',
    'Ojibwe, Ojibwa',
    'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    'Oromo',
    'Oriya',
    'Ossetian, Ossetic',
    'Panjabi, Punjabi',
    'Pāli',
    'Persian',
    'Polish',
    'Pashto, Pushto',
    'Portuguese',
    'Quechua',
    'Romansh',
    'Kirundi',
    'Romanian, Moldavian, Moldovan',
    'Russian',
    'Sanskrit (Saṁskṛta)',
    'Sardinian',
    'Sindhi',
    'Northern Sami',
    'Samoan',
    'Sango',
    'Serbian',
    'Scottish Gaelic; Gaelic',
    'Shona',
    'Sinhala, Sinhalese',
    'Slovak',
    'Slovene',
    'Somali',
    'Southern Sotho',
    'Spanish; Castilian',
    'Sundanese',
    'Swahili',
    'Swati',
    'Swedish',
    'Tamil',
    'Telugu',
    'Tajik',
    'Thai',
    'Tigrinya',
    'Tibetan Standard, Tibetan, Central',
    'Turkmen',
    'Tagalog',
    'Tswana',
    'Tonga (Tonga Islands)',
    'Turkish',
    'Tsonga',
    'Tatar',
    'Twi',
    'Tahitian',
    'Uighur, Uyghur',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Venda',
    'Vietnamese',
    'Volapük',
    'Walloon',
    'Welsh',
    'Wolof',
    'Western Frisian',
    'Xhosa',
    'Yiddish',
    'Yoruba',
    'Zhuang, Chuang'
 ];

 export const WeightScaleParamerters = {
    BMI: {
      name: "Body Mass Index (BMI)",
      unit: "",
      range: {
        start: 18.5,
        end: 25,
      },
    },
    BMR: {
      name: "Basal Metabolic Rate (BMR)",
      unit: "",
      range: {
        start: "",
        end: "",
      },
    },
    "Bone Mass": {
      name: "Bone Mass",
      unit: "",
      range: {
        start: "",
        end: "",
      },
    },
    Fat: {
      name: "Fat percentage",
      unit: "%",
      range: {
        start: "",
        end: "",
      },
    },
    "Ideal Weight": {
      name: "Ideal Weight",
      unit: "kg",
      range: {
        start: "",
        end: "",
      },
    },
    "weight": {
      name: "Weight",
      unit: "kg",
      range: {
        start: "",
        end: "",
      },
    },
    "LBM Coefficient": {
      name: "LBM Coefficient",
      unit: "",
      range: {
        start: "",
        end: "",
      },
    },
    "Metabolic Age": {
      name: "Metabolic Age",
      unit: "",
      range: {
        start: "",
        end: "",
      },
    },
    "Muscle Mass": {
      name: "Muscle Mass",
      unit: "",
      range: {
        start: "",
        end: "",
      },
    },
    "Protein Percentage": {
      name: "Protein Percentage",
      unit: "",
      range: {
        start: "",
        end: "",
      },
    },
    "Visceral Fat": {
      name: "Visceral Fat",
      unit: "",
      range: {
        start: "",
        end: "",
      },
    },
  }

  export const aiHealthCheckerResultParameters = {
    healthScore: [
      {
        start: 0,
        end: 20,
        class: '#EB5757'
      },
      {
        start: 20,
        end: 40,
        class: '#f98889'
      },
      {
        start: 40,
        end: 60,
        class: '#FFA412'
      },
      {
        start: 60,
        end: 80,
        class: '#3CD856'
      },
      {
        start: 80,
        end: Infinity,
        class: '#3CD856'
      },
    ],
    ppm: [
      {
        start: 0,
        end: 60,
        class: '#FFA412'
      },
      {
        start: 60,
        end: 100,
        class: '#3CD856'
      },
      {
        start: 100,
        end: Infinity,
        class: '#FFA412'
      },
    ],
    irregularHeartBeats: [],
    breathing:[
      {
        start: 0,
        end: 12,
        class: '#FFA412'
      },
      {
        start: 12,
        end: 25,
        class: '#3CD856'
      },
      {
        start: 25,
        end: Infinity,
        class: '#FFA412'
      },
    ],
    heartRateVariability:[
      {
        start: 0,
        end: 10.8,
        class: '#EB5757'
      },
      {
        start: 10.8,
        end: 16.4,
        class: '#f98889'
      },
      {
        start: 16.4,
        end: 35.5,
        class: '#FFA412'
      },
      {
        start: 35.5,
        end: 51.5,
        class: '#3CD856'
      },
      {
        start: 51.5,
        end: Infinity,
        class: '#3CD856'
      },
    ],
    systolic: [
      {
        start: 0,
        end: 90,
        class: '#FFA412'
      },
      {
        start: 90,
        end: 120,
        class: '#3CD856'
      },
      {
        start: 120,
        end: 130,
        class: '#3CD856'
      },
      {
        start: 130,
        end: 140,
        class: '#FFA412'
      },
      {
        start: 140,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    diastolic :[
      {
        start: 0,
        end: 60,
        class: '#FFA412'
      },
      {
        start: 60,
        end: 70,
        class: '#3CD856'
      },
      {
        start: 70,
        end: 80,
        class: '#3CD856'
      },
      {
        start: 80,
        end: 90,
        class: '#FFA412'
      },
      {
        start: 90,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    bmi:[
      {
        start: 0,
        end: 18.5,
        class: '#FFA412'
      },
      {
        start: 18.5,
        end: 25,
        class: '#3CD856'
      },
      {
        start: 25,
        end: 30,
        class: '#FFA412'
      },
      {
        start: 30,
        end: 35,
        class: '#f98889'
      },
      {
        start: 35,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    absi:[
      {
        start: 0,
        end: 7.07,
        class: '#3CD856'
      },
      {
        start: 7.07,
        end: 7.95,
        class: '#FFA412'
      },
      {
        start: 7.95,
        end: 8.39,
        class: '#f98889'
      },
      {
        start: 8.39,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    msi:[
      {
        start: 1,
        end: 2,
        class: '#3CD856'
      },
      {
        start: 2,
        end: 3,
        class: '#3CD856'
      },
      {
        start: 3,
        end: 4,
        class: '#FFA412'
      },
      {
        start: 4,
        end: 5,
        class: '#f98889'
      },
      {
        start: 5,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    age: [],
    waistToHeight: [
      {
        start: 0,
        end: 43,
        class: '#FFA412'
      },
      {
        start: 43,
        end: 53,
        class: '#3CD856'
      },
      {
        start: 53,
        end: 58,
        class: '#FFA412'
      },
      {
        start: 58,
        end: 63,
        class: '#f98889'
      },
      {
        start: 63,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    cardiacWorkload: [
      {
        start: 0,
        end: 3.8,
        class: '#3CD856'
      },
      {
        start: 3.8,
        end: 3.9,
        class: '#3CD856'
      },
      {
        start: 3.9,
        end: 4.08,
        class: '#FFA412'
      },
      {
        start: 4.08,
        end: 4.18,
        class: '#f98889'
      },
      {
        start: 4.18,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    cvdRisk:[
      {
        start: 0,
        end: 5,
        class: '#3CD856'
      },
      {
        start: 5,
        end: 7.25,
        class: '#3CD856'
      },
      {
        start: 7.25,
        end: 10,
        class: '#FFA412'
      },
      {
        start: 10,
        end: 20,
        class: '#f98889'
      },
      {
        start: 20,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    heartAttackRisk:[
      {
        start: 0,
        end: 1.65,
        class: '#3CD856'
      },
      {
        start: 1.65,
        end: 2.39,
        class: '#3CD856'
      },
      {
        start: 2.39,
        end: 3.3,
        class: '#FFA412'
      },
      {
        start: 3.3,
        end: 6.6,
        class: '#f98889'
      },
      {
        start: 6.6,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    strokeRisk:[
      {
        start: 0,
        end: 3.3,
        class: '#3CD856'
      },
      {
        start: 3.3,
        end: 4.79,
        class: '#3CD856'
      },
      {
        start: 4.79,
        end: 6.6,
        class: '#FFA412'
      },
      {
        start: 6.6,
        end: 13.2,
        class: '#f98889'
      },
      {
        start: 13.2,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    HypertensionRisk: [
      {
        start: 0,
        end: 5,
        class: '#3CD856'
      },
      {
        start: 5,
        end: 7.25,
        class: '#3CD856'
      },
      {
        start: 7.25,
        end: 10,
        class: '#FFA412'
      },
      {
        start: 10,
        end: 20,
        class: '#f98889'
      },
      {
        start: 20,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    HypertriglyceridemiaRisk: [
      {
        start: 0,
        end: 5,
        class: '#3CD856'
      },
      {
        start: 5,
        end: 7.25,
        class: '#3CD856'
      },
      {
        start: 7.25,
        end: 10,
        class: '#FFA412'
      },
      {
        start: 10,
        end: 20,
        class: '#f98889'
      },
      {
        start: 20,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    HypercholesterolemiaRisk: [
      {
        start: 0,
        end: 5,
        class: '#3CD856'
      },
      {
        start: 5,
        end: 7.25,
        class: '#3CD856'
      },
      {
        start: 7.25,
        end: 10,
        class: '#FFA412'
      },
      {
        start: 10,
        end: 20,
        class: '#f98889'
      },
      {
        start: 20,
        end: Infinity,
        class: '#EB5757'
      },
    ],
    DiabetesRisk: [
      {
        start: 0,
        end: 5,
        class: '#3CD856'
      },
      {
        start: 5,
        end: 7.25,
        class: '#3CD856'
      },
      {
        start: 7.25,
        end: 10,
        class: '#FFA412'
      },
      {
        start: 10,
        end: 20,
        class: '#f98889'
      },
      {
        start: 20,
        end: Infinity,
        class: '#EB5757'
      },
    ],
  }