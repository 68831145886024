
import { environment } from '../../../environments/environment';
export class StringConst {
    // private static awspath = environment.awspath;
    // private static awsbucket = environment.bucketname;
    public static get APP_NAME(): string { return 'BAC Couriers'; };
    public static get ADMIN_LOCAL_STORAGE_NAME(): string { return '__lafi__ad'; };
    public static get CONSUMER_LOCAL_STORAGE_NAME(): string { return 'c__lafi'; };
    public static get CART_LOCAL_STORAGE_NAME(): string { return '__eSt__CI'; };
    public static get MURAL_LOCAL_STORAGE_NAME(): string { return '__eSt__MI'; };
    public static get RECENT_LOCAL_STORAGE_NAME(): string { return '__eSt__RS'; };
    public static get USER_TYPE(): string { return 'user_type'; };

    public static get INVALID_INFORMATION(): string { return "Please Enter Valid Information"; };
    public static get INVALID_EMAIL(): string { return "Invalid email"; };
    public static get NOT_FOUND(): string { return 'Not Found'; };
    public static get PLEASE_LOGIN(): string { return 'Please Login'; };
    public static get SOMETHING_WENT_WRONG(): string { return 'Something Went Wrong.'; };
    public static get PLEASE_TRY_AGAIN_LATER(): string { return 'Please Try Again Later'; };
    public static get PLEASE_SELECT_IMAGE(): string { return 'Please Try Again Later'; };

    // public static get AWS_PATH(): string { return this.awspath + this.awsbucket; };

    public static get SELECT(): string { return 'Select one record'; };

    public static get AUTHENTICATION_MODULE_URL(): string { return '/user'; };

    public static get NOT_MODIFIED(): number { return 0 };
    public static get NEW(): number { return 1 };
    public static get MODIFIED(): number { return 2 };
    public static get DELETED(): number { return 3 };
    public static get PRODUCT_IMAGE_RESTRICT_MB(): number { return 4 };
    public static get SHIPPING_PERCENTAGE(): number { return 0 };

    public static get MATERIAL_TYPE(): string[] { return ["Removable", "Non-Removable"]; };
    //Form validations
    public static get PLEASE_ENTER_DOSAGE(): string { return "Please Enter Dosage for every medicine"; };
    public static get PRESCRIPTION_SAVED(): string { return "Prescription Saved"; };
    public static get NO_MEDICINE_SELECTED(): string { return "No Medicine Selected"; };
    public static get PLEASE_ENTER_ONLY_CHARACTERS(): string { return 'Please enter only characters!'; };
    public static get PLEASE_ENTER_CORRECT_OR_COMPLETE_EMAIL(): string { return 'Please enter correct or complete email!'; };
    public static get PLEASE_ENTER_TEN_DIGIT_CONTACT_NUMBER(): string { return 'Please enter 10 digit contact number!'; };
    public static get PLEASE_ENTER_ONLY_CONTACT_NUMBER(): string { return 'Please enter only contact number!'; };
    public static get PLEASE_ENTER_COST_OF_CART(): string { return 'Please enter cost of cart!'; };
    public static get PLEASE_ENTER_WEIGHT_IN_POUNDS(): string { return 'Please enter number!'; };
    public static PATIENT_DIAGNOSIS=[
        {value: 'p1', viewValue: 'Hypertension'},
        {value: 'p2', viewValue: 'Hyperlipidemia'},
        {value: 'p3', viewValue: 'Diabetes'},
        {value: 'p4', viewValue: 'Back pain'},
        {value: 'p5', viewValue: 'Anxiety'},
        {value: 'p6', viewValue: 'Obesity'},
        {value: 'p7', viewValue: 'Allergic rhinitis'},
        {value: 'p8', viewValue: 'Respiratory problems'},
        {value: 'p9', viewValue: 'Hypothyroidism'},
        {value: 'p10', viewValue: 'Visual refractive errors'},
        {value: 'p11', viewValue: 'General medical exam'},
        {value: 'p12', viewValue: 'Osteoarthritis'},
        {value: 'p13', viewValue: 'Fibromyalgia / myositis'},
        {value: 'p14', viewValue: 'Pain in joint'},
        {value: 'p15', viewValue: 'Acute laryngopharyngitis'},
        {value: 'p16', viewValue: 'Acute maxillary sinusitis'},
        {value: 'p17', viewValue: 'Major depressive disorder'},
        {value: 'p18', viewValue: 'Acute bronchitis'},
        {value: 'p19', viewValue: 'Asthma'},
        {value: 'p20', viewValue: 'Depressive disorder'},
        {value: 'p21', viewValue: 'Nail fungus'},
        {value: 'p22', viewValue: 'Coronary atherosclerosis'},
        {value: 'p23', viewValue: 'Tuberculosis'},
        {value: 'p24', viewValue: 'Urinary tract infection'},
        {value: 'p25', viewValue: 'Others'},
        {value: 'p26', viewValue: 'None'},
      ];

   public static SPECIALITIES=[
  { value: "ADHD And Autism", viewValue: "ADHD And Autism" },
  { value: "Addiction Medicine", viewValue: "Addiction Medicine" },
  { value: "Adolescent Medicine", viewValue: "Adolescent Medicine" },
  { value: "Aerospace Medicine", viewValue: "Aerospace Medicine" },
  { value: "Aesthetic Medicine", viewValue: "Aesthetic Medicine" },
  { value: "Allergy", viewValue: "Allergy" },
  { value: "Allergy And Immunology", viewValue: "Allergy And Immunology" },
  {
    value: "Anesthesiology",
    viewValue: "Anesthesiology",
  },
  { value: "Anti-Aging Medicine", viewValue: "Anti-Aging Medicine" },
  { value: "Bariatrics", viewValue: "Bariatrics" },
  { value: "Breast Surgery", viewValue: "Breast Surgery" },
  { value: 'Cancer', viewValue: 'Cancer' },
  {
    value: "Cardiac Electrophysiology",
    viewValue: "Cardiac Electrophysiology",
  },
  { value: "Cardiology", viewValue: "Cardiology" },

  {
    value: "Cardiothoracic Surgery",
    viewValue: "Cardiothoracic Surgery",
  },
  { value: "Child Psychiatry", viewValue: "Child Psychiatry" },
  { value: "Clinical Genetics", viewValue: "Clinical Genetics" },
  { value: "Clinical Lipidology", viewValue: "Clinical Lipidology" },
  {
    value: "Clinical Neurophysiology",
    viewValue: "Clinical Neurophysiology",
  },
  { value: "Clinical Psychology", viewValue: "Clinical Psychology" },
  {
    value: "Colon And Rectal Surgery",
    viewValue: "Colon And Rectal Surgery",
  },
  { value: "Cosmetic Dentistry", viewValue: "Cosmetic Dentistry" },
  { value: "Critical Care", viewValue: "Critical Care" },

  { value: "Cytopathology", viewValue: "Cytopathology" },

  { value: "Dentistry", viewValue: "Dentistry" },
  {
    value: "Dermatology",
    viewValue: "Dermatology",
  },
  { value: "Dermatopathology", viewValue: "Dermatopathology" },
  {
    value: "Developmental And Behavioral Pediatrics",
    viewValue: "Developmental And Behavioral Pediatrics",
  },

  { value: "Diabetology", viewValue: "Diabetology" },
  { value: "Diagnostic Radiology", viewValue: "Diagnostic Radiology" },

  {
    value: "ENT And Head And Neck Surgery",
    viewValue: "ENT And Head And Neck Surgery",
  },
  {
    value: "Ear, Nose And Throat Surgery",
    viewValue: "Ear, Nose And Throat Surgery",
  },
  { value: "Emergency Medicine", viewValue: "Emergency Medicine" },
  { value: "Endocrinology", viewValue: "Endocrinology" },
  { value: "Endodontics", viewValue: "Endodontics" },
  { value: "Environmental Health", viewValue: "Environmental Health" },
  { value: "Facial Plastic Surgery", viewValue: "Facial Plastic Surgery" },
  { value: "Family Medicine", viewValue: "Family Medicine" },
  { value: "Fertility Medicine", viewValue: "Fertility Medicine" },
  { value: 'Forensic Pathology', viewValue: 'Forensic Pathology' },
  { value: "Functional Medicine", viewValue: "Functional Medicine" },
  { value: "Gastroenterology", viewValue: "Gastroenterology" },
  { value: "General Practitioners", viewValue: "General Practitioners" },
  { value: "General Surgery", viewValue: "General Surgery" },
  { value: "Genetics Counseling", viewValue: "Genetics Counseling" },
  { value: "Geriatric Psychiatry", viewValue: "Geriatric Psychiatry" },
  { value: "Geriatrics", viewValue: "Geriatrics" },
  { value: "Gynecologic Oncology", viewValue: "Gynecologic Oncology" },
  { value: "Gynecology", viewValue: "Gynecology" },
  { value: "Hair Restoration", viewValue: "Hair Restoration" },
  { value: "Hand Surgery", viewValue: "Hand Surgery" },
  { value: "Head And Neck Surgery", viewValue: "Head And Neck Surgery" },
  { value: "Healthcare Professional", viewValue: "Healthcare Professional" },
  { value: "Hematology", viewValue: "Hematology" },
  { value: "Hematology And Oncology", viewValue: "Hematology And Oncology" },
  { value: "Hepatology", viewValue: "Hepatology" },
  { value: "Histopathology", viewValue: "Histopathology" },
  { value: "Holistic Medicine", viewValue: "Holistic Medicine" },
  { value: "Hospital-Based Practice", viewValue: "Hospital-Based Practice" },
  { value: "Immunology", viewValue: "Immunology" },
  { value: "Infectious Disease", viewValue: "Infectious Disease" },
  { value: "Integrative Medicine", viewValue: "Integrative Medicine" },
  { value: "Internal Medicine", viewValue: "Internal Medicine" },
  {
    value: "Interventional Cardiology",
    viewValue: "Interventional Cardiology",
  },
  {
    value: "Interventional Pulmonology",
    viewValue: "Interventional Pulmonology",
  },
  { value: "Legal Medicine", viewValue: "Legal Medicine" },
  { value: "Maternal-Fetal Medicine", viewValue: "Maternal-Fetal Medicine" },
  { value: "Medical Oncology", viewValue: "Medical Oncology" },
  { value: "Medical Ophthalmology", viewValue: "Medical Ophthalmology" },
  { value: "Medical Psychotherapy", viewValue: "Medical Psychotherapy" },
  { value: "Neonatology", viewValue: "Neonatology" },
  { value: "Nephrology And Dialysis", viewValue: "Nephrology And Dialysis" },
  { value: "Neurology", viewValue: "Neurology" },
  { value: "Neuromuscular Medicine", viewValue: "Neuromuscular Medicine" },
  { value: "Neuropathology", viewValue: "Neuropathology" },
  { value: "Neuroradiology", viewValue: "Neuroradiology" },
  { value: "Neurosurgery", viewValue: "Neurosurgery" },
  { value: "Nuclear Medicine", viewValue: "Nuclear Medicine" },
  { value: 'Nutritionist', viewValue: 'Nutritionist' },
  { value: "Obstetric Medicine", viewValue: "Obstetric Medicine" },
  {
    value: "Obstetrics And Gynecology",
    viewValue: "Obstetrics And Gynecology",
  },
  { value: 'Oncology', viewValue: 'Oncology' },
  { value: "Occupational Medicine", viewValue: "Occupational Medicine" },
  { value: "Ophthalmology", viewValue: "Ophthalmology" },
  { value: 'Optometry', viewValue: 'Optometry' },
  {
    value: "Oral Facial Pain Management",
    viewValue: "Oral Facial Pain Management",
  },
  { value: "Oral Surgery", viewValue: "Oral Surgery" },
  {
    value: "Oral And Maxillofacial Surgery",
    viewValue: "Oral And Maxillofacial Surgery",
  },
  { value: "Orthodontics", viewValue: "Orthodontics" },
  {
    value: "Orthopedic Foot And Ankle Surgery",
    viewValue: "Orthopedic Foot And Ankle Surgery",
  },
  {
    value: "Orthopedic Reconstructive Surgery",
    viewValue: "Orthopedic Reconstructive Surgery",
  },
  {
    value: "Orthopedic Spine Surgery",
    viewValue: "Orthopedic Spine Surgery",
  },
  { value: "Orthopedic Surgery", viewValue: "Orthopedic Surgery" },
  { value: "Pain Management", viewValue: "Pain Management" },
  { value: "Palliative Care", viewValue: "Palliative Care" },
  { value: "Pathology", viewValue: "Pathology" },
  { value: "Pediatric Allergy", viewValue: "Pediatric Allergy" },
  {
    value: "Pediatric Allergy And Asthma",
    viewValue: "Pediatric Allergy And Asthma",
  },
  {
    value: "Pediatric Anesthesiology",
    viewValue: "Pediatric Anesthesiology",
  },
  { value: "Pediatric Cardiology", viewValue: "Pediatric Cardiology" },
  { value: "Pediatric Critical Care", viewValue: "Pediatric Critical Care" },
  { value: "Pediatric Dentistry", viewValue: "Pediatric Dentistry" },
  { value: "Pediatric Dermatology", viewValue: "Pediatric Dermatology" },
  {
    value: "Pediatric ENT And Head And Neck Surgery",
    viewValue: "Pediatric ENT And Head And Neck Surgery",
  },
  {
    value: "Pediatric Electrophysiology",
    viewValue: "Pediatric Electrophysiology",
  },
  {
    value: "Pediatric Emergency Medicine",
    viewValue: "Pediatric Emergency Medicine",
  },
  { value: "Pediatric Endocrinology", viewValue: "Pediatric Endocrinology" },
  {
    value: "Pediatric Gastroenterology",
    viewValue: "Pediatric Gastroenterology",
  },
  {
    value: "Pediatric Hematology And Oncology",
    viewValue: "Pediatric Hematology And Oncology",
  },
  {
    value: "Pediatric Infectious Disease",
    viewValue: "Pediatric Infectious Disease",
  },
  {
    value: "Pediatric Nephrology And Dialysis",
    viewValue: "Pediatric Nephrology And Dialysis",
  },
  { value: "Pediatric Neurology", viewValue: "Pediatric Neurology" },
  { value: "Pediatric Oncology", viewValue: "Pediatric Oncology" },
  {
    value: "Pediatric Orthopedic Surgery",
    viewValue: "Pediatric Orthopedic Surgery",
  },
  { value: "Pediatric Pulmonology", viewValue: "Pediatric Pulmonology" },
  {
    value: "Pediatric Rehabilitation Medicine",
    viewValue: "Pediatric Rehabilitation Medicine",
  },
  { value: "Pediatric Rheumatology", viewValue: "Pediatric Rheumatology" },
  {
    value: "Pediatric Sports Medicine",
    viewValue: "Pediatric Sports Medicine",
  },
  { value: "Pediatric Surgery", viewValue: "Pediatric Surgery" },
  { value: "Pediatric Urology", viewValue: "Pediatric Urology" },
  { value: "Pediatrics", viewValue: "Pediatrics" },
  { value: "Periodontics", viewValue: "Periodontics" },
  { value: "Pharmacology", viewValue: "Pharmacology" },
  { value: "Phlebology", viewValue: "Phlebology" },
  {
    value: "Physical Medicine And Rehabilitation",
    viewValue: "Physical Medicine And Rehabilitation",
  },
  { value: "Plastic Surgery", viewValue: "Plastic Surgery" },
  { value: "Podiatric Surgery", viewValue: "Podiatric Surgery" },
  { value: "Podiatry", viewValue: "Podiatry" },
  { value: "Preventive Medicine", viewValue: "Preventive Medicine" },
  { value: "Proctology", viewValue: "Proctology" },
  { value: 'Prosthetics and Orthotics', viewValue: 'Prosthetics and Orthotics' },
  { value: "Prosthodontics", viewValue: "Prosthodontics" },

  { value: "Psychiatry", viewValue: "Psychiatry" },
  { value: "Public Health", viewValue: "Public Health" },
  { value: "Pulmonary Critical Care", viewValue: "Pulmonary Critical Care" },
  { value: "Pulmonology", viewValue: "Pulmonology" },
  { value: "Radiation Oncology", viewValue: "Radiation Oncology" },
  { value: "Radiology", viewValue: "Radiology" },
  { value: "Retinal Surgery", viewValue: "Retinal Surgery" },
  { value: "Rheumatology", viewValue: "Rheumatology" },
  {
    value: "Sexual Health Practitioner",
    viewValue: "Sexual Health Practitioner",
  },
  { value: "Sleep Medicine", viewValue: "Sleep Medicine" },
  { value: "Sports Medicine", viewValue: "Sports Medicine" },
  { value: "Surgical Oncology", viewValue: "Surgical Oncology" },

  { value: "Thoracic Surgery", viewValue: "Thoracic Surgery" },
  { value: "Toxicology", viewValue: "Toxicology" },
  { value: "Transfusion Medicine", viewValue: "Transfusion Medicine" },
  { value: "Transplant Surgery", viewValue: "Transplant Surgery" },
  { value: "Trauma Surgery", viewValue: "Trauma Surgery" },
  {
    value: "Trauma And Orthopaedic Surgery",
    viewValue: "Trauma And Orthopaedic Surgery",
  },
  { value: "Travel Medicine", viewValue: "Travel Medicine" },
  {
    value: "Undersea And Hyperbaric Medicine",
    viewValue: "Undersea And Hyperbaric Medicine",
  },
  { value: "Urgent Care", viewValue: "Urgent Care" },

  { value: "Urogynecology", viewValue: "Urogynecology" },
  { value: "Urologic Oncology", viewValue: "Urologic Oncology" },
  { value: "Urology", viewValue: "Urology" },
  { value: "Vascular Surgery", viewValue: "Vascular Surgery" },
  { value: "Wilderness Medicine", viewValue: "Wilderness Medicine" },
  { value: "Wound Care", viewValue: "Wound Care" },
];      
      public static RELATIONSHIP=[
        {value: 'p1', viewValue: 'Father'},
        {value: 'p2', viewValue: 'Mother'},
        {value: 'p3', viewValue: 'Wife'},
        {value: 'p4', viewValue: 'Daughter'},
        {value: 'p5', viewValue: 'Son'},
        {value: 'p5', viewValue: 'Other'},
        ];
        public static currencyMapping = {
          BD: "BDT",
          BE: "EUR",
          BF: "XOF",
          BG: "BGN",
          BA: "BAM",
          BB: "BBD",
          WF: "XPF",
          BL: "EUR",
          BM: "BMD",
          BN: "BND",
          BO: "BOB",
          BH: "BHD",
          BI: "BIF",
          BJ: "XOF",
          BT: "BTN",
          JM: "JMD",
          BV: "NOK",
          BW: "BWP",
          WS: "WST",
          BQ: "USD",
          BR: "BRL",
          BS: "BSD",
          JE: "GBP",
          BY: "BYR",
          BZ: "BZD",
          RU: "RUB",
          RW: "RWF",
          RS: "RSD",
          TL: "USD",
          RE: "EUR",
          TM: "TMT",
          TJ: "TJS",
          RO: "RON",
          TK: "NZD",
          GW: "XOF",
          GU: "USD",
          GT: "GTQ",
          GS: "GBP",
          GR: "EUR",
          GQ: "XAF",
          GP: "EUR",
          JP: "JPY",
          GY: "GYD",
          GG: "GBP",
          GF: "EUR",
          GE: "GEL",
          GD: "XCD",
          GB: "GBP",
          GA: "XAF",
          SV: "USD",
          GN: "GNF",
          GM: "GMD",
          GL: "DKK",
          GI: "GIP",
          GH: "GHS",
          OM: "OMR",
          TN: "TND",
          JO: "JOD",
          HR: "HRK",
          HT: "HTG",
          HU: "HUF",
          HK: "HKD",
          HN: "HNL",
          HM: "AUD",
          VE: "VEF",
          PR: "USD",
          PS: "ILS",
          PW: "USD",
          PT: "EUR",
          SJ: "NOK",
          PY: "PYG",
          IQ: "IQD",
          PA: "PAB",
          PF: "XPF",
          PG: "PGK",
          PE: "PEN",
          PK: "PKR",
          PH: "PHP",
          PN: "NZD",
          PL: "PLN",
          PM: "EUR",
          ZM: "ZMK",
          EH: "MAD",
          EE: "EUR",
          EG: "EGP",
          ZA: "ZAR",
          EC: "USD",
          IT: "EUR",
          VN: "VND",
          SB: "SBD",
          ET: "ETB",
          SO: "SOS",
          ZW: "ZWL",
          SA: "SAR",
          ES: "EUR",
          ER: "ERN",
          ME: "EUR",
          MD: "MDL",
          MG: "MGA",
          MF: "EUR",
          MA: "MAD",
          MC: "EUR",
          UZ: "UZS",
          MM: "MMK",
          ML: "XOF",
          MO: "MOP",
          MN: "MNT",
          MH: "USD",
          MK: "MKD",
          MU: "MUR",
          MT: "EUR",
          MW: "MWK",
          MV: "MVR",
          MQ: "EUR",
          MP: "USD",
          MS: "XCD",
          MR: "MRO",
          IM: "GBP",
          UG: "UGX",
          TZ: "TZS",
          MY: "MYR",
          MX: "MXN",
          IL: "ILS",
          FR: "EUR",
          IO: "USD",
          SH: "SHP",
          FI: "EUR",
          FJ: "FJD",
          FK: "FKP",
          FM: "USD",
          FO: "DKK",
          NI: "NIO",
          NL: "EUR",
          NO: "NOK",
          NA: "NAD",
          VU: "VUV",
          NC: "XPF",
          NE: "XOF",
          NF: "AUD",
          NG: "NGN",
          NZ: "NZD",
          NP: "NPR",
          NR: "AUD",
          NU: "NZD",
          CK: "NZD",
          XK: "EUR",
          CI: "XOF",
          CH: "CHF",
          CO: "COP",
          CN: "CNY",
          CM: "XAF",
          CL: "CLP",
          CC: "AUD",
          CA: "CAD",
          CG: "XAF",
          CF: "XAF",
          CD: "CDF",
          CZ: "CZK",
          CY: "EUR",
          CX: "AUD",
          CR: "CRC",
          CW: "ANG",
          CV: "CVE",
          CU: "CUP",
          SZ: "SZL",
          SY: "SYP",
          SX: "ANG",
          KG: "KGS",
          KE: "KES",
          SS: "SSP",
          SR: "SRD",
          KI: "AUD",
          KH: "KHR",
          KN: "XCD",
          KM: "KMF",
          ST: "STD",
          SK: "EUR",
          KR: "KRW",
          SI: "EUR",
          KP: "KPW",
          KW: "KWD",
          SN: "XOF",
          SM: "EUR",
          SL: "SLL",
          SC: "SCR",
          KZ: "KZT",
          KY: "KYD",
          SG: "SGD",
          SE: "SEK",
          SD: "SDG",
          DO: "DOP",
          DM: "XCD",
          DJ: "DJF",
          DK: "DKK",
          VG: "USD",
          DE: "EUR",
          YE: "YER",
          DZ: "DZD",
          US: "USD",
          UY: "UYU",
          YT: "EUR",
          UM: "USD",
          LB: "LBP",
          LC: "XCD",
          LA: "LAK",
          TV: "AUD",
          TW: "TWD",
          TT: "TTD",
          TR: "TRY",
          LK: "LKR",
          LI: "CHF",
          LV: "EUR",
          TO: "TOP",
          LT: "LTL",
          LU: "EUR",
          LR: "LRD",
          LS: "LSL",
          TH: "THB",
          TF: "EUR",
          TG: "XOF",
          TD: "XAF",
          TC: "USD",
          LY: "LYD",
          VA: "EUR",
          VC: "XCD",
          AE: "AED",
          AD: "EUR",
          AG: "XCD",
          AF: "AFN",
          AI: "XCD",
          VI: "USD",
          IS: "ISK",
          IR: "IRR",
          AM: "AMD",
          AL: "ALL",
          AO: "AOA",
          AQ: "",
          AS: "USD",
          AR: "ARS",
          AU: "AUD",
          AT: "EUR",
          AW: "AWG",
          IN: "INR",
          AX: "EUR",
          AZ: "AZN",
          IE: "EUR",
          ID: "IDR",
          UA: "UAH",
          QA: "QAR",
          MZ: "MZN"
        };

        public static countryList = [
          {
          "name": "Afghanistan",
          "dial_code": "+93",
          "code": "AF"
          },
          {
          "name": "Aland Islands",
          "dial_code": "+358",
          "code": "AX"
          },
          {
          "name": "Albania",
          "dial_code": "+355",
          "code": "AL"
          },
          {
          "name": "Algeria",
          "dial_code": "+213",
          "code": "DZ"
          },
          {
          "name": "AmericanSamoa",
          "dial_code": "+1684",
          "code": "AS"
          },
          {
          "name": "Andorra",
          "dial_code": "+376",
          "code": "AD"
          },
          {
          "name": "Angola",
          "dial_code": "+244",
          "code": "AO"
          },
          {
          "name": "Anguilla",
          "dial_code": "+1264",
          "code": "AI"
          },
          {
          "name": "Antarctica",
          "dial_code": "+672",
          "code": "AQ"
          },
          {
          "name": "Antigua and Barbuda",
          "dial_code": "+1268",
          "code": "AG"
          },
          {
          "name": "Argentina",
          "dial_code": "+54",
          "code": "AR"
          },
          {
          "name": "Armenia",
          "dial_code": "+374",
          "code": "AM"
          },
          {
          "name": "Aruba",
          "dial_code": "+297",
          "code": "AW"
          },
          {
          "name": "Australia",
          "dial_code": "+61",
          "code": "AU"
          },
          {
          "name": "Austria",
          "dial_code": "+43",
          "code": "AT"
          },
          {
          "name": "Azerbaijan",
          "dial_code": "+994",
          "code": "AZ"
          },
          {
          "name": "Bahamas",
          "dial_code": "+1242",
          "code": "BS"
          },
          {
          "name": "Bahrain",
          "dial_code": "+973",
          "code": "BH"
          },
          {
          "name": "Bangladesh",
          "dial_code": "+880",
          "code": "BD"
          },
          {
          "name": "Barbados",
          "dial_code": "+1246",
          "code": "BB"
          },
          {
          "name": "Belarus",
          "dial_code": "+375",
          "code": "BY"
          },
          {
          "name": "Belgium",
          "dial_code": "+32",
          "code": "BE"
          },
          {
          "name": "Belize",
          "dial_code": "+501",
          "code": "BZ"
          },
          {
          "name": "Benin",
          "dial_code": "+229",
          "code": "BJ"
          },
          {
          "name": "Bermuda",
          "dial_code": "+1441",
          "code": "BM"
          },
          {
          "name": "Bhutan",
          "dial_code": "+975",
          "code": "BT"
          },
          {
          "name": "Bolivia, Plurinational State of",
          "dial_code": "+591",
          "code": "BO"
          },
          {
          "name": "Bosnia and Herzegovina",
          "dial_code": "+387",
          "code": "BA"
          },
          {
          "name": "Botswana",
          "dial_code": "+267",
          "code": "BW"
          },
          {
          "name": "Brazil",
          "dial_code": "+55",
          "code": "BR"
          },
          {
          "name": "British Indian Ocean Territory",
          "dial_code": "+246",
          "code": "IO"
          },
          {
          "name": "Brunei Darussalam",
          "dial_code": "+673",
          "code": "BN"
          },
          {
          "name": "Bulgaria",
          "dial_code": "+359",
          "code": "BG"
          },
          {
          "name": "Burkina Faso",
          "dial_code": "+226",
          "code": "BF"
          },
          {
          "name": "Burundi",
          "dial_code": "+257",
          "code": "BI"
          },
          {
          "name": "Cambodia",
          "dial_code": "+855",
          "code": "KH"
          },
          {
          "name": "Cameroon",
          "dial_code": "+237",
          "code": "CM"
          },
          {
          "name": "Canada",
          "dial_code": "+1",
          "code": "CA"
          },
          {
          "name": "Cape Verde",
          "dial_code": "+238",
          "code": "CV"
          },
          {
          "name": "Cayman Islands",
          "dial_code": "+ 345",
          "code": "KY"
          },
          {
          "name": "Central African Republic",
          "dial_code": "+236",
          "code": "CF"
          },
          {
          "name": "Chad",
          "dial_code": "+235",
          "code": "TD"
          },
          {
          "name": "Chile",
          "dial_code": "+56",
          "code": "CL"
          },
          {
          "name": "China",
          "dial_code": "+86",
          "code": "CN"
          },
          {
          "name": "Christmas Island",
          "dial_code": "+61",
          "code": "CX"
          },
          {
          "name": "Cocos (Keeling) Islands",
          "dial_code": "+61",
          "code": "CC"
          },
          {
          "name": "Colombia",
          "dial_code": "+57",
          "code": "CO"
          },
          {
          "name": "Comoros",
          "dial_code": "+269",
          "code": "KM"
          },
          {
          "name": "Congo",
          "dial_code": "+242",
          "code": "CG"
          },
          {
          "name": "Congo, The Democratic Republic of the Congo",
          "dial_code": "+243",
          "code": "CD"
          },
          {
          "name": "Cook Islands",
          "dial_code": "+682",
          "code": "CK"
          },
          {
          "name": "Costa Rica",
          "dial_code": "+506",
          "code": "CR"
          },
          {
          "name": "Cote d'Ivoire",
          "dial_code": "+225",
          "code": "CI"
          },
          {
          "name": "Croatia",
          "dial_code": "+385",
          "code": "HR"
          },
          {
          "name": "Cuba",
          "dial_code": "+53",
          "code": "CU"
          },
          {
          "name": "Cyprus",
          "dial_code": "+357",
          "code": "CY"
          },
          {
          "name": "Czech Republic",
          "dial_code": "+420",
          "code": "CZ"
          },
          {
          "name": "Denmark",
          "dial_code": "+45",
          "code": "DK"
          },
          {
          "name": "Djibouti",
          "dial_code": "+253",
          "code": "DJ"
          },
          {
          "name": "Dominica",
          "dial_code": "+1767",
          "code": "DM"
          },
          {
          "name": "Dominican Republic",
          "dial_code": "+1849",
          "code": "DO"
          },
          {
          "name": "Ecuador",
          "dial_code": "+593",
          "code": "EC"
          },
          {
          "name": "Egypt",
          "dial_code": "+20",
          "code": "EG"
          },
          {
          "name": "El Salvador",
          "dial_code": "+503",
          "code": "SV"
          },
          {
          "name": "Equatorial Guinea",
          "dial_code": "+240",
          "code": "GQ"
          },
          {
          "name": "Eritrea",
          "dial_code": "+291",
          "code": "ER"
          },
          {
          "name": "Estonia",
          "dial_code": "+372",
          "code": "EE"
          },
          {
          "name": "Ethiopia",
          "dial_code": "+251",
          "code": "ET"
          },
          {
          "name": "Falkland Islands (Malvinas)",
          "dial_code": "+500",
          "code": "FK"
          },
          {
          "name": "Faroe Islands",
          "dial_code": "+298",
          "code": "FO"
          },
          {
          "name": "Fiji",
          "dial_code": "+679",
          "code": "FJ"
          },
          {
          "name": "Finland",
          "dial_code": "+358",
          "code": "FI"
          },
          {
          "name": "France",
          "dial_code": "+33",
          "code": "FR"
          },
          {
          "name": "French Guiana",
          "dial_code": "+594",
          "code": "GF"
          },
          {
          "name": "French Polynesia",
          "dial_code": "+689",
          "code": "PF"
          },
          {
          "name": "Gabon",
          "dial_code": "+241",
          "code": "GA"
          },
          {
          "name": "Gambia",
          "dial_code": "+220",
          "code": "GM"
          },
          {
          "name": "Georgia",
          "dial_code": "+995",
          "code": "GE"
          },
          {
          "name": "Germany",
          "dial_code": "+49",
          "code": "DE"
          },
          {
          "name": "Ghana",
          "dial_code": "+233",
          "code": "GH"
          },
          {
          "name": "Gibraltar",
          "dial_code": "+350",
          "code": "GI"
          },
          {
          "name": "Greece",
          "dial_code": "+30",
          "code": "GR"
          },
          {
          "name": "Greenland",
          "dial_code": "+299",
          "code": "GL"
          },
          {
          "name": "Grenada",
          "dial_code": "+1473",
          "code": "GD"
          },
          {
          "name": "Guadeloupe",
          "dial_code": "+590",
          "code": "GP"
          },
          {
          "name": "Guam",
          "dial_code": "+1671",
          "code": "GU"
          },
          {
          "name": "Guatemala",
          "dial_code": "+502",
          "code": "GT"
          },
          {
          "name": "Guernsey",
          "dial_code": "+44",
          "code": "GG"
          },
          {
          "name": "Guinea",
          "dial_code": "+224",
          "code": "GN"
          },
          {
          "name": "Guinea-Bissau",
          "dial_code": "+245",
          "code": "GW"
          },
          {
          "name": "Guyana",
          "dial_code": "+595",
          "code": "GY"
          },
          {
          "name": "Haiti",
          "dial_code": "+509",
          "code": "HT"
          },
          {
          "name": "Holy See (Vatican City State)",
          "dial_code": "+379",
          "code": "VA"
          },
          {
          "name": "Honduras",
          "dial_code": "+504",
          "code": "HN"
          },
          {
          "name": "Hong Kong",
          "dial_code": "+852",
          "code": "HK"
          },
          {
          "name": "Hungary",
          "dial_code": "+36",
          "code": "HU"
          },
          {
          "name": "Iceland",
          "dial_code": "+354",
          "code": "IS"
          },
          {
          "name": "India",
          "dial_code": "+91",
          "code": "IN"
          },
          {
          "name": "Indonesia",
          "dial_code": "+62",
          "code": "ID"
          },
          {
          "name": "Iran, Islamic Republic of Persian Gulf",
          "dial_code": "+98",
          "code": "IR"
          },
          {
          "name": "Iraq",
          "dial_code": "+964",
          "code": "IQ"
          },
          {
          "name": "Ireland",
          "dial_code": "+353",
          "code": "IE"
          },
          {
          "name": "Isle of Man",
          "dial_code": "+44",
          "code": "IM"
          },
          {
          "name": "Israel",
          "dial_code": "+972",
          "code": "IL"
          },
          {
          "name": "Italy",
          "dial_code": "+39",
          "code": "IT"
          },
          {
          "name": "Jamaica",
          "dial_code": "+1876",
          "code": "JM"
          },
          {
          "name": "Japan",
          "dial_code": "+81",
          "code": "JP"
          },
          {
          "name": "Jersey",
          "dial_code": "+44",
          "code": "JE"
          },
          {
          "name": "Jordan",
          "dial_code": "+962",
          "code": "JO"
          },
          {
          "name": "Kazakhstan",
          "dial_code": "+77",
          "code": "KZ"
          },
          {
          "name": "Kenya",
          "dial_code": "+254",
          "code": "KE"
          },
          {
          "name": "Kiribati",
          "dial_code": "+686",
          "code": "KI"
          },
          {
          "name": "Korea, Democratic People's Republic of Korea",
          "dial_code": "+850",
          "code": "KP"
          },
          {
          "name": "Korea, Republic of South Korea",
          "dial_code": "+82",
          "code": "KR"
          },
          {
          "name": "Kuwait",
          "dial_code": "+965",
          "code": "KW"
          },
          {
          "name": "Kyrgyzstan",
          "dial_code": "+996",
          "code": "KG"
          },
          {
          "name": "Laos",
          "dial_code": "+856",
          "code": "LA"
          },
          {
          "name": "Latvia",
          "dial_code": "+371",
          "code": "LV"
          },
          {
          "name": "Lebanon",
          "dial_code": "+961",
          "code": "LB"
          },
          {
          "name": "Lesotho",
          "dial_code": "+266",
          "code": "LS"
          },
          {
          "name": "Liberia",
          "dial_code": "+231",
          "code": "LR"
          },
          {
          "name": "Libyan Arab Jamahiriya",
          "dial_code": "+218",
          "code": "LY"
          },
          {
          "name": "Liechtenstein",
          "dial_code": "+423",
          "code": "LI"
          },
          {
          "name": "Lithuania",
          "dial_code": "+370",
          "code": "LT"
          },
          {
          "name": "Luxembourg",
          "dial_code": "+352",
          "code": "LU"
          },
          {
          "name": "Macao",
          "dial_code": "+853",
          "code": "MO"
          },
          {
          "name": "Macedonia",
          "dial_code": "+389",
          "code": "MK"
          },
          {
          "name": "Madagascar",
          "dial_code": "+261",
          "code": "MG"
          },
          {
          "name": "Malawi",
          "dial_code": "+265",
          "code": "MW"
          },
          {
          "name": "Malaysia",
          "dial_code": "+60",
          "code": "MY"
          },
          {
          "name": "Maldives",
          "dial_code": "+960",
          "code": "MV"
          },
          {
          "name": "Mali",
          "dial_code": "+223",
          "code": "ML"
          },
          {
          "name": "Malta",
          "dial_code": "+356",
          "code": "MT"
          },
          {
          "name": "Marshall Islands",
          "dial_code": "+692",
          "code": "MH"
          },
          {
          "name": "Martinique",
          "dial_code": "+596",
          "code": "MQ"
          },
          {
          "name": "Mauritania",
          "dial_code": "+222",
          "code": "MR"
          },
          {
          "name": "Mauritius",
          "dial_code": "+230",
          "code": "MU"
          },
          {
          "name": "Mayotte",
          "dial_code": "+262",
          "code": "YT"
          },
          {
          "name": "Mexico",
          "dial_code": "+52",
          "code": "MX"
          },
          {
          "name": "Micronesia, Federated States of Micronesia",
          "dial_code": "+691",
          "code": "FM"
          },
          {
          "name": "Moldova",
          "dial_code": "+373",
          "code": "MD"
          },
          {
          "name": "Monaco",
          "dial_code": "+377",
          "code": "MC"
          },
          {
          "name": "Mongolia",
          "dial_code": "+976",
          "code": "MN"
          },
          {
          "name": "Montenegro",
          "dial_code": "+382",
          "code": "ME"
          },
          {
          "name": "Montserrat",
          "dial_code": "+1664",
          "code": "MS"
          },
          {
          "name": "Morocco",
          "dial_code": "+212",
          "code": "MA"
          },
          {
          "name": "Mozambique",
          "dial_code": "+258",
          "code": "MZ"
          },
          {
          "name": "Myanmar",
          "dial_code": "+95",
          "code": "MM"
          },
          {
          "name": "Namibia",
          "dial_code": "+264",
          "code": "NA"
          },
          {
          "name": "Nauru",
          "dial_code": "+674",
          "code": "NR"
          },
          {
          "name": "Nepal",
          "dial_code": "+977",
          "code": "NP"
          },
          {
          "name": "Netherlands",
          "dial_code": "+31",
          "code": "NL"
          },
          {
          "name": "Netherlands Antilles",
          "dial_code": "+599",
          "code": "AN"
          },
          {
          "name": "New Caledonia",
          "dial_code": "+687",
          "code": "NC"
          },
          {
          "name": "New Zealand",
          "dial_code": "+64",
          "code": "NZ"
          },
          {
          "name": "Nicaragua",
          "dial_code": "+505",
          "code": "NI"
          },
          {
          "name": "Niger",
          "dial_code": "+227",
          "code": "NE"
          },
          {
          "name": "Nigeria",
          "dial_code": "+234",
          "code": "NG"
          },
          {
          "name": "Niue",
          "dial_code": "+683",
          "code": "NU"
          },
          {
          "name": "Norfolk Island",
          "dial_code": "+672",
          "code": "NF"
          },
          {
          "name": "Northern Mariana Islands",
          "dial_code": "+1670",
          "code": "MP"
          },
          {
          "name": "Norway",
          "dial_code": "+47",
          "code": "NO"
          },
          {
          "name": "Oman",
          "dial_code": "+968",
          "code": "OM"
          },
          {
          "name": "Pakistan",
          "dial_code": "+92",
          "code": "PK"
          },
          {
          "name": "Palau",
          "dial_code": "+680",
          "code": "PW"
          },
          {
          "name": "Palestinian Territory, Occupied",
          "dial_code": "+970",
          "code": "PS"
          },
          {
          "name": "Panama",
          "dial_code": "+507",
          "code": "PA"
          },
          {
          "name": "Papua New Guinea",
          "dial_code": "+675",
          "code": "PG"
          },
          {
          "name": "Paraguay",
          "dial_code": "+595",
          "code": "PY"
          },
          {
          "name": "Peru",
          "dial_code": "+51",
          "code": "PE"
          },
          {
          "name": "Philippines",
          "dial_code": "+63",
          "code": "PH"
          },
          {
          "name": "Pitcairn",
          "dial_code": "+872",
          "code": "PN"
          },
          {
          "name": "Poland",
          "dial_code": "+48",
          "code": "PL"
          },
          {
          "name": "Portugal",
          "dial_code": "+351",
          "code": "PT"
          },
          {
          "name": "Puerto Rico",
          "dial_code": "+1939",
          "code": "PR"
          },
          {
          "name": "Qatar",
          "dial_code": "+974",
          "code": "QA"
          },
          {
          "name": "Romania",
          "dial_code": "+40",
          "code": "RO"
          },
          {
          "name": "Russia",
          "dial_code": "+7",
          "code": "RU"
          },
          {
          "name": "Rwanda",
          "dial_code": "+250",
          "code": "RW"
          },
          {
          "name": "Reunion",
          "dial_code": "+262",
          "code": "RE"
          },
          {
          "name": "Saint Barthelemy",
          "dial_code": "+590",
          "code": "BL"
          },
          {
          "name": "Saint Helena, Ascension and Tristan Da Cunha",
          "dial_code": "+290",
          "code": "SH"
          },
          {
          "name": "Saint Kitts and Nevis",
          "dial_code": "+1869",
          "code": "KN"
          },
          {
          "name": "Saint Lucia",
          "dial_code": "+1758",
          "code": "LC"
          },
          {
          "name": "Saint Martin",
          "dial_code": "+590",
          "code": "MF"
          },
          {
          "name": "Saint Pierre and Miquelon",
          "dial_code": "+508",
          "code": "PM"
          },
          {
          "name": "Saint Vincent and the Grenadines",
          "dial_code": "+1784",
          "code": "VC"
          },
          {
          "name": "Samoa",
          "dial_code": "+685",
          "code": "WS"
          },
          {
          "name": "San Marino",
          "dial_code": "+378",
          "code": "SM"
          },
          {
          "name": "Sao Tome and Principe",
          "dial_code": "+239",
          "code": "ST"
          },
          {
          "name": "Saudi Arabia",
          "dial_code": "+966",
          "code": "SA"
          },
          {
          "name": "Senegal",
          "dial_code": "+221",
          "code": "SN"
          },
          {
          "name": "Serbia",
          "dial_code": "+381",
          "code": "RS"
          },
          {
          "name": "Seychelles",
          "dial_code": "+248",
          "code": "SC"
          },
          {
          "name": "Sierra Leone",
          "dial_code": "+232",
          "code": "SL"
          },
          {
          "name": "Singapore",
          "dial_code": "+65",
          "code": "SG"
          },
          {
          "name": "Slovakia",
          "dial_code": "+421",
          "code": "SK"
          },
          {
          "name": "Slovenia",
          "dial_code": "+386",
          "code": "SI"
          },
          {
          "name": "Solomon Islands",
          "dial_code": "+677",
          "code": "SB"
          },
          {
          "name": "Somalia",
          "dial_code": "+252",
          "code": "SO"
          },
          {
          "name": "South Africa",
          "dial_code": "+27",
          "code": "ZA"
          },
          {
          "name": "South Sudan",
          "dial_code": "+211",
          "code": "SS"
          },
          {
          "name": "South Georgia and the South Sandwich Islands",
          "dial_code": "+500",
          "code": "GS"
          },
          {
          "name": "Spain",
          "dial_code": "+34",
          "code": "ES"
          },
          {
          "name": "Sri Lanka",
          "dial_code": "+94",
          "code": "LK"
          },
          {
          "name": "Sudan",
          "dial_code": "+249",
          "code": "SD"
          },
          {
          "name": "Suriname",
          "dial_code": "+597",
          "code": "SR"
          },
          {
          "name": "Svalbard and Jan Mayen",
          "dial_code": "+47",
          "code": "SJ"
          },
          {
          "name": "Swaziland",
          "dial_code": "+268",
          "code": "SZ"
          },
          {
          "name": "Sweden",
          "dial_code": "+46",
          "code": "SE"
          },
          {
          "name": "Switzerland",
          "dial_code": "+41",
          "code": "CH"
          },
          {
          "name": "Syrian Arab Republic",
          "dial_code": "+963",
          "code": "SY"
          },
          {
          "name": "Taiwan",
          "dial_code": "+886",
          "code": "TW"
          },
          {
          "name": "Tajikistan",
          "dial_code": "+992",
          "code": "TJ"
          },
          {
          "name": "Tanzania, United Republic of Tanzania",
          "dial_code": "+255",
          "code": "TZ"
          },
          {
          "name": "Thailand",
          "dial_code": "+66",
          "code": "TH"
          },
          {
          "name": "Timor-Leste",
          "dial_code": "+670",
          "code": "TL"
          },
          {
          "name": "Togo",
          "dial_code": "+228",
          "code": "TG"
          },
          {
          "name": "Tokelau",
          "dial_code": "+690",
          "code": "TK"
          },
          {
          "name": "Tonga",
          "dial_code": "+676",
          "code": "TO"
          },
          {
          "name": "Trinidad and Tobago",
          "dial_code": "+1868",
          "code": "TT"
          },
          {
          "name": "Tunisia",
          "dial_code": "+216",
          "code": "TN"
          },
          {
          "name": "Turkey",
          "dial_code": "+90",
          "code": "TR"
          },
          {
          "name": "Turkmenistan",
          "dial_code": "+993",
          "code": "TM"
          },
          {
          "name": "Turks and Caicos Islands",
          "dial_code": "+1649",
          "code": "TC"
          },
          {
          "name": "Tuvalu",
          "dial_code": "+688",
          "code": "TV"
          },
          {
          "name": "Uganda",
          "dial_code": "+256",
          "code": "UG"
          },
          {
          "name": "Ukraine",
          "dial_code": "+380",
          "code": "UA"
          },
          {
          "name": "United Arab Emirates",
          "dial_code": "+971",
          "code": "AE"
          },
          {
          "name": "United Kingdom",
          "dial_code": "+44",
          "code": "GB"
          },
          {
          "name": "United States",
          "dial_code": "+1",
          "code": "US"
          },
          {
          "name": "Uruguay",
          "dial_code": "+598",
          "code": "UY"
          },
          {
          "name": "Uzbekistan",
          "dial_code": "+998",
          "code": "UZ"
          },
          {
          "name": "Vanuatu",
          "dial_code": "+678",
          "code": "VU"
          },
          {
          "name": "Venezuela, Bolivarian Republic of Venezuela",
          "dial_code": "+58",
          "code": "VE"
          },
          {
          "name": "Vietnam",
          "dial_code": "+84",
          "code": "VN"
          },
          {
          "name": "Virgin Islands, British",
          "dial_code": "+1284",
          "code": "VG"
          },
          {
          "name": "Virgin Islands, U.S.",
          "dial_code": "+1340",
          "code": "VI"
          },
          {
          "name": "Wallis and Futuna",
          "dial_code": "+681",
          "code": "WF"
          },
          {
          "name": "Yemen",
          "dial_code": "+967",
          "code": "YE"
          },
          {
          "name": "Zambia",
          "dial_code": "+260",
          "code": "ZM"
          },
          {
          "name": "Zimbabwe",
          "dial_code": "+263",
          "code": "ZW"
          }
          ]

};
