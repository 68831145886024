import { Injectable, EventEmitter } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    ActivatedRoute
} from '@angular/router';
import { NotificationService } from '../../@core/services/notification.service';
import * as _ from 'underscore';
import { Observable } from 'rxjs/internal/Observable';
import { PermissionService } from 'src/app/services/permission.service';

@Injectable()
export class AccessGuard implements CanActivate {
    //permission 
    permission: any = [];
    constructor(
        private router: Router,
        private permissionService: PermissionService,
        private notificationService: NotificationService


    ) { }
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const action = route.data.action || '';
        const controller = route.data.controller || '';
        const list_controller = this.permissionService.permission['controller'];
        const controller_actions = this.permissionService.permission['action_map'];

        if (!action && !controller) {
            return true;
        }
        if (_.contains(list_controller, controller) && _.contains(controller_actions[controller], action)) {
            return true;
        } else {
            this.router.navigate(['/admin/dashboard']);
            this.notificationService.errorToast('You are not authorised user for access this');
            return false;
        }
    }
}
