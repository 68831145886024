import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StringConst } from '../utils/string-constants';
import decode from 'jwt-decode';
@Injectable()

export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (localStorage.getItem(StringConst.ADMIN_LOCAL_STORAGE_NAME)) {
            return true;
        }
        if (state.url.indexOf('/lafiya-admin') !== -1) {
            this.router.navigate(['/admin']);
        } else {
            this.router.navigate(['/login']);
        }
        return false;
    }

}
