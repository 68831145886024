import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { ElesAppConst } from './../@core/utils/eles-app-constant';
import { Injectable } from '@angular/core';
import { TreeviewItem } from 'ngx-treeview/src/treeview-item';


@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    public user: any = [];
    public permission: any = [];
    constructor(public http: HttpClient) { }

    setUserDetails(data) {
        this.user = data;
    }

    getStaffDetails(): Observable<any> {
        if (this.user) {
            return Observable.create((observer: Observer<any>) => observer.next(this.user)) ;
        } else {
        }
    }
    setPermission(permission) {
        this.permission = permission;
    }
    getPermission(): Observable<any> {
        if (this.permission && this.permission.length) {
            return Observable.create((observer: Observer<any>) => observer.next(this.permission)) ;
        } else {
            return this.http.get(ElesAppConst.GET_USER_ASSIGN_PERMISSION);
        }
    }
    getProducts(): TreeviewItem[] {
        const fruitCategory = new TreeviewItem({
            text: 'Fruit', value: 1, children: [
                { text: 'Apple', value: 11 },
                { text: 'Mango', value: 12 }
            ]
        });
        const vegetableCategory = new TreeviewItem({
            text: 'Vegetable', value: 2, children: [
                { text: 'Salad', value: 21 },
                { text: 'Potato', value: 22 }
            ]
        });
        // vegetableCategory.children.push(new TreeviewItem({ text: 'Mushroom', value: 23, checked: false }));
        vegetableCategory.correctChecked(); // need this to make 'Vegetable' node to change checked value from true to false
        return [fruitCategory, vegetableCategory];
    }

    updatePermissionStatus(obj) {
        if (obj) {
            return this.http.put(ElesAppConst.GET_PERMISSION, obj);
        } else {
            return Observable.create((observer: Observer<any>) => observer.next(this.permission)) ;
        }
    }
}
