import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../auth/home/home.service';
import { NotificationService } from '../@core/services/notification.service';

@Component({
  selector: 'app-paymentsuccess',
  templateUrl: './paymentsuccess.component.html',
  styleUrls: ['./paymentsuccess.component.scss']
})
export class PaymentsuccessComponent implements OnInit {
  payerId : any;
  paymentId : any;

  constructor(
    private route: ActivatedRoute,
    private homeService: HomeService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.payerId = params['PayerID'];
      console.log("PayerID",this.payerId); 

      this.paymentId = params['paymentId'];
      console.log("paymentId",this.paymentId); 
    });

    if(this.payerId && this.paymentId){
      let obj = {
        payer_id : this.payerId,
        payment_id : this.paymentId
      }
      this.homeService.executePayment(obj).subscribe(res => {
        if(res && res.data && res.code == 200){
          console.log("Res",res);
          this.notificationService.successToast(res.message);
        }else{
          console.log("err",res);
          this.notificationService.errorToast(res.message);
        }
      });
    }

  }

}
