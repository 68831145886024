import { Injectable } from '@angular/core';
import {CanActivate,ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot,Router} from '@angular/router';
import { Observable, of } from 'rxjs';
import {AppointmentService} from '../../services/appointment.service';
import { NotificationService } from "../../@core/services/notification.service";
import { Country } from '../../@core/services/country';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TeleconsultationGuard implements CanActivate {
    roomName = "";
    isValid = false;
    timeZone = "";
  constructor(private country:Country, private notificationService: NotificationService, private activatedRoute: ActivatedRoute, private router: Router, private appointmentService:AppointmentService ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    return new Observable<boolean>((observer) => {
      let url: string = state.url;
      this.country.getCurrentCountry().subscribe((response) => {
        this.timeZone = response['timezone'];
        this.roomName = url.split("/")[3];
        const userType = url.split("/")[2];
        let obj = {roomName: this.roomName}
        this.appointmentService.getAppointmentDetailsWithRoomName(obj).subscribe((res) => {
    let startUTCString =  res['data']['startTimeUTC'];
    let startDate =  moment(startUTCString);
    let startTimeMilliseconds  = startDate.tz(this.timeZone).valueOf();
    
    let endUTCString =  res['data']['endTimeUTC'];
    let endDate =  moment(endUTCString);
    let endTimeMilliseconds  = endDate.tz(this.timeZone).valueOf();
    
    if(Date.now() >= endTimeMilliseconds){
      this.notificationService.errorToast("Appointment Time is Over");
      observer.next(false);
      observer.complete();
      // this.isValid = false;
      // return this.isValid;
    } 
    else if(userType === 'consultation') {
      observer.next(true);
      observer.complete();
    }
    else if(true){//(startTimeMilliseconds - Date.now()) <= 300000){
      observer.next(true);
      observer.complete();
      // this.isValid = true;
      // return this.isValid;
    }
    else if((startTimeMilliseconds - Date.now()) > 300000){
      
      this.notificationService.errorToast("Please note you can only join the Teleconsultation room 5 minutes prior to your booked time slot only");
      // this.isValid = false;
      // return this.isValid;
      observer.next(true);//false);
      observer.complete();
    }
    
        }, (err) => {
          observer.next(false);
          observer.complete();
        })
        });
   

    });



  }
}
