import { Component, OnInit, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'usg_scanner_component',
  templateUrl: './usg_scanner_component.html',
  styleUrls: ['./usg_scanner_component.scss']
})
export class USGScannerComponent implements OnInit {
  constructor(    private activatedRoute: ActivatedRoute,
  ){}
  
  ngOnInit() {
    this.getIdFromRoute();
  }
  getIdFromRoute() {
    this.activatedRoute.params.subscribe((params) => {
      console.log("scanner id added", params.id);
      localStorage.setItem("usg_scanner", params.id);
    });
  }
}

