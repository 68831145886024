import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ElesAppConst } from '../utils/eles-app-constant';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})

export class Country {

    constructor(private http: HttpClient) {
    }

    getCurrentCountry(): Observable<any> {
        const { currentLocation: { url, token } } = environment;
        return this.http.get(`${url}?token=${token}`);
    }

    getCurrentCountryV2(): Observable<any> {
        return this.http.get('https://www.lafiyatelehealth.com/api/f/country_by_ip');
    }

    getGeoCordinate(address): Observable<any> {
        return this.http.get(`${ElesAppConst.GET_GEO_CORDINATE}?address=${address}`);
    }
}
