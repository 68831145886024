import { HttpClient } from "@angular/common/http";
import { Observable, Observer } from "rxjs";
import { Injectable } from "@angular/core";
import { ElesAppConst } from "../@core/utils/eles-app-constant";
import { StringConst } from "../@core/utils/string-constants";
import { query } from "@angular/animations";

@Injectable({
  providedIn: "root",
})
export class StaffService {
  constructor(public http: HttpClient) {}
  addStaff(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_STAFF, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  uploadMultipleStaff(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.UPLOAD_MULTIPLE_STAFF, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  getStaff(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.GET_STAFFS, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  getStaffDetails(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.GET_STAFF_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  saveStaffSchedule(id, req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(
        `${ElesAppConst.SAVE_DOCTOR_SCHEDULE}?id=${id}`,
        req_obj
      );
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  saveStaffConsultancyFees(id, req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(
        `${ElesAppConst.SAVE_DOCTOR_CONSULTANCY_FEE}?id=${id}`,
        req_obj
      );
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  getStaffConsultancyFees(id): Observable<any> {
    if (id) {
      return this.http.get(
        `${ElesAppConst.GET_DOCTOR_CONSULTANCY_FEE}?id=${id}`
      );
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  getConsultancyFees(): Observable<any> {
    return this.http.get(`${ElesAppConst.GET_CONSULTANCY_FEE}`);
  }
  saveStaffRepresentative(id, req_obj): Observable<any> {
    return this.http.post(
      `${ElesAppConst.SAVE_STAFF_REPRESENTATIVE}?id=${id}`,
      req_obj
    );
  }
  getStaffRepresentative(id): Observable<any> {
    return this.http.get(`${ElesAppConst.GET_STAFF_REPRESENTATIVE}?id=${id}`);
  }
  getStaffSchedule(id): Observable<any> {
    if (id) {
      return this.http.get(`${ElesAppConst.GET_DOCTOR_SCHEDULE}?id=${id}`);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  getMyDetails(): Observable<any> {
    return this.http.get(ElesAppConst.GET_MY_DETAIL);
  }
  getStaffSubscriptions(convertCurrencyTo, query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_STAFF_SUBSCRIPTION +
        "?convertCurrencyTo=" +
        convertCurrencyTo +
        "&page=" +
        query.page +
        "&pageSize=" +
        query.pageSize
    );
  }
  getStaffPlans(convertCurrencyTo, query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_STAFF_PLANS +
        "?convertCurrencyTo=" +
        convertCurrencyTo +
        "&page=" +
        query.page +
        "&pageSize=" +
        query.pageSize
    );
  }
  getStaffPlan(convertCurrencyTo): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_STAFF_PLAN +
        "?convertCurrencyTo=" +
        convertCurrencyTo
    );
  }
  getMyPayments(convertCurrencyTo, query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_MY_PAYMENT +
        "?convertCurrencyTo=" +
        convertCurrencyTo +
        "&page=" +
        query.page +
        "&pageSize=" +
        query.pageSize
    );
  }
  getMyWallet(convertCurrencyTo, query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_MY_WALLET +
        "?convertCurrencyTo=" +
        convertCurrencyTo +
        "&page=" +
        query.page +
        "&pageSize=" +
        query.pageSize
    );
  }
  getPatientReport(convertCurrencyTo, query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_PATIENT_REPORT +
        "?convertCurrencyTo=" +
        convertCurrencyTo +
        "&page=" +
        query.page +
        "&pageSize=" +
        query.pageSize +
        "&fromDate=" +
        query.fromDate +
        "&toDate=" +
        query.toDate
    );
  }
  getDoctorReport(convertCurrencyTo, query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_DOCTOR_REPORT +
        "?convertCurrencyTo=" +
        convertCurrencyTo +
        "&page=" +
        query.page +
        "&pageSize=" +
        query.pageSize +
        "&fromDate=" +
        query.fromDate +
        "&toDate=" +
        query.toDate
    );
  }
  getMyWalletBalance(convertCurrencyTo): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_MY_WALLET_BALANCE +
        "?convertCurrencyTo=" +
        convertCurrencyTo
    );
  }
  getAllWallet(query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_ALL_WALLET +
        "?page=" +
        query.page +
        "&pageSize=" +
        query.pageSize
    );
  }
  updateAllWallet(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.put(ElesAppConst.UPDATE_WALLET_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  addAdminWallet(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_ADMIN_WALLET_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  getAdminWallet(query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_ADMIN_WALLET +
        "?page=" +
        query.page +
        "&pageSize=" +
        query.pageSize
    );
  }
  getAdminWalletBalance(): Observable<any> {
    return this.http.get(ElesAppConst.GET_ADMIN_WALLET_BALANCE);
  }
  getAdminAllWallet(): Observable<any> {
    return this.http.get(ElesAppConst.GET_ADMIN_ALL_WALLET);
  }
  updateAdminWallet(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.put(ElesAppConst.UPDATE_ADMIN_WALLET_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  getAdminPayments(query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_ADMIN_PAYMENT +
        "?page=" +
        query.page +
        "&pageSize=" +
        query.pageSize
    );
  }

  getAdminAppointmentStats(query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_ADMIN_APPOINTMENT_REPORT +
        "?fromDate=" +
        query.fromDate +
        "&toDate=" +
        query.toDate
    );
  }
  getAdminPaymentReport(query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_ADMIN_PAYMENT_REPORT +
        "?page=" +
        query.page +
        "&pageSize=" +
        query.pageSize +
        "&fromDate=" +
        query.fromDate +
        "&toDate=" +
        query.toDate
    );
  }
  getAdminEarningReport(query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_ADMIN_EARNING_REPORT +
        "?page=" +
        query.page +
        "&pageSize=" +
        query.pageSize +
        "&fromDate=" +
        query.fromDate +
        "&toDate=" +
        query.toDate
    );
  }
  getAdminProviderReport(query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_ADMIN_PROVIDER_REPORT +
        "?page=" +
        query.page +
        "&pageSize=" +
        query.pageSize +
        "&fromDate=" +
        query.fromDate +
        "&toDate=" +
        query.toDate
    );
  }
  generateVoucher(obj): Observable<any> {
    return this.http.post(ElesAppConst.GENERATE_VOUCHER, obj);
  }
  getLots(query): Observable<any> {
    return this.http.get(`${ElesAppConst.GET_LOTS}?${query}`);
  }
  deleteLot(id): Observable<any> {
    return this.http.delete(`${ElesAppConst.GET_LOTS}?id=${id}`);
  }
  getVouchers(id, query): Observable<any> {
    return this.http.get(`${ElesAppConst.GET_VOUCHERS}?id=${id}&${query}`);
  }
  deleteVoucher(id): Observable<any> {
    return this.http.delete(`${ElesAppConst.GET_VOUCHERS}?id=${id}`);
  }
  getAdminPaymentTotal(query): Observable<any> {
    return this.http.get(
      ElesAppConst.GET_ADMIN_PAYMENT_TOTAL +
        "?fromDate=" +
        query.fromDate +
        "&toDate=" +
        query.toDate
    );
  }
  getAdminRevenue(obj): Observable<any> {
    return this.http.post(ElesAppConst.GET_ADMIN_REVENUE, obj);
  }
  getMyCard(): Observable<any> {
    return this.http.get(ElesAppConst.GET_MY_CARD);
  }
  getStaffCardsByAdmin(id): Observable<any> {
    return this.http.get(`${ElesAppConst.GET_STAFF_CARD_BY_ADMIN}?id=${id}`);
  }
  addMyCard(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_CARD_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  addMyWallet(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_WALLET_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  updateMyCard(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.put(ElesAppConst.UPDATE_CARD_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  deleteMyCard(id): Observable<any> {
    if (id) {
      return this.http.delete(`${ElesAppConst.DELETE_CARD_DETAIL}?id=${id}`);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  addMedicenePayment(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_MEDICENE_PAYMENT, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  uploadStaffImage(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.UPLOAD_STAFF_IMAGE, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  uploadStaffSignature(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(`${ElesAppConst.UPLOAD_STAFF_SIGNATURE}`, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  uploadDocument(query, req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(
        `${ElesAppConst.UPLOAD_STAFF_DOCUMENT}?${query}`,
        req_obj
      );
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  uploadMultipleDocument(query, req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(
        `${ElesAppConst.UPLOAD_STAFF_MULTIPLE_DOCUMENT}?${query}`,
        req_obj
      );
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  uploadStaffRepresentativeDocument(query, req_obj): Observable<any> {
    return this.http.post(
      `${ElesAppConst.UPLOAD_STAFF_REPRESENTATIVE_DOCUMENT}?${query}`,
      req_obj
    );
  }
  getMyDocument(): Observable<any> {
    return this.http.get(`${ElesAppConst.GET_MY_DOCUMENTS}`);
  }
  updateStaffDetails(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.UPDATE_STAFF_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  updateStaffSubscriptionPlan(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.put(
        ElesAppConst.UPDATE_STAFF_SUBSCRIPTION_PLAN,
        req_obj
      );
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  updateStaffPlan(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.put(
        ElesAppConst.UPDATE_STAFF_PLAN,
        req_obj
      );
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  addStaffDetails(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_STAFF_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  setupConsultationDuration(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.SETUP_CONSULTATION_DURATION, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  getConsultationDuration(): Observable<any> {
    return this.http.get(ElesAppConst.GET_CONSULTATION_DURATION);
  }

  saveAiHealthCheckerFees(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.SETUP_AI_HEALTHCHECKER_FEES, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  getAiHealthCheckerFees(): Observable<any> {
    return this.http.get(ElesAppConst.GET_AI_HEALTHCHECKER_FEES);
  }

  patchStaffDetails(id, req_obj): Observable<any> {
    if (req_obj) {
      return this.http.put(`${ElesAppConst.PATCH_STAFF}?id=${id}`, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  // requestOtp(req_obj): Observable<any> {
  //   if (req_obj) {
  //     return this.http.post(ElesAppConst.REQUEST_OTP, req_obj);
  //   } else {
  //     return Observable.create((observer: Observer<any>) =>
  //       observer.error(StringConst.INVALID_INFORMATION)
  //     );
  //   }
  // }

  verifyOtp(req_obj): Observable<any> {
    console.log("in verify service----");

    if (req_obj) {
      return this.http.post(ElesAppConst.VERIFY_OTP, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  addDoctorDetails(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_DOCTOR_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  addHospitalDetails(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_DOCTOR_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  addPharmacyDetails(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_DOCTOR_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  addMedicalLabDetails(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_DOCTOR_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  addAgencyDetails(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_DOCTOR_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  registerStaff(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.REGISTERT_STAFF, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  addFinanceUser(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.ADD_DOCTOR_DETAIL, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  resgisterStaff(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.REGISTER_STAFF, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  resetPassword(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.RESETPASSWORD_STAFF, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  activateAccount(id): Observable<any> {
    if (id) {
      return this.http.get(`${ElesAppConst.ACTIVATE_ACCOUNT}?id=${id}`);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  getStffById(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.GET_STAFF, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  getCountries(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.GET_COUNTRIES, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  getStates(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.GET_STATES, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  getCities(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(ElesAppConst.GET_CITIES, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  deleteStaffById(id, type_ = ""): Observable<any> {
    if (id && !type_){
      return this.http.delete(`${ElesAppConst.PATCH_STAFF}?id=${id}`);
    } else if(type_ === "from_lafiya"){
      const hospitalDetails = JSON.parse(sessionStorage.getItem('staffData'));
      const id_clinic = hospitalDetails.staffIds;
      return this.http.post(ElesAppConst.REMOVE_LAFIYA_PROVIDER_FROM_CLINIC, {id_clinic, id_profile:id});
    }else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }
  updateStaffStatus(obj) {
    if (obj) {
      return this.http.put(`${ElesAppConst.PATCH_STAFF}/status`, obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  getUsersByRoles(req_obj) {
    if (req_obj) {
      return this.http.post(ElesAppConst.GET_USERS_BY_ROLES, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  getHospitalProviders(obj) {
    return this.http.post(ElesAppConst.GET_HOSPITAL_PROVIDERS, obj);
  }
  
  updateHospitalProvider(obj) {
    return this.http.post(ElesAppConst.UPDATE_HOSPITAL_PROVIDER, obj);
  }

  getLafiyaProviders(obj) {
    return this.http.post(ElesAppConst.GET_LAFIYA_PROVIDERS, obj);
  }
  addLafiyaProvidersToClinic(obj){
    return this.http.post(ElesAppConst.ADD_LAFIYA_PROVIDERS_TO_CLIINIC, obj);
  }
  getHospitalPatients(obj) {
    return this.http.post(ElesAppConst.GET_HOSPITAL_PATIENTS, obj);
  }

  getHMOPatients(obj) {
    return this.http.post(ElesAppConst.GET_HMO_PATIENTS, obj);
  }

  getDashboardStaffCount(obj) {
    return this.http.post(ElesAppConst.GET_STAFF_COUNT, obj);
  }

  getAppointmentCount(obj) {
    return this.http.post(ElesAppConst.GET_APPOINTMENT_COUNT, obj);
  }

  getDoctorPatients(obj) {
    return this.http.post(ElesAppConst.GET_DOCTOR_PATIENTS, obj);
  }

  getStaffScheduleSlots(obj) {
    return this.http.post(ElesAppConst.GET_STAFF_SCHEDULE_SLOTS, obj);
  }

  getDoctorScheduleSlots(obj) {
    return this.http.post(ElesAppConst.GET_DOCTOR_SCHEDULE_SLOTS, obj);
  }

  getAggregateDoctorFeedback(id) {
    return this.http.get(`${ElesAppConst.GET_DOCTOR_FEEDBACK_COUNT}?id=${id}`);
  }

  getAllReferralDetail(obj) {
    return this.http.post(ElesAppConst.GET_ALL_STAFF_REFERRAL, obj);
  }

  generateMembershipId(obj) {
    return this.http.post(ElesAppConst.GENERATE_MEMBERSHIP_ID, obj);
  }

  getMemberships(obj) {
    return this.http.post(ElesAppConst.GET_MEMBERSHIP_ID, obj);
  }

  deleteMembership(id) {
    return this.http.delete(`${ElesAppConst.DELETE_MEMBERSHIP_ID}?id=${id}`);
  }

  patientRegisterMembershipId(obj) {
    return this.http.post(ElesAppConst.PATIENT_REGISTER_MEMBERSHIP_ID, obj);
  }

  getRequestedMemberships(obj) {
    return this.http.post(ElesAppConst.GET_REQUESTED_MEMBERSHIP_ID, obj);
  }

  approveMembershipRequest(obj) {
    return this.http.post(ElesAppConst.APPROVE_REQUESTED_MEMBERSHIP_ID, obj);
  }

  getPatientMembershipId(obj) {
    return this.http.post(ElesAppConst.GET_PATIENT_MEMBERSHIP_ID, obj);
  }
}
