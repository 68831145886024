export default {
  production: true,
  frontendUrl: "https://www.lafiyatelehealth.com",
  aiCheckerUrl: "https://checker.lafiyatelehealth.com",
  url: "https://www.lafiyatelehealth.com/api/",
  apiUrl: "https://www.lafiyatelehealth.com",
  vidoe_socket_url: "https://localhost:5957/",
  Imageurl: "https://www.lafiyatelehealth.com/upload/",
  currentLocation: {
    url: "https://ipinfo.io",
    token: "08972fe7a63b11",
  },
  //Stripe Publishable key
  stripKey:
    "pk_live_51HMHNMCv80spFZlueGDlbvoUaPMvS56htHlyx5sUPvnoScV2uObxPBYyHMK0yoIfj2xBMVVlXZFcM5HecUgyHjIN00Jy2AiBNf",
  monnifyKey: "MK_PROD_WKVQWN2SE4",
  monnifyContractCode: "556889487287",
  monnifyTestMode: false,
  //Paypal Cliend ID
  paypalClientId:
    "AUT1jBOW7J6Eec_J5w3x8j7Zr0xkshd63Lbr2VTmCB02bud0xF49aOlyqXO8axYmZi27Mm7NQDugSUSH",
};
