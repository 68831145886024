import { Injectable } from '@angular/core';
import {CanActivate,ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot,Router} from '@angular/router';
import { Observable, of } from 'rxjs';
import {AppointmentService} from '../../services/appointment.service';
import { NotificationService } from "../../@core/services/notification.service";
import { Country } from '../../@core/services/country';
 //import { moment  from 'moment';
import * as momentTz from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class OpenTeleconsultationGuard implements CanActivate {
    roomName = "";
    isValid = false;
    timeZone = "";
  constructor(private country:Country, private notificationService: NotificationService, private activatedRoute: ActivatedRoute, private router: Router, private appointmentService:AppointmentService ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    return new Observable<boolean>((observer) => {
      let url: string = state.url;
      this.country.getCurrentCountry().subscribe((response) => {
        this.timeZone = response['timezone'];
        this.roomName = url.split("/")[2];
        let obj = {roomName: this.roomName}
        this.appointmentService.getAppointmentDetailsWithRoomNameOpen(obj).subscribe((res) => {
    let startUTCString =  res['data']['startTimeUTC'];
    let startDate =  momentTz(startUTCString);
    let startTimeMilliseconds  = startDate.tz(this.timeZone).valueOf();
    
    let endUTCString =  res['data']['endTimeUTC'];
    let endDate =  momentTz(endUTCString);
    let endTimeMilliseconds  = endDate.tz(this.timeZone).valueOf();
    
    if(Date.now() >= endTimeMilliseconds){
      this.notificationService.errorNotification("Appointment Time is Over");
      observer.next(false);
      observer.complete();
      this.router.navigate(['/'])
      // this.isValid = false;
      // return this.isValid;
    }
    else {
      observer.next(true);
      observer.complete();
      // this.isValid = true;
      // return this.isValid;
    }
    // else if((startTimeMilliseconds - Date.now()) > 120000){
      
    //   this.notificationService.errorToast("Please note you can only join the Teleconsultation room 2 minutes prior to your booked time slot only");
    //   // this.isValid = false;
    //   // return this.isValid;
    //   observer.next(false);
    //   observer.complete();
    // }
    
        }, (err) => {
          observer.next(false);
          observer.complete();
        })
        });
   

    });



  }
}
