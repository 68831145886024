import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { ElesAppConst } from '../@core/utils/eles-app-constant';
import { StringConst } from "../@core/utils/string-constants";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(public http: HttpClient) { }



  SaveAppointment(appointmentData): Observable<any> {

    return this.http.post(ElesAppConst.SAVE_APPOINTMENT_DATA, appointmentData);
  }

  bookAppointmentByDoctor(appointmentData): Observable<any> {
    return this.http.post(ElesAppConst.BOOK_APPOINTMENT_BY_DOCTOR, appointmentData);
  }

  bookAppointmentByAgent(appointmentData): Observable<any> {
    return this.http.post(ElesAppConst.BOOK_APPOINTMENT_BY_AGENT, appointmentData);
  }

  deleteAppointment(appointmentData): Observable<any> {

    return this.http.post(ElesAppConst.DELETE_APPOINTMENT_DATA, {


      slotId: appointmentData.slotId,
      isDeleted: appointmentData.isDeleted,
    });
  }

  GetAppointment(patientId): Observable<any> {

    return this.http.post(ElesAppConst.GET_APPOINTMENT_DATA, {
      patientId,

    });
  }

  getPatientAppointment(obj) {
    return this.http.post(ElesAppConst.GET_PATIENT_APPOINTMENT_DATA, obj);
  }

  getDoctorAppointment(obj) {
    return this.http.post(ElesAppConst.GET_DOCTOR_APPOINTMENT_DATA, obj);
  }

  getLabAppointment(obj) {
    return this.http.post(ElesAppConst.GET_LAB_APPOINTMENT_DATA, obj);
  }

  
  bookLabAppointment(obj) {
    return this.http.post(ElesAppConst.BOOK_LAB_APPOINTMENT, obj);
  }

  confirmBookingMonnify(obj) {
    return this.http.post(ElesAppConst.CONFIRM_BOOK_MONNIFY, obj);
  }

  cancelAppointment(obj) {
    return this.http.put(ElesAppConst.CANCELED_APPOINTMENT, obj);
  }

  getAllAppointments(obj) {
    return this.http.post(ElesAppConst.GET_ALL_APPOINTMENT, obj);
  }

  reScheduledAppointments(obj) {
    return this.http.put(ElesAppConst.RE_SCHEDULED_APPOINTMENT, obj);
  }

  getHospitalProviderAppointment(obj) {
    return this.http.post(ElesAppConst.GET_HOSPITAL_PROVIDER_APPOINTMENT, obj);
  }

  uploadSignature(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(`${ElesAppConst.UPLOAD_CONSENT_SIGNATURE}`, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }



   getAppointmentDetailsWithRoomName(obj) {
    return this.http.post(ElesAppConst.GET_APPOINTMENT_DETAILS_WITH_ROOM_NAME, obj);
  }
  getAppointmentDetailsWithRoomNameOpen(obj) {
    return this.http.post(ElesAppConst.GET_APPOINTMENT_DETAILS_WITH_ROOM_NAME_OPEN, obj);
  }

  updateAppointmentWithRoomName(obj) {
    return this.http.post(ElesAppConst.UPDATE_APPOINTMENT_WITH_ROOM_NAME, obj);
  }

searchMedicine(obj){
  return this.http.post(ElesAppConst.SEARCH_MEDICINE, obj);
}
  saveReferralDetail(obj) {
    return this.http.post(ElesAppConst.STAFF_REFERRAL, obj);
  }

  getReferralDetail(obj) {
    return this.http.post(ElesAppConst.GET_STAFF_REFERRAL, obj);
  }

  getUserFeedbacks(obj) {
    return this.http.post(ElesAppConst.GET_USER_FEEDBACKS,obj);
  }

  saveFeedback(obj) {
    return this.http.post(ElesAppConst.SAVE_FEEDBACK, obj);
  }

  getpatientMedicalRecords(obj) {
    return this.http.post(ElesAppConst.GET_PATIENT_MEDICAL_RECORDS,obj);
  }

  uploadAppointmentDoc(query, obj) {
    return this.http.post(`${ElesAppConst.UPLOAD_PATIENT_APPOINTMENT_DOC}?${query}`, obj);
  }

  authenticatePatientOpen(obj) {
    return this.http.post(ElesAppConst.AUTHENTICATE_PATIENT_OPEN, obj);
  }

  uploadSignatureOpen(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(`${ElesAppConst.UPLOAD_CONSENT_SIGNATURE_OPEN}`, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

  uploadScreenshot(req_obj): Observable<any> {
    if (req_obj) {
      return this.http.post(`${ElesAppConst.UPLOAD_SCREENSHOT}`, req_obj);
    } else {
      return Observable.create((observer: Observer<any>) =>
        observer.error(StringConst.INVALID_INFORMATION)
      );
    }
  }

startTytoVisit(params): Observable<any> {
  return this.http.post(ElesAppConst.START_TYTO_VISIT, params);
}

getReviewUrl(params): Observable<any> {
  return this.http.post(ElesAppConst.TYTO_REVIEW_URL, params);
}

uploadMedicalData(req_obj): Observable<any> {
  if (req_obj) {
    return this.http.post(`${ElesAppConst.UPLOAD_MEDICAL_DATA}`, req_obj);
  } else {
    return Observable.create((observer: Observer<any>) =>
      observer.error(StringConst.INVALID_INFORMATION)
    );
  }
}

joinAsPhoneCall(params):Observable<any>{
  return this.http.post(ElesAppConst.JOIN_CONF_AS_PHONE_CALL, params);
}

updateConnectionState(params):Observable<any>{
  return this.http.post(ElesAppConst.UPDATE_CONN_STATE, params);
}

makeTestFromAppointment(obj):Observable<any>{
  return this.http.post(ElesAppConst.MAKE_TEST_FROM_APPOINTMENT, obj);
}

}
