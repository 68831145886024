import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import {
  ConfirmValidParentMatcher,
  regExps,
  errorMessages
} from "../../@core/services/tn-form-error.service";
import { LoginService } from "../../services/login.service";
import { NotificationService } from "src/app/@core/services/notification.service";
@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"]
})
export class ForgetPasswordComponent implements OnInit {
  public forgetForm: FormGroup;
  public confirmValidParentMatcher = new ConfirmValidParentMatcher();
  public errors = errorMessages;
  public isLoading = false;
  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private notificationService: NotificationService
  ) {
    this.forgetForm = this.fb.group({
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(regExps.email)
        ])
      ]
    });
  }
  ngOnInit() {}
  forgetPassword() {
    if (this.forgetForm.valid) {
      this.isLoading = true;
      this.loginService
        .forgetPassword(this.forgetForm.value)
        .subscribe(respons => {
          this.isLoading = false;
          if (respons["code"] === 200) {
            this.notificationService.successToast(respons["message"]);
          } else {
            this.notificationService.infoToast(respons["message"]);
          }
        });
    } else {
      this.notificationService.infoToast("Please enter valid email");
    }
  }
}
