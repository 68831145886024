import CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class EncriptionDecriptionService {
    private keySize = 256;
    private ivSize = 128;
    private iterations = 100;
    private SECRET = 'lthp@$12&*01';
    encrypt(encText) {
        return new Promise(function (resolve, reject) {
            try {
                const salt = CryptoJS.lib.WordArray.random(128 / 8);
                const key = CryptoJS.PBKDF2(this.SECRET, salt, {
                    keySize: this.keySize / 32,
                    iterations: this.iterations
                  });
                const iv = CryptoJS.lib.WordArray.random(128 / 8);
                const encrypted = CryptoJS.AES.encrypt(encText, key, {
                    iv: iv,
                    padding: CryptoJS.pad.Pkcs7,
                    mode: CryptoJS.mode.CBC
                });
                const encryptedData = salt.toString() + iv.toString() + encrypted.toString();
                resolve(encryptedData);
            } catch (e) {
                reject(e);
            }
        });
    }

    decrypt1(decText) {
        return new Promise((resolve, reject) => {
            try {
                if(decText){
                    const salt = CryptoJS.enc.Hex.parse(decText.substr(0, 32));
                    const iv = CryptoJS.enc.Hex.parse(decText.substr(32, 32));
                    const encrypted = decText.substring(64);
                    const key = CryptoJS.PBKDF2(this.SECRET, salt, {
                        keySize: this.keySize / 32,
                        iterations: this.iterations
                        });
    
                    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
                        iv: iv,
                        padding: CryptoJS.pad.Pkcs7,
                        mode: CryptoJS.mode.CBC
                    });
                    resolve(decrypted.toString(CryptoJS.enc.Utf8));
                }else{
                    resolve();
                }
            } catch (error) {
                reject(error);
            }
        });
    }
}

