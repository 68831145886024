import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { SeoService } from "./services/seo.service";

declare let gtag: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(public router: Router, private activatedRoute: ActivatedRoute, private seoService: SeoService) {
    const navEndEvents = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag("config", "UA-163392256-2", {
        path_page: event.urlAfterRedirects,
      });
    });
  }

  ngOnInit() {
  // On page SEO for every page title decsription change
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      if(Object.keys(data).length != 0){
       let seoData = data['seo'];
       let seoTitle = seoData['title'];
       let seoMetaTags = seoData['metaTags'];
       this.seoService.updateTitle(seoTitle);
       this.seoService.updateMetaTags(seoMetaTags);
      } 
    });
  }
}
