import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatListModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatProgressBarModule,
  MatStepperModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatChipsModule

 } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
const materialArray = [
  FlexLayoutModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatListModule,
  FormsModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatMomentDateModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatStepperModule,
  MatTabsModule,
  MatChipsModule,

];

@NgModule({

  imports: [
    CommonModule,
    ...materialArray
  ],
  exports: [
    ...materialArray
  ]
})
export class MaterialModule { }
