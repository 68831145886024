import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import { StaffService } from "src/app/services/staff.service";


@Injectable({
  providedIn: 'root'
})
export class ChatService{

  private url:string = environment.apiUrl;//"http://localhost:3000";//
  private socket;
  private call_observers = [];
  constructor() { 
    this.socket = io(this.url);
  }

  public sendMessage(message:any) {
    this.socket.emit('chatMessage', message);
  }
  public joinRoom(obj){
    this.socket.emit('joinRoom', obj)
  }
  public getMessages () : Observable<any> {
    return Observable.create((observer) => {
      this.socket.on('message', function(msg){
        observer.next(msg)
      });
  });
  }

  public update_presence(obj){
    this.socket.emit("update_presence", obj);
    this.socket.io.on("reconnect", ()=>{
      this.socket.emit("update_presence", obj);
    })
  }
  public subscribe(obj){
    this.socket.emit("subscribe", obj);
  }

  public getPresence () : Observable<any> {
    return Observable.create((observer) => {
      this.socket.on('update_presence_list', function(msg){
        observer.next(msg)
      });
  });
  }

  public getVisitRequest () : Observable<any> {
    return Observable.create((observer) => {
      this.socket.on('visit_request', function(msg){
        observer.next(msg)
      });
  });
  }

  public socketDisconnect(){
    this.socket.disconnect();
  }

  public requestVisit (staffId) : Observable<any> {
    let staff_details_:any ={};
     //@ts-ignore
    Object.assign(staff_details_, global.patient);
    delete staff_details_.staffDetails.sign_path;
    delete staff_details_.staffDetails.subscription_plan_id;
    this.socket.emit("visit_request", {doctor_id:staffId, patient_details:staff_details_})
    //wyslij chat do staffid
    return Observable.create((observer) => {
      this.socket.on('visit_request_response', function(msg){
        observer.next(msg)
      });
  });
  }

  public requestVisitResponse(obj){
    this.socket.emit("visit_request_response", obj)
  }

  public cancelRequestVisit(doctor_id){
    this.socket.emit("cancel_visit_request", {id:doctor_id});
  }

  ////call events
  public getCallEvents(){//wywolywany tylko z init header, pobiera ogolne events i pozniej dzieli dla observers
      this.socket.on('call_event', function(msg){
        //@ts-ignore
        global.presence_obj.call_observers.forEach(elem=>{
          if(elem.sid == msg.CallSid){
              elem.fun(msg);
          }
        })
    });
  }
  
  public subscribeToCall(sid):Observable<any>{//sub do events z danej calls
    this.socket.emit("subscribe_to_call", sid);
    return Observable.create((observer) => {
      //@ts-ignore
      global.presence_obj.call_observers.push({sid:sid, fun:function(msg){
        observer.next(msg)
      }});
    });
  }
  ///user notifications
  public getNotifications() : Observable<any> {//wywolywany tylko z init header, pobiera ogolne user notifications i decyduje
    return Observable.create((observer) => {
      this.socket.on('user_notification', function(msg){
        observer.next(msg)
      });
  });
  }


}
