import { aiHealthCheckerResultParameters } from './mockData'

export const convertDataURIToBinary = dataURI => {
  const BASE64_MARKER = ";base64,";
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

export const toDataUrl = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

export const calculateAge = (dob) => {
  const diff_ms = Date.now() - new Date(dob).getTime();
  const age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const getAiHealthCheckerColor = (parameters) => {
  const colors = {};
  Object.keys(parameters).forEach((key) => {
    const rangeValues = aiHealthCheckerResultParameters[key];
    if (rangeValues && rangeValues.length) {
      const color = rangeValues.find(
        ({ start, end }) => start <= parameters[key] && parameters[key] <= end
      );
      if (color) {
        colors[key] = color.class;
      } else {
        colors[key] = "";
      }
    } else {
      colors[key] = "";
    }
  });
  return colors
};