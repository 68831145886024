import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'src/app/layout/users/common-profile/doctor-profile/slots-add-dialog/slots-add-dialog.component';

@Component({
  selector: 'app-dialog-reuse',
  templateUrl: './dialog-reuse.component.html',
  styleUrls: ['./dialog-reuse.component.scss']
})
export class DialogReuseComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogReuseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  sendOk(){
    this.dialogRef.close(true);
  }
}
