import { Component, OnInit, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { VideoService } from "src/app/services/video.service";
import { StaffService } from "src/app/services/staff.service";
import { connect,  Room, LocalVideoTrack,  createLocalTracks, createLocalVideoTrack, createLocalAudioTrack } from 'twilio-video';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from "../../../@core/services/notification.service";
import { SignaturePad } from "angular2-signaturepad/signature-pad";
import { convertDataURIToBinary, toDataUrl } from 'src/app/@core/utils/helper';
import {AppointmentService} from '../../../services/appointment.service';
import { RatingComponent } from 'src/app/@core/rating/rating.component';
import { MatDialog } from "@angular/material";
import { Country } from '../../../@core/services/country';
import { ConfirmDialogComponent } from 'src/app/@core/confirm-dialog/confirm-dialog.component';
import { ChatService } from '../../../@core/services/chat.service';
import { DialogReuseComponent } from '../doctors/consultation/devices/dialog-reuse/dialog-reuse.component';
import * as momentTz from 'moment-timezone';
import Metrics from './bm_metrics';
import { environment } from 'src/environments/environment';
 const mediaDevices = navigator.mediaDevices as any;

@Component({
  selector: 'app-open-consultation',
  templateUrl: './open-consultation.component.html',
  styleUrls: ['./open-consultation.component.scss']
})
export class OpenConsultationComponent implements OnInit {
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  usg_scanner = ""; 
  scripts={};
	waiting = true;
	consult = false;
  phone_view = false;
  accessToken = "";
  isStartButtonDisabled =  true;
  roomName = "";
  password="";
  isUserAuthenticated = false;
  localParticipant = {};
  isOtherUserJoined =false;
  room : any = "";
  room_twilio:{};
  isSubmitButtonDisabled = true;
  newSignature = false;
  isSignatureUploaded = false;
  isAuthorised = false;
  appointmentStartTime="";
  doctorName = "";
  doctorEmail = "";
  patientName = "";
  isMobile =  false;
  userId = "";
  sign_path = "";
  isScreenSharingDisabled = false;
  isCameraDisabled = false;
  appointmentId = "";
  appointmentMode = "";
  patient_conn_state="";
  patient_call_sid="";
  doctorId = "";
  isMicDisabled = false;
  timeLeft = "";
  intervalRef;
  timeZone ="";
  chatMessages = [];
  text = "";
  min_vid_height:String;
  isShowSettings = false;
  listOfWebCamDevices = [];
  webcamSelected = '';
  listOfMics = [];
  micSelected = '';
  default_audio_id = "";
  default_video_id = "";
  current_device_id = "";
  current_audio_device_id = "";
  local_devices = {};
  data_devices = {
    usg_scanner:{
      status:"unknown",
      data:{},
      id_scanner:""
    },
    oximeter:{
      status:"unknown",
      data:{}
    },
    thermometer:{
      status:"unknown",
      data:{}
    },
    blood_pressure:{
      status:"unknown",
      data:{}
    },
    w_scale:{
      status:"unknown",
      data:{}
    }
  };
  thermometer = {
    temperature:"",
    message:""
  };
  blood_pressure = {
    result:{},
    message:"",
    get_data:false,
    service:{},
    server:{}
  };
  oximeter = {
    spo2:{
    results_arr:[], 
    },
    pulseRate:{
      results_arr:[], 
      
    }
  };
  w_scale = {
    weight:"0 kg",
    impedance:{
     results:{},
     msg:"",
     impedance:Number,
     vital_data:{age:"NA", sex:"NA", height:"NA" }  
    },
    message:"",
    gatt_status:"unknown",
  };
  screenshot_index = 0;
  showDevice = true;//false;
  patient_url = "";
  preview_expanded = false;
    // full screen variables
    @ViewChild('fs',{ static: false }) fs: ElementRef;
    @HostBinding('class.is-fullscreen') isFullscreen = false;
    isActive = false;
    // full screen end
  participantList = [];
  constructor(
    private staffServ: StaffService,
    private _videoService : VideoService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private appointmentService: AppointmentService,
    private dialog: MatDialog,
    private router: Router,
    private country: Country,
    private chatService: ChatService
  ) { }

 async ngOnInit() {
    //@ts-ignore
    await this.getIdFromRoute();
    this.checkIfMobile();
    this.getMediaDevices();
    this.isSignatureUploaded = true;//sprawdzamy, jak nie to odzanczamy, inaczej widac przez chwile
    this.min_vid_height = (0.80 * window.innerHeight) + "px" ;
    let vid_ = document.getElementById("remote-media-div");
    if(vid_){
      //@ts-ignore
      vid_.style.minHeight = this.min_vid_height;
    }
  //TESTS
    let scanner_ = localStorage.getItem("usg_scanner");
    if(!scanner_){
      localStorage.setItem("usg_scanner", "2984-210721-0044");//this has to be set in the scanner setup page only!
    }
    if(localStorage.getItem("usg_scanner")){
      this.usg_scanner = localStorage.getItem("usg_scanner");
      this.data_devices["usg_scanner"].id_scanner = this.usg_scanner;
      //@ts-ignore
      global.usg_scanner = {id_scanner:this.usg_scanner};
    }
    this.appointmentService.getAppointmentDetailsWithRoomNameOpen({roomName: this.roomName}).subscribe((res) => {
          let startUTCString =  res['data']['startTimeUTC'];
          let date__ = new Date(startUTCString);
          this.appointmentStartTime =  date__.toLocaleString();
          let endUTCString =  res['data']['endTimeUTC'];
    })
  }

  startConsult(msg?) {
    //@ts-ignore
    if(global.staffDetails.patient){
          //@ts-ignore
        if(global.staffDetails.patient.sign_path){
          this.isSignatureUploaded = true;
        }
    }
    if(!this.isSignatureUploaded){
      this.notificationService.errorToast("Please Submit Signature");
      return
    }
    if(this.isSignatureUploaded){
      this.waiting = false;
      if(this.appointmentMode == "PhoneCall"){
        //nie wchodzimy w ekran consult
        this.phone_view = true;
      }else{
        this.consult = true;
      }
    }
    this.getToken();
}

  getIdFromRoute() {
    let this_ = this;
    return new Promise((resolve, reject)=>{
      this_.activatedRoute.params.subscribe(async (params) => {
        this_.roomName = params['id'];
        // this_.joinChat();
        // this_.subscribeChat();
        // await this_.getDoctorName();
        resolve();
      // this.getToken();
      });
    })
  }

  joinConsultation(){
      if(!this.password || this.password.length != 4){
        this.notificationService.errorToast("please enter 4 Digit passcode Received in Email");
        return;
      }
      let obj = {roomName: this.roomName, password: this.password}
      this.appointmentService.authenticatePatientOpen(obj).subscribe(async(res) => {
        if(res && res['code'] != 200){
          this.notificationService.errorToast("Passcode Incorrect");
          return;
        }
        //@ts-ignore
        global.staffDetails = {patient:res["data"]._doc};
        this.isUserAuthenticated = true;
        // this.consult = false;//TEST
        this.joinChat();
        this.subscribeChat();
        localStorage.setItem(
        "c__lafi",
          res["data"].token
        );
        localStorage.setItem("isLoggedin", "true");
        sessionStorage.setItem("staffId", res["data"]._doc.staff_id);
        sessionStorage.setItem("staffData", JSON.stringify(res["data"]._doc));
        //@ts-ignore
        if(global.staffDetails.patient.sign_path && global.staffDetails.patient.sign_path !=`${environment.apiUrl}/` ){
          this.isSignatureUploaded = true;
          this.startConsult();
        }else{
          this.isSignatureUploaded = false;
        }
        this.getDoctorName();
      })
  }

  getToken(){
      let params = {userId:this.userId, roomName : this.roomName, timeZone: this.timeZone, test:true}
      this._videoService.videoCall(params).subscribe((res) => {
        this.accessToken = res['token'];
        if(this.appointmentMode == "PhoneCall"){
          this.joinAsPhoneCall();
          this.patient_conn_state = "pending_phone";
        }else{
          this.joinRoom("");
        }
      }, (err) => {
        console.log(err);
      });
  }

  async joinRoom(options_){
    if(!options_){
      //choose device, any video but Virtual cam which is scanner
      let constraint;
      let devices = await navigator.mediaDevices.enumerateDevices();
      var camera = devices.find(device => (device.kind == "videoinput" && device.label != "Virtual Cam"));
      if (camera) {
            constraint = { deviceId: { exact: camera.deviceId}, width: 800  };   
      }
      options_ = {   
          audio: true,
          name:this.roomName,
          video:  constraint?constraint:{width: 800}
      }
    }
    //let vid_constraint_ = { width: 800 };
    if(this.default_video_id && options_.video){
      //@ts-ignore
      options_.video.deviceId = { exact: this.default_video_id};
    }  
    connect(this.accessToken, options_).then(room=>{ 
      this.room = room;
      this.room_twilio = room;
      //scanner
      //@ts-ignore
      if(global.usg_scanner){
        //@ts-ignore
        global.usg_scanner["room_name"] = this.room_twilio.name;
        //@ts-ignore
        global.usg_scanner["user_id"] = "usg_scanner_" + this.userId;
      }
      console.log(`Successfully joined a Room: ${room}`);
      this.appointmentService.updateConnectionState({roomName:this.roomName, patient_conn_state:"connected_web"}).subscribe((res)=>{
      console.log("patient conn state updated")
    });
      // Log any Participants already connected to the Room
    room.participants.forEach(participant => {
        if(participant['identity'].split("_")[0] == 'phone'){
          this.participantList.push({isPhone: true, sid: participant.sid, identity: participant['identity'].split("_")[1]})
          this.notificationService.successToast(`${participant['identity'].split("_")[1]} has joined the call`)
        }else{
          this.participantList.push({isPhone: false, sid: participant.sid, identity: participant.identity})
        }
        if(participant && participant.identity){
          this.isStartButtonDisabled = false;
          this.isOtherUserJoined =true;
          this.notificationService.successToast("Other User Has already Joined the call, Please click on start");
              //
          let obj = {
            timeZone: this.timeZone,
            senderName: this.patientName,
            sender: this.userId,
            roomName: this.roomName,
            message:JSON.stringify({video:this.listOfWebCamDevices, audio:this.listOfMics}),
            command: "devices_list",
            isFile: false
          }
          this.sendMessageCmd(obj) 
        }
        participant.tracks.forEach(publication => {
          if (publication.track) {
            // @ts-ignore
            document.getElementById('remote-media-div').appendChild(publication.track.attach());
          }
        });
  
        participant.on('trackSubscribed', track => {
          if(track.name.split("_")[0] == "screenShare"){
            let mainDiv = document.getElementById('remote-media-div');
            let nodeList = mainDiv.querySelectorAll('div');
            if(nodeList.length > 0){
              for(let i=0; i < nodeList.length; i++){
                nodeList[i].style.display = "none";
                        }
            }
            this.isScreenSharingDisabled = true
          }
          let div = document.createElement("div");
          div.id =  track['sid'];
          div.className = "remote-video-div-div";
          document.getElementById('remote-media-div').appendChild(div);
          // @ts-ignore
          document.getElementById(track.sid).appendChild(track.attach());
        });


        participant.on('trackUnsubscribed', track => {
  
        });
    });
    this.localParticipant = room.localParticipant;
    room.localParticipant.tracks.forEach(publication => {
      if (publication.track) {
        // @ts-ignore
        document.getElementById('local-media-div').appendChild(publication.track.attach());
      }
    });
    console.log(`Connected to the Room as LocalParticipant "${this.localParticipant['identity']}"`);
  
    room.on('participantConnected', participant => {
      if(participant.identity.indexOf("usg_scanner") == 0){//TODO add special handling for USG for remote viewers, i.e. no global.scanner object
        return;
      }
      if(participant['identity'].split("_")[0] == 'phone'){
        this.participantList.push({isPhone: true, sid: participant.sid, identity: participant['identity'].split("_")[1]})
        this.notificationService.successToast(`${participant['identity'].split("_")[1]} has joined the call`)
      }else{
        this.participantList.push({isPhone: false, sid: participant.sid, identity: participant.identity})
      }
      console.log(`A remote Participant connected: ${participant}`);
      participant.tracks.forEach((publication, index) => {
        this.isStartButtonDisabled = false;
        this.isOtherUserJoined =true;
        if(participant['identity'].split("_")[0] != 'phone'){
          this.notificationService.successToast("Other User Has Joined the call Please click on start");
        }
        if (publication.isSubscribed) {
          const track = publication.track;
          // @ts-ignore
          document.getElementById('remote-media-div').appendChild(track.attach());  
        }
      });

      participant.on('trackSubscribed', track => {
        if(track.name.split("_")[0] == "screenShare"){
          let mainDiv = document.getElementById('remote-media-div');
          let nodeList = mainDiv.querySelectorAll('div');
          if(nodeList.length > 0){
            for(let i=0; i < nodeList.length; i++){
              nodeList[i].style.display = "none";
            }
          }
          this.isScreenSharingDisabled = true
        }
        let div = document.createElement("div");
        div.id =  track['sid'];
        document.getElementById('remote-media-div').appendChild(div);
        // @ts-ignore
        document.getElementById(track.sid).appendChild(track.attach());
      });
  
      participant.on('trackUnsubscribed', track => {
        if(track.name.split("_")[0] == "screenShare"){
          let mainDiv = document.getElementById('remote-media-div');
          let nodeList = mainDiv.querySelectorAll('div');
          if(nodeList.length > 0){
            for(let i=0; i < nodeList.length; i++){
              nodeList[i].style.display = "block";
            }
          }
          this.isScreenSharingDisabled = false;
        }
        //@ts-ignore
        track.detach().forEach(element => element.remove());
      });
    });

    room.on('disconnected', (room, error) => {
      if(room['state'] == "disconnected"){
        this.endSession();
      }
    });

    room.on('participantDisconnected', participant => {
      participant.tracks.forEach((publication, index) => {
        let element = document.getElementById(publication.trackSid);
        if(element){
          element.parentNode.removeChild(element);
        } 
      });
      let participantIndex: any  = "" ;
      let particp =  this.participantList.find((item, i)=>{
        if(participant.sid == item.sid){
          participantIndex =  i
          return item;
        }
      })
      this.participantList.splice(participantIndex,1)
      console.log(`A remote Participant disconnected: ${participant}`);
      participant.tracks.forEach(track => {
        //@ts-ignore
        track.detach().forEach(element => element.remove());
      });
    });

    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
      if(error.message == "Could not start video source"){
        this.joinRoom({   
              audio: true,
              name:this.roomName,
             }
        )
      }
    });
  }
  
  ngOnDestroy(){
    if(this.room){
      this.room.disconnect();
    }
    clearInterval(this.intervalRef);
  }

  drawComplete() {
    this.isSubmitButtonDisabled = false;
  }

  drawStart() {
    this.newSignature = true;
  }

  submitConsent(){
    this.signatureUpload();
  }

  signatureUpload() {
    if(!this.isAuthorised){
      this.notificationService.errorNotification("please Check Authorization Checkbox");
      return;
    }
    if ( this.newSignature != true){
      if(!this.isSignatureUploaded){
        this.notificationService.errorNotification("Please Sign Before Submit")
      }
      return;
    }
    const dataURI = this.signaturePad.toDataURL('image/png');
    const arr = convertDataURIToBinary(dataURI);
    const blob = new Blob([arr], { type: 'image/png' });
    const formData = new FormData();
    //formData.append('staff_image', blob, this.roomName);
    //@ts-ignore
    formData.append('staff_image', blob, global.staffDetails.patient._id);
    this.staffServ.uploadStaffSignature(formData).subscribe((res) => {
    //this.appointmentService.uploadSignature(formData).subscribe((res) => {
      
      if(res['code'] == 200){
        this.isSignatureUploaded = true
        this.newSignature = false;
        this.startConsult();
      }
      else{
        this.notificationService.errorNotification(res.message)
      }}
      ,(err) => {
        console.log(err);
        });
  }

  getDoctorName(){
    let obj = {roomName: this.roomName}
    let this_ = this;
    return new Promise((resolve, reject)=>{
      this_.appointmentService.getAppointmentDetailsWithRoomNameOpen(obj).subscribe((res) => {
        this_.doctorName = res['data'].doctorName;
        this_.doctorEmail = res['data'].doctorEmail;
        this_.patientName = res['data'].patientName;
        this_.appointmentId = res['data'].appointmentId;
        this_.doctorId = res['data'].doctorId;
        this_.userId = res['data'].userId;
        this_.sign_path = res['data'].sign_path;
        this_.appointmentMode = res['data'].appointmentMode; 
        this_.patient_conn_state = res['data'].patient_conn_state;
        this_.patient_call_sid = res['data'].patient_call_sid;
        //@ts-ignore
        global.staffDetails = {patient:res["data"].patient};
        if(this_.appointmentMode=="PhoneCall" && this_.patient_conn_state !="none"){//=="pending_phone"){
          this_.phone_view =true;
          let this_obj = this_;
          //subscribe to updates on call events
          //@ts-ignore
          global.presence_obj.subscribeToCall(this_.patient_call_sid).subscribe((response: any) => {
            console.log("call event response, status", response.CallStatus);
            //@ts-ignore
            this_obj.patient_conn_state = response.CallStatus;
          }); 
        }
       // this.getTimeZone(res['data']['endTimeUTC']);
       // this.getChats();
        resolve();
      }, (err) => {
        console.log(err);
      })
    })
  }

  startTimer(endTimeUTC){
    let endTime = new Date(endTimeUTC);
    let endTimeSeconds = endTime.getTime();
    let timeZone = this.timeZone;
    this.intervalRef =  setInterval(()=>{
      let minutes = this.timeLeft.split(":")[0];
      let seconds = this.timeLeft.split(":")[1];
      if(parseInt(minutes) < 1 && parseInt(seconds) < 1){
        this.room.disconnect();
        clearInterval(this.intervalRef);
      }

      let usersDateString = new Date().toLocaleString('en-US', { timeZone: timeZone });
      let usersDate = new Date(usersDateString);
       let mSeconds = endTimeSeconds - usersDate.getTime();
     this.timeLeft =  this.millisToMinutesAndSeconds(mSeconds);
    }, 1000)

  }

  clearSign() {
    this.signaturePad.clear();
  }


  checkIfMobile(){
    let type = navigator.userAgent;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(type)){
      this.isMobile = true;
    }
    
  }

// Mute Local Audio
  muteAudio(){
    this.room.localParticipant.audioTracks.forEach(publication => {
      publication.track.disable();
      this.isMicDisabled =  true;
    });
}

// Unmute Local Audio
unmuteAudio(){
  this.room.localParticipant.audioTracks.forEach(publication => {
    publication.track.enable();
    this.isMicDisabled =  false;
  });
}

// Mute Local Video
muteVideo(){
  this.room.localParticipant.videoTracks.forEach(publication => {
    publication.track.stop();
    publication.unpublish();
    this.isCameraDisabled = true;
  });
}

// UnMute Local Video
unmuteVideo(){
  createLocalVideoTrack().then(localVideoTrack => {
    return this.room.localParticipant.publishTrack(localVideoTrack);
  }).then(publication => {
    this.isCameraDisabled = false;
    console.log('Successfully unmuted your video:', publication);
  });
}

endSession(){
  let dialogRef = this.dialog.open(RatingComponent, {
    width: '650px',
    data: {
      type: "endSession"
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      let obj =result;
      obj['appointmentId'] =  this.appointmentId;
      obj['objectType'] =  'doctor';
      obj['objectId'] = this.doctorId;
     this.saveFeedback(obj);
    }
    else{
      this.router.navigate(['/lafiya/my-appointments']);
    }

  });
}

saveFeedback(obj){
  this.appointmentService.saveFeedback(obj).subscribe((res) => {
if(res['code'] == 200){
  this.notificationService.successToast("feedback submitted");
}else{
  this.notificationService.errorToast("somethig went wrong");
} 
this.router.navigate(['/lafiya/my-appointments']);
  }, (err) => {
    this.notificationService.errorToast("somethig went wrong");
  })
}

millisToMinutesAndSeconds(milliSecondss) {
  let minutes = Math.floor(milliSecondss / 60000);
  let seconds: any = ((milliSecondss % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

getTimeZone(endTimeUTC){
  this.country.getCurrentCountry().subscribe((response) => {
    this.timeZone = response['timezone'];
    //this.startTimer(endTimeUTC);
    this.startConsult()

      // this.getToken();
    });
}



openPhoneCallConfirmDialog(){
  let dialogRef = this.dialog.open(ConfirmDialogComponent, {
    width: '800px',
    data: {
      type: "inviteOthers"
    }
  });


  dialogRef.afterClosed().subscribe((result) => {
    if(result){
      let obj = result;
      obj['appointmentId'] = this.appointmentId;
      this.inviteOtherUser(obj);
    }

  });


}

inviteOtherUser(obj){
  this._videoService.inviteOtherUser(obj).subscribe((res) => {
if(res.code == 200){
this.notificationService.successToast("User Invited");
}
else{
  this.notificationService.errorToast(res.message || "something went wrong");
}
  }, (err) => {
this.notificationService.errorToast(err.message || "something went wrong")
  });
}
fullscreen(){
  
}

subscribeChat(){
 this.chatService
  .getMessages()
  .subscribe((message: any) => {
    if(message.command){
      console.log("command received" + message.command);
      this.processCommand(message);
    }else{
      this.chatMessages.push(message);
    }
  });
}

joinChat(){
  console.log("join chat roomName " +  this.roomName);
  this.chatService.joinRoom({roomName: this.roomName})
}
processCommand(msg){
  if(msg.command == "get_devices"){
    let obj = {
      timeZone: this.timeZone,
      senderName: this.patientName,
      sender: this.userId,
      roomName: this.roomName,
      message:JSON.stringify({video:this.listOfWebCamDevices, audio:this.listOfMics}),
      command: "devices_list",
      isFile: false
    }
     this.sendMessageCmd(obj) 
  }
  if(msg.command == "devices_list"){
    console.log("device list msg" + msg.message);
  }
  if(msg.command == "change_video_source"){
      let new_video_source = msg.message;
      console.log("new video source " + msg.message);
      this.changeVideoSource(new_video_source);
      let label_ = "";
      this.listOfWebCamDevices.forEach(elem=>{
        if(elem.device_id==new_video_source){
          label_ = elem.label;
        }
      });
      console.log("label found for the video -", label_);
      if(label_){
        this.openDialog("Your active camera is now the " + label_,"");
      }
  }
  if(msg.command == "change_video_to_default"){
    console.log("change video to default constraint");
    this.changeVideoSource("default_constraint");
    //this.openDialog("Your active camera is now the default one","");
    this.notificationService.successToast("Your default camera is active again");
  }
  if(msg.command == "change_audio_source"){
    let new_audio_source = msg.message;
    console.log("new audio source " + msg.message);
    this.changeAudioSource(new_audio_source);
  }
  if(msg.command == "change_audio_to_default"){
    console.log("new audio source " + this.default_audio_id);
    this.changeAudioSource(this.default_audio_id);
  }
  if(msg.command == "start_device"){
    console.log("start_device from doctor side " + msg.message);
    //this.notificationService.successToast(`Put the oximeter on your finger and press OK when ready`)
    if(msg.message == "oximeter"){
      this.openDialog("Please put the oximeter on your finger and press OK when ready","OXIMETER");
      console.log("start_device confirmed");
    }
    if(msg.message == "thermometer"){
      this.openDialog("The remote side requested to connect to thermometer","THERMOMETER");
      console.log("start_device confirmed");
    }
    if(msg.message == "blood_pressure"){
      this.openDialog("The remote side requested to connect to blood pressure device","BLOOD_PRESSURE");
      console.log("start_device confirmed");
    }
    if(msg.message == "w_scale"){
      this.openDialog("The remote side requested to connect to the weight scale","W_SCALE");
      console.log("start_device confirmed");
    }
    this.startDevice(msg.message);
  }
  if(msg.command == "stop_device"){
    console.log("stop_device from doctor side " + msg.message);
    this.stopDevice(msg.message);
  }
  if(msg.command == "tyto_patient_url"){
    this.notificationService.successToast(`patient url` + msg.message);
    this.patient_url = msg.message;
  }
  if(msg.command == "tyto_device_off"){
    console.log("Please turn on your Tyto device and make sure it is connected to network");
    this.openDialog("Please turn on your Tyto device and make sure it is connected to network","TYTO_DEVICE");
  }

}
getChats(){
  this._videoService.getChatsWithAppointmentId({appointmentId: this.appointmentId}).subscribe((res) => {
if(res && res.code == 200 && res.data){
this.chatMessages = res['data'];
}
  
  }, (err) => {
    console.log(err);
  });
}

sendMessageCmd(obj){
  console.log("send msg command", JSON.stringify(obj));
  this.chatService.sendMessage(obj);
}
sendMessage(event){
  event.stopPropagation();
    if(!this.text || this.text == ""){
      this.notificationService.errorToast("please enter message");
      return;
    }
    let obj = {
    timeZone: this.timeZone,
    senderName: this.patientName,
    sender: this.userId,
    roomName: this.roomName,
    message:this.text,
    appointment: this.appointmentId,
    isFile: false,
    command:null
  }
  if(this.text.indexOf("command") == 0){
    //test
    if(this.text.indexOf("command__set_data_devices") == 0){
      this.data_devices.thermometer.status = "running";
      //@ts-ignore
      this.data_devices.thermometer.data.temperature = "34.5";
      this.data_devices.blood_pressure.status = "running";
      //@ts-ignore
      this.data_devices.blood_pressure.data.systolic = "34";
      //@ts-ignore
      this.data_devices.blood_pressure.data.diastolic = "84";
      //@ts-ignore
      this.data_devices.blood_pressure.data.pulse = "394";
      
      return this.sendCommand("set_data_devices", this.data_devices);
    }
    //test
    this.text = this.text.replace("\n", "");
    let arr_ = this.text.split("__");
    obj.command = arr_[1];
    obj.message = arr_[2];
    if(!obj.message){
      obj.message = "ccc";
    }
    console.log("sending command message sendMessage");
  }
this.chatService.sendMessage(obj);
this.text = "";
  }


  onFileSelect(event){
    let files = [];
    let fileArray = event.target.files;
for(let i =0; i < fileArray.length ; i++) {
 let fileObj = {
   timeZone: this.timeZone,
   senderName: this.patientName,
   sender: this.userId,
   roomName: this.roomName,
   message:"",
   appointment: this.appointmentId,
   isFile: true
 }
 fileObj['originalFilename'] = fileArray[i].name;
 fileObj['fileExtension'] = fileArray[i].type.split('/')[1];
 fileObj['fileName'] = this.generateRandomString(this.doctorName.split(' ')[0], fileObj['fileExtension']);
 fileObj['file'] = fileArray[i];
 fileObj['size'] = fileArray[i].size;
 files.push(fileObj);
};
this.chatService.sendMessage({isFile: true, roomName: this.roomName ,files});
(event.target as HTMLInputElement).value = null;
  }

   generateRandomString(dName, ext) {
   let name = dName.replace(/\s+/g, '');
   let length = 10
   let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
   var result = '';
   for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
   return name + result + Date.now() + "." + ext;
 }


showSettings(){
  this.isShowSettings =  true;
}

getMediaDevices(){
  navigator.mediaDevices.enumerateDevices()
  .then((res) =>{
  this.listOfWebCamDevices = [];
  let found_ = 0;
  res.forEach(elem=>{
    if(elem.kind == 'videoinput'){
      console.log("video device ", elem.label, elem.deviceId);
      if(elem.label.indexOf("Digital Microscope") > -1){
        if(found_ == 0){
          console.log("Ear camera ", elem.label, elem.deviceId);
          this.listOfWebCamDevices.push({device_id:elem.deviceId, label:"Ear camera"});
          found_ = 1;
        }else if(found_ == 1){
          console.log("Skin camera ", elem.label, elem.deviceId);
          this.listOfWebCamDevices.push({device_id:elem.deviceId, label:"Skin camera"});
          found_ = 2;
        }else if(found_ == 2){
            console.log("Eye device ", elem.label, elem.deviceId);
            this.listOfWebCamDevices.push({device_id:elem.deviceId, label:"Eye camera"});
            found_ = 3;
        }
      }else if(elem.label.indexOf("Ear ") > -1){
        console.log("Ear camera ", elem.label, elem.deviceId);
        this.listOfWebCamDevices.push({device_id:elem.deviceId, label:"Ear camera"});
      }else if(elem.label.indexOf("Skin ") > -1){
        console.log("Skin camera ", elem.label, elem.deviceId);
        this.listOfWebCamDevices.push({device_id:elem.deviceId, label:"Skin camera"});
      }else if(elem.label.indexOf("Eye ") > -1){
        console.log("Eye camera ", elem.label, elem.deviceId);
        this.listOfWebCamDevices.push({device_id:elem.deviceId, label:"Eye camera"});
      }else if(elem.label.indexOf("Exam") > -1 || elem.label.indexOf("exam") > -1){
        console.log("Exam camera ", elem.label, elem.deviceId);
        this.listOfWebCamDevices.push({device_id:elem.deviceId, label:"Exam camera"});
      }else if(elem.label.indexOf("PTZ") > -1){
          console.log("default video device", elem.label, elem.deviceId);
          this.default_video_id = elem.deviceId; 
      }else if(elem.label.indexOf("Integrated Webcam") > -1){
         this.listOfWebCamDevices.push({device_id:elem.deviceId, label:"my test cam"});
      }
    }
  })
  //this.listOfWebCamDevices = this.listOfWebCamDevices.filter(elem=>{return elem});
  this.listOfMics = [];
  let found_steth_ = false;
  res.forEach(elem=>{
    if(elem.kind == 'audioinput'){
      console.log("audio device ", elem.label, elem.deviceId);
      if(elem.label.indexOf("efault") > -1){
        console.log("default audio id " + elem.deviceId);
        this.default_audio_id = elem.deviceId; 
      }
      if(elem.label.indexOf("PCP") > -1 && !found_steth_){
        console.log("stethoscope ", elem.label, elem.deviceId);
        this.listOfMics.push({device_id:elem.deviceId, label:"Stethoscope"});
        found_steth_ = true;
      }
    }
  })
  // this.listOfMics = this.listOfMics.filter(elem=>{return elem});
  //TODO filter only relevant ones
  this.local_devices = {video:this.listOfWebCamDevices, audio:this.listOfMics};
  this.showDevice = true;
  }, (err)=>{
    console.log(err)
  });
}

setMediaDevices(){
  let constraints = {
    audio: {
      deviceId: {exact: this.micSelected}
    },
    video: {
      deviceId: {exact: this.webcamSelected}
    }
  };

  navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
    window['stream'] = stream;
  }, (err) => {
    console.log(err)
  })


}
//full screen Functionality
  openFullscreen(): void {
    this.isFullscreen = true;
    const el = this.fs.nativeElement;
    let document:any=Document;

    if (!document.fullscreenElement &&    // alternative standard method
      !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.webkitRequestFullscreen) {
        // el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (el.msRequestFullscreen) {

        el.msRequestFullscreen();
      }
    }

    setTimeout(() => {
      this.isActive = true;
    }, 500);
  }

  closeFullscreen(): void {
    this.isFullscreen = false;
    this.isActive = false;
    const closeScreen = document as Document & {
    mozCancelFullScreen(): Promise<void>;
    webkitExitFullscreen(): Promise<void>;
    msExitFullscreen(): Promise<void>;
    };
    this.isFullscreen = false;
    if (closeScreen.exitFullscreen) {
    closeScreen.exitFullscreen();
    } else if (closeScreen.msExitFullscreen) {
    closeScreen.msExitFullscreen();
    } else if (closeScreen.mozCancelFullScreen) {
    closeScreen.mozCancelFullScreen();
    } else if (closeScreen.webkitExitFullscreen) {
    closeScreen.webkitExitFullscreen();
    }
  }
  //End Full screen 
  changeVideoSource(source){
    console.log("second cam id " + source);
    //
    let vid_ = document.getElementById('local-media-div');
    vid_.childNodes.forEach(node_=>{
      //@ts-ignore
      if(node_.localName == "video"){
        //@ts-ignore
        node_.srcObject.getTracks().forEach(t => {
          t.stop()});
        }
    })
    //
    let constraint = {}; 
    if(source == "default_constraint"){
      constraint = { width: 800 };
      if(this.default_video_id){
        //@ts-ignore
        constraint = {deviceId: { exact: this.default_video_id}};
      }
      this.current_device_id = "";
    }else{
      constraint = {deviceId: { exact: source}};
      this.current_device_id = source;
    }
    createLocalVideoTrack(   
    constraint).then(localVideoTrack=>{
    const tracks = Array.from(this.room.localParticipant.videoTracks.values());
    this.room.localParticipant.videoTracks.forEach(publication => {
      publication.track.stop();
      publication.unpublish();
    });
    //this.room.localParticipant.unpublishTracks(tracks);
    // @ts-ignore
    console.log(this.room.localParticipant.identity  + " removed track: " +  tracks[0].kind);
    this.detachTracks("local-media-div", "VIDEO");
    this.room.localParticipant.publishTrack(localVideoTrack);
    console.log(this.room.localParticipant.identity  + " added track: "  + localVideoTrack.kind);
    document.getElementById('local-media-div').appendChild(localVideoTrack.attach());
    console.log("video source attached");
  })
  }
  changeAudioSource(source){
    if(source == "default_constraint"){
      source = this.default_audio_id;
    }
    console.log("second audio id " + source);
    this.current_audio_device_id = source;
    createLocalAudioTrack(   
      {deviceId: { exact: source}}).then(localAudioTrack=>{
      const tracks = Array.from(this.room.localParticipant.audioTracks.values());
      //this.room.localParticipant.unpublishTracks(tracks);
      // @ts-ignore
      console.log(this.room.localParticipant.identity  + " removed track: " +  tracks[0].kind);
      this.detachTracks("local-media-div", "AUDIO");
      this.room.localParticipant.publishTrack(localAudioTrack);
      console.log(this.room.localParticipant.identity  + " added track: "  + localAudioTrack.kind);
      document.getElementById('local-media-div').appendChild(localAudioTrack.attach());
      console.log("audio source attached");
    })
  }

  async startDevice(id){
    console.log("startDevice");
    try{
    this.data_devices[id].status = "starting";
    //usg_scanner handling
    if(id === "usg_scanner"){
      this.startUSGScanner();
    }
    /// 
    //todo pass the device status to the doctor side
    this.sendCommand("set_data_devices", this.data_devices);
    if(id == "oximeter"){
      let device_;
      //@ts-ignore
      if(!global.data_device){
          console.log("requesting device on button click event");
          try{
          //@ts-ignore
          device_ =  await navigator.usb.requestDevice({ filters: [{ vendorId: 0x1c3d }] }) //oxy   
              //.requestDevice({ filters: [{ vendorId: 0xC45 }] }) //camera
          }catch(e){
            //@ts-ignore
            console.log(e);
            if(e.name == "NotFoundError"){
              this.notificationService.errorToast("check if the oximeter is connected to the computer");         
            }else if(e.message == "No device selected"){
              this.notificationService.errorToast("choose the oximeter device from the list and press Connect");
            }
            //here pass to doctor
            this.data_devices[id].status = "unknown";
            this.data_devices['thermometer'].status = "unknown";
            this.data_devices['blood_pressure'].status = "unknown";
            this.sendCommand("set_data_devices", this.data_devices);
            return;
          }//end catch
          //@ts-ignore
          global.data_device = device_;
          console.log("global device set");
      }else{
          console.log("connected global device");
          //@ts-ignore
          device_ = global.data_device;
      }
      this.openDevice(device_);    
    }else if(id == "thermometer"){
      //AOJ-20A
      //check if it is there 
      //@ts-ignore
      let found_ = await this.getBTDevices("AOJ-20A", "thermometer", this.thermometer_advert_received);
      if(found_){
        console.log("device already in getDevices");
        return;
      }
      //request device
          console.log("starting thermometer BT request");
          this.thermometer.message = "We need to pair the thermometer. Please click on the item in the Bluetooth scan window and press Pair."
          //@ts-ignore
          navigator.bluetooth.requestDevice({
            filters: [{
                services: ["0000ffe0-0000-1000-8000-00805f9b34fb"]
              }],
              optionalServices: ["0000ffe0-0000-1000-8000-00805f9b34fb"] 
            })
            .then(device => { 
              console.log("device found", device.name, "id", device.id);
              //@ts-ignore
             // global.bt_data_device.thermometer = device.id;
              return device.gatt.connect();     
           }).then(server => { 
              console.log("gatt connected");
              this.thermometer.message = "The thermometer is connected, please take a measurement now";
              this.btDeviceAction(server, "thermometer");
            }).catch(error => { 
                console.log(error); 
                this.thermometer.message = error;
          });
          
      }else if(id == "blood_pressure"){
        //namePrefix:"BLEsmart"
        //check if it is there 
        //@ts-ignore
        let found_ = await this.getBTDevices("BLEsmart", "blood_pressure", this.blood_pressure_advert_received);
        if(found_){
          console.log("device BP already in getDevices");
          return;
        }
        //zmiana
        this.blood_pressure.message = "Take a measure now and press button when finished";
        this.blood_pressure.get_data = true;
        this.data_devices["blood_pressure"].status = "running";
        this.sendCommand("set_data_devices", this.data_devices);
        return;//robimy parowanie dopiero w getresults..
        //request device
            console.log("starting BP BT request");
            this.blood_pressure.message = "We need to pair the blood pressure device. Please click on the item in the Bluetooth scan window and press Pair."
            //@ts-ignore
            navigator.bluetooth.requestDevice({
              filters: [{
                services: [0x1810]
              }],
              optionalServices: [0x1810, "0000180a-0000-1000-8000-00805f9b34fb"] //BP service
              })
              .then(device => { 
                console.log("BP device found", device.name, "id", device.id);
                //@ts-ignore
                return device.gatt.connect();     
             }).then(server => { 
                console.log("gatt connected");
                this.btDeviceAction(server, "blood_pressure");
              }).catch(error => { 
                  console.log(error); 
                  this.blood_pressure.message = error;
            });
            
        }else if(id == "w_scale"){
          //@ts-ignore
          let found_ = await this.getBTDevices("MIBFS", "w_scale", this.w_scale_advert_received);
          if(found_){
            console.log("device w_scale already in getDevices");
            return;
          }
          //request device
              console.log("starting w_scale request");
              this.w_scale.message = "We need to pair the scale. Please click on the item in the Bluetooth scan window and press Pair."
              //@ts-ignore
              navigator.bluetooth.requestDevice({
                filters: [ { name: 'MIBFS' } ],
                optionalServices: ["0000181b-0000-1000-8000-00805f9b34fb"] 
                })
                .then(device => { 
                  console.log("scale device found", device.name, "id", device.id);
                  //@ts-ignore
                  return device.gatt.connect();     
               }).then(server => { 
                  console.log("gatt connected");
                  this.btDeviceAction(server, "w_scale");
                }).catch(error => { 
                    console.log(error); 
                    this.w_scale.message = error;
              });
              
          }
    }catch(e){
      console.log(e);
    }
  }

  async openDevice(device){
    console.log("openDevice");
    var getShortValue = function(msb, lsb) {
      return msb * 256 + lsb;
    };
    try{
    await device.open();
    if (device.configuration === null) await device.selectConfiguration(1);//1
    this.data_devices["oximeter"].status = "running";
    this.data_devices["thermometer"].status = "unknown";
    this.data_devices["blood_pressure"].status = "unknown";
    this.sendCommand("set_data_devices", this.data_devices);
    await device.claimInterface(1);
    while (this.data_devices.oximeter.status == "running") {
        console.log("before transferIn");
        //test await this.sleep(1000);
        let result = await device.transferIn(1, 4096);
        console.log("after transfer in");
        if (result.data) {
          console.log("result data received");
          if (result.status === 'ok') {
            let spo2  = "--";
            let pulseRate = "--";
            if(result.data.byteLength > 8){
              spo2 = result.data.getUint8(8);
              pulseRate = getShortValue(
                result.data.getUint8(9),
                result.data.getUint8(10)
              );
            }else{
              console.log("too short buffer length")
            }
            // @ts-ignore
            if(spo2 > 200){
              spo2 = "--";
            }
            //@ts-ignore
            if(pulseRate > 300){
              pulseRate = "--"
            }
            console.log(`spo2: ${spo2}		pulse rate: ${pulseRate}`);
            this.data_devices.oximeter.data = {spo2:spo2, pulse_rate:pulseRate};
            if(spo2 != "--"){
                //@ts-ignore
                this.oximeter.spo2.results_arr.push(spo2);
                if(pulseRate != "--"){
                  //@ts-ignore
                  this.oximeter.pulseRate.results_arr.push(pulseRate)
                }
                let rem_ = this.oximeter.spo2.results_arr.length % 5;
                let len_ =this.oximeter.spo2.results_arr.length;
                //@ts-ignore
                if(( rem_ == 0) ||  len_ == 1){
                  let last_pulse_ = this.oximeter.pulseRate.results_arr.length >0?
                    this.oximeter.pulseRate.results_arr[this.oximeter.pulseRate.results_arr.length -1]:"--";
                  //if there is no pulserate yet in the array we have to send "--"  
                  let pulse_ = pulseRate != "--"?pulseRate:last_pulse_;
                  this.uploadMedicalData({ oximeter: {spo2:spo2, pulse_rate:pulse_}});
                }
            }
            //pass to doctor
            console.log("sending Oximeter data to doctor");
            this.sendCommand("set_data_devices", this.data_devices);
          }else{
              console.log("result status ", result.status)
          }
        }
        if (result.status === 'stall') {
          console.warn('Endpoint stalled.  Clearing.');
          await device.clearHalt(1);
        }
    }
    console.log("end of while openDevice");
    }catch(e){
      console.log(e);
      this.data_devices.oximeter.status = "unknown";
      this.data_devices["thermometer"].status = "unknown";
      this.data_devices["blood_pressure"].status = "unknown";
      //todo send to doctor.. 
      this.sendCommand("set_data_devices", this.data_devices);
    }
  }

  uploadMedicalData(requestObject){
    this.appointmentService
    .uploadMedicalData({ roomName: this.roomName, ...requestObject })
    .subscribe((response) => {
      console.log(response);
    })
  }  
  
  stopDevice(id){
    console.log("stopDevice", id);
    this.data_devices[id].status = "stopped";
    //todo send to doctor.. 
    this.sendCommand("set_data_devices", this.data_devices);
  }

  detachTracks(id_, type_){
    const myNode = document.getElementById(id_);
    myNode.childNodes.forEach(node_=>{
      if(node_.nodeName == type_){
        node_.remove();
      }
    })
  }

  sleep(ms){
    return new Promise(resolve=>{
        setTimeout(resolve,ms)
    })
  }

  sendCommand(cmd_, arg_){
    let obj = {
      timeZone: this.timeZone,
      senderName: this.patientName,
      sender: this.userId,
      message:JSON.stringify(arg_),
      command: cmd_,
      isFile: false,
      roomName:this.roomName
    }
    this.sendMessageCmd(obj) 
  }

  takePicture(e){
    console.log("takePicture");
    let canv_cont_ = document.getElementById("canvas_container");
    canv_cont_.style.display = "block";
    canv_cont_.style.position = "fixed";
    var canvas:any = document.getElementById('canvas');     
    var video_div_ = document.getElementById('local-media-div');
    let video_:any = video_div_.childNodes[0].nodeName == "VIDEO"?video_div_.childNodes[0]:video_div_.childNodes[1];
    canvas.width = video_.videoWidth;
    canvas.height = video_.videoHeight;
    canvas.getContext('2d').drawImage(video_, 0, 0, video_.videoWidth, video_.videoHeight);  
    
  }
  savePicture(){
    var canvas:any = document.getElementById('canvas');    
    let img_file_ = canvas.toDataURL(); 
    //todo uploading to the server
    this.uploadScreenshot(img_file_);
    let canv_cont_ = document.getElementById("canvas_container");
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canv_cont_.style.display = "none";
  }
  discardPicture(){
    var canvas:any = document.getElementById('canvas');     
    let canv_cont_ = document.getElementById("canvas_container");
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canv_cont_.style.display = "none";
  }

  uploadScreenshot(img_){//img_ is a data url
    console.log("uploadScreenshot");
    this.screenshot_index ++;
    const arr = convertDataURIToBinary(img_);
    const blob = new Blob([arr], { type: 'image/png' });
    const formData = new FormData();
    formData.append('screenshot', blob, this.roomName);
    formData.append('index', this.screenshot_index.toString());
    this.appointmentService.uploadScreenshot(formData).subscribe((res) => {
      if(res['code'] == 200){
        this.notificationService.successToast("The picture has been saved to the patient's medical records")
      }
      else{
        this.notificationService.errorNotification(res.message)
      }
    });
  }

    // dialog open when device is running

    openDialog(msg,deviceName) {
      const dialogRef = this.dialog.open(DialogReuseComponent,{
        data: {message:msg, deviceName:deviceName}
      });
  
      dialogRef.afterClosed().subscribe(result => {
       if(result){
        //  let msg={command:"start_device"};
        //  this.startDevice(msg)
       }
      });
    }

    handleTemperatureValueChanged(e, this_obj){
      console.log("temperature notification");
      const NEW_LINE_FLAG = 170;
      const t = e.target;
      let cache = [];
      let receivedData = new Uint8Array(t.value.byteLength);  
      for (let i = 0; i < t.value.byteLength; i++) {
        receivedData[i] = t.value.getUint8(i);
        const value = t.value.getUint8(i);
        if(cache.length == 7){
          console.log("cache", cache.toString());
          let numb_ = cache[4];
          let numb_2 = cache[5];
          let hex2 = numb_2.toString(16);
          if(hex2.length == 1){
            hex2 = "0"+hex2;
          }
          let hexString = numb_.toString(16);
          let temp_ = parseInt(hexString + hex2, 16);
          console.log("temp", temp_);
          let t_ = temp_ /100;
          this_obj.thermometer.temperature = `${t_}`;
          this_obj.data_devices.thermometer.data = {temperature:`${t_}`};
          this_obj.thermometer.message = "Your temperature:";
          this.uploadMedicalData({ thermometer: this_obj.data_devices.thermometer.data });
          this_obj.data_devices["oximeter"].status = "unknown";
          this_obj.data_devices["blood_pressure"].status = "unknown";
          this_obj.sendCommand("set_data_devices", this_obj.data_devices);
        }
        if (value == NEW_LINE_FLAG) {
          // if we find 170, parse data and start a new line/cache
         //parseData(cache);
          cache = [value];
        } else {
          // if not 170, keep looking but store each value in our cache so we can use it once we find our number
          cache = [...cache, value];
        }
      }
    }

    handleBPValueChanged(e, this_obj){
      console.log("BP notification received!");
      const t = e.target;
      let receivedData = new Uint8Array(t.value.byteLength);  
      for (let i = 0; i < t.value.byteLength; i++) {
        receivedData[i] = t.value.getUint8(i);
        const value = t.value.getUint8(i);
        console.log("value", value);
        if(i == 1){
          this_obj.blood_pressure.result.systolic = value;
        }
        if(i == 3){
          this_obj.blood_pressure.result.diastolic = value;
        }
        if(i == 5){
          this_obj.blood_pressure.result.mean_pressure = value;
        }
        if(i == 14){
          this_obj.blood_pressure.result.pulse = value;
        }
      }
      this_obj.data_devices.blood_pressure.data = {
        systolic:this_obj.blood_pressure.result.systolic,
        diastolic:this_obj.blood_pressure.result.diastolic,
        pulse:this_obj.blood_pressure.result.pulse
      };
      this.uploadMedicalData({ bloodPressure: this_obj.data_devices.blood_pressure.data });
      this_obj.data_devices["oximeter"].status = "unknown";
  //    this_obj.data_devices["thermometer"].status = "unknown";
      this_obj.sendCommand("set_data_devices", this_obj.data_devices);
    }

  async getBTDevices(name, type, advert_received){
     console.log("getBTDevices")
    try{
      //@ts-ignore
      let devices_ = await navigator.bluetooth.getDevices();
      let found_ = false;
      devices_.forEach(async dev_=>{
        if(dev_.name == name || (dev_.name && dev_.name.indexOf(name) ==0)){
          found_ = true;
          console.log("device id", name, "already paired");
          if(!dev_.gatt.connected){
            //try to connect first
            //if this is BLEsmart return false, it does not work with advert
            if(dev_.name && dev_.name.indexOf("BLEsmart") == 0){
              found_ = false;
              return false;
            }
            try{
            let server_ = await dev_.gatt.connect();
            console.log("gatt connected");
            await this.btDeviceAction(server_, type);
            }catch(e){
              if(e.code == 19){
                console.log("Not in range, please turn on the device")
                if(type == "thermometer"){
                  this.thermometer.message = "Not in range, please turn on the thermometer"
                }else if(type == "blood_pressure"){
                  this.blood_pressure.message = "Not in range, please turn on the blood pressure device"
                }else if(type == "w_scale"){
                  this.w_scale.message = "Not in range, please turn on the scale"
                }
              }
              console.log(e);
              dev_.watchAdvertisements();
              dev_.addEventListener('advertisementreceived', (e)=>{
                console.log("advert received");
                advert_received(e, this)});
              console.log("watchAdvertisement started for id", dev_.id);
            }
          }else{
             console.log("device already connected "); 
             await this.btDeviceAction(dev_.gatt, type);
          }
        }
      })
      return found_;
    }catch(e){
      console.log(e);
    }
  }

  async btDeviceAction(server_, type){//when this is called, the gatt is already connected
        console.log("btDeviceAction, type ", type);
        try{
        if(type == "thermometer"){
          let service = await server_.getPrimaryService("0000ffe0-0000-1000-8000-00805f9b34fb");
          let write_ch_ = await service.getCharacteristic('0000ffe2-0000-1000-8000-00805f9b34fb');
          const send_data = new Uint8Array([0xaa, 0x01, 0xd1, 0x00, 0xd0]);//
          console.log("write value");
          await write_ch_.writeValueWithoutResponse(send_data);
          let notify_ch_ = await service.getCharacteristic('0000ffe1-0000-1000-8000-00805f9b34fb');
          notify_ch_.addEventListener('characteristicvaluechanged', (e)=>{
            console.log("callback called");
            this.handleTemperatureValueChanged(e, this)});
          await notify_ch_.startNotifications();
          console.log("notifications started");
          this.data_devices["thermometer"].status = "running";
          this.sendCommand("set_data_devices", this.data_devices);
        }else if(type == "blood_pressure"){
          //2a49 read, 2a35 indicate   Blood P
          let service = await server_.getPrimaryService("00001810-0000-1000-8000-00805f9b34fb");////0x1810);
          this.blood_pressure.service = service;
          this.blood_pressure.server = server_;
          let read_ch = await service.getCharacteristic("00002a49-0000-1000-8000-00805f9b34fb")//0x2A49);//0x2A49
         // let re_ = await read_ch.readValue();
          this.blood_pressure.message = "Take a measure now and press button when finished";
          this.blood_pressure.get_data = true;
          this.data_devices["blood_pressure"].status = "running";
          this.sendCommand("set_data_devices", this.data_devices);
        //  console.log("BP read characteristic", re_);
         //notification start on pressing button
        }else if(type == "w_scale"){
          let service = await server_.getPrimaryService("0000181b-0000-1000-8000-00805f9b34fb");
          let notify_ch_ = await service.getCharacteristic("00002a9c-0000-1000-8000-00805f9b34fb");
          notify_ch_.addEventListener('characteristicvaluechanged', (e)=>this.handleW_ScaleValueChanged(e, this));
          await notify_ch_.startNotifications();
          console.log("scale notification started");
          this.w_scale.message = "Stand on the scale"
          this.w_scale.weight = "0 kg";
        }
        }catch(e){
          console.log(e);
        }
  }
  async startBPNotifications(){
    console.log("startBPNotifications");
    let this_obj = this;
    try{
   //@ts-ignore
   if(true){//!this.blood_pressure.service.device.gatt.connected){
      console.log("start bp notfication service not connect, will connect again");
      //@ts-ignore
        navigator.bluetooth.requestDevice({
          filters: [{
            services: [0x1810]
          }],
          optionalServices: [0x1810, "0000180a-0000-1000-8000-00805f9b34fb"] //BP service
          })
          .then(device => { 
            console.log("BP device found", device.name, "id", device.id);
            //@ts-ignore
            return device.gatt.connect();     
        }).then(async server_ => { 
            console.log("gatt connected");
            let service = await server_.getPrimaryService("00001810-0000-1000-8000-00805f9b34fb");////0x1810);
            let notify_ch_ = await service.getCharacteristic(0x2A35);//'00002a2b-0000-1000-8000-00805f9b34fb');
            notify_ch_.addEventListener('characteristicvaluechanged', (e)=>this_obj.handleBPValueChanged(e, this_obj));
            console.log("eventlistener added")
            await notify_ch_.startNotifications();
            console.log("BP notifications started");
          }).catch(error => { 
              console.log(error); 
              this.blood_pressure.message = error;
        });
      }else{
        console.log("bp start notification server connnected already")
        //@ts-ignore
        let notify_ch_ = await this.blood_pressure.service.getCharacteristic(0x2A35);//'00002a2b-0000-1000-8000-00805f9b34fb');
        notify_ch_.addEventListener('characteristicvaluechanged', (e)=>this_obj.handleBPValueChanged(e, this_obj));
        console.log("eventlistener added")
        await notify_ch_.startNotifications();
        console.log("BP notifications started");
      }
      }catch(e){
        console.log("startBPnotification error", e);
      }
      
  }

  async thermometer_advert_received(e, this_obj){
    console.log("thermometer advert received - device in range now, id", e.device.id);
    this_obj.thermometer.message = "Thermometer in range now";
    if(!e.device.gatt.connected){
      let server_ = await e.device.gatt.connect();
      console.log("gatt connected");
      this_obj.thermometer.message = "Thermometer connected";
      await this_obj.btDeviceAction(server_, "thermometer");
    }
  }
  
  async blood_pressure_advert_received(e, this_obj){
    console.log("blood pressure advert received - device in range now, id", e.device.id);
    this_obj.blood_pressure.message = "Blood pressure device in range now";
    if(!e.device.gatt.connected){
      let server_ = await e.device.gatt.connect();
      console.log("gatt connected");
      this_obj.blood_pressure.message = "Blood pressure device connected";
      await this_obj.btDeviceAction(server_, "blood_pressure");
    }
  }

  async w_scale_advert_received(e, this_obj){
    console.log("scale advert received - device in range now, id", e.device.id);
    this_obj.w_scale.message = "Scale device in range now";
    if(!e.device.gatt.connected && this_obj.w_scale.gatt_status !="connecting"){//nie chcemy zeby w kolko probowal connect
      this_obj.w_scale.gatt_status = "connecting";
      let server_;
      try{
      server_ = await e.device.gatt.connect();
      this_obj.w_scale.gatt_status = "connected";
      console.log("gatt connected");
      this_obj.w_scale.message = "Scale device connected";
      }catch(e){
        console.log(e);
        return this_obj.w_scale.gatt_status = "unknown";
      }
      await this_obj.btDeviceAction(server_, "w_scale");
    }
  }

getBPResult(event){
  this.startBPNotifications();
}

handleW_ScaleValueChanged(event, this_obj){
  const value = event.target.value;
  const buffer = new Uint8Array(value.buffer)
  const ctrlByte1 = buffer[1]
  const stabilized = ctrlByte1 & (1 << 5);
  console.log("stabilized", stabilized);
  const impedance = (buffer[10] << 8) + buffer[9];
  const weight = ((buffer[12] << 8) + buffer[11]) / 200;
  this_obj.w_scale.impedance.impedance = impedance;
  this_obj.w_scale.impedance.msg = "Taking measurement...";
  if (impedance > 0 && impedance < 3000 && stabilized) {
    this_obj.w_scale.impedance.msg = "";
    let age_;
    //@ts-ignore
    if(global.staffDetails.patient && global.staffDetails.patient.vitalInformation){
      //@ts-ignore
      age_ =new Number((new Date().getTime() - new Date(global.staffDetails.patient.dob).getTime()) / 31536000000).toFixed(0);
    }
    let height;
     //@ts-ignore
    if(global.staffDetails.patient && global.staffDetails.patient.vitalInformation){
      //@ts-ignore
      height = global.staffDetails.patient.vitalInformation.height;
    }
    //@ts-ignore
    const gender = global.staffDetails.patient.gender;
    if(!(gender && height && age_)){
      this_obj.w_scale.impedance.msg = "Please provide the missing data:";
      this_obj.w_scale.impedance.vital_data = {heigh:height, age:age_, sex:gender}
    }else{
      const metrics = new Metrics(weight, impedance, height, age_, gender)
      let arr_ = metrics.getResult();
      arr_.map(item => {
        this_obj.w_scale.impedance.results[item.name] = item.value.toFixed(2);
      })
    }
  }
  console.log('Weight:', weight, 'KG')
  console.log('Received from notification ' + value);
  console.log('Impedance:', impedance);
  this_obj.w_scale.weight = weight + " kg";
  this_obj.data_devices.w_scale.data = {weight:weight + " kg", ...this_obj.w_scale.impedance.results};
  this_obj.w_scale.message = "Your weight:";
  this_obj.sendCommand("set_data_devices", this_obj.data_devices);
}

closeCard(id){
  this.data_devices[id].status='unknown';
}
scaleVitalData(ev){//vital_data provided by patient during consultation, while using Scale, we need to update patient profile
  //and calculate metrics __
  let h_ = document.getElementById("wscale_height");
  let a_ = document.getElementById("wscale_age");
  //@ts-ignore
  let s_ = global.staffDetails.patient.gender;//document.getElementById("wscale_sex");
  //@ts-ignore
  if(!(h_.value && a_.value && s_)){
    return;
  }
  //@ts-ignore
  this.w_scale.impedance.vital_data = {height:h_.value, age:a_.value, sex:s_}
  let weight_ = this.w_scale.weight.split(" kg")[0];
  //@ts-ignore
  const metrics = new Metrics(weight_, this.w_scale.impedance.impedance, h_.value, a_.value, s_.value)
  let arr_ = metrics.getResult();
  arr_.map(item => {
    this.w_scale.impedance.results[item.name] = item.value.toFixed(2);
  })
  this.data_devices.w_scale.data = {weight:weight_ + " kg", ...this.w_scale.impedance.results};
  this.sendCommand("set_data_devices", this.data_devices);
}

saveScaleResults(){
  console.log("save scale results")
  this.data_devices.w_scale.data = {
    weight:this.w_scale.weight,
    ...this.w_scale.impedance.results
  }
  this.uploadMedicalData({ weight_scale: this.data_devices.w_scale.data});
}

 expandPreview(){
   let el_ = document.getElementById("local-media-div");
   if(el_){
  //   el_.className = "img-expanded";
   el_.style.height = "400px";
   el_.style.width = "400px";
   }
   this.preview_expanded = true;
 } 
 shrinkPreview(){
  let el_ = document.getElementById("local-media-div");
  if(el_){
   // el_.className = "img-100";
   el_.style.height = "180px";
   el_.style.width = "180px";
  }
  this.preview_expanded = false;
} 

joinAsPhoneCall(){
  let this_obj = this;
  //@ts-ignore
  let phone__ = `${global.staffDetails.patient.countrycode}${global.staffDetails.patient.phone}`;
  //@ts-ignore
  let param ={to:phone__, name:global.staffDetails.patient.firstName, roomName:this.roomName};
  this.appointmentService.joinAsPhoneCall(param).subscribe((res) => {
    console.log(res);
    this_obj.patient_call_sid = res.data.sid;
     //@ts-ignore
    global.presence_obj.subscribeToCall(this_obj.patient_call_sid).subscribe((response: any) => {
    console.log("call event response, status", response.CallStatus);
    //@ts-ignore
    this_obj.patient_conn_state = response.CallStatus;
  }); 
  },err=>{
    console.log(err);
  })
}

startChat(e){
  console.log("startChat");
  if(e.index === 0){
    //@ts-ignore
    global.changeChatDest(this.doctorEmail);
  }
}

startUSGScanner(){
  console.log("loading usg_scanner script");
  //@ts-ignore
  if(global.mode === "dev"){
    //@ts-ignore
    global.mode = "dev";
    this.loadScript('http://192.168.3.126:3003/build/static/js/main.js')
    this.loadScript('http://192.168.3.126:3003/build/static/js/one1.chunk.js')
    this.loadScript('http://192.168.3.126:3003/build/static/js/two1.chunk.js')
  }else{
    //@ts-ignore
    if(global.usg_scanner && global.usg_scanner.showScannerView){
      //@ts-ignore
        global.usg_scanner.showScannerView();
        return;
    }
    //@ts-ignore
    global.med_rest_api = "https://app.lafiyatelehealth.com:2445/";
    //@ts-ignore
    global.med_io_socket_url = "wss://app.lafiyatelehealth.com:3091/"
    this.loadScript('https://app.lafiyatelehealth.com:2445/build/static/js/main.js')
    this.loadScript('https://app.lafiyatelehealth.com:2445/build/static/js/one1.chunk.js')
    this.loadScript('https://app.lafiyatelehealth.com:2445/build/static/js/two1.chunk.js')
  }
}

loadScript(src) {
  try{
  return new Promise((resolve, reject) => {
    if (!this.scripts[src]) {
      var tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      tag.onload = () => {
          this.scripts[src] = true;
          resolve({});
      };
      let bd_ = document.getElementsByTagName('body');
      bd_[0].appendChild(tag);
    }
  })
  }catch(e){
    console.log(e);
  }
}

}



// import { Component, OnInit, ViewChild, ElementRef, HostBinding } from '@angular/core';
// import { VideoService } from "src/app/services/video.service";
// import { connect,  Room, LocalVideoTrack,  createLocalTracks, createLocalVideoTrack } from 'twilio-video';
// import { ActivatedRoute, Router } from '@angular/router';
// import { NotificationService } from "../../../@core/services/notification.service";
// import { SignaturePad } from "angular2-signaturepad/signature-pad";
// import { convertDataURIToBinary, toDataUrl } from 'src/app/@core/utils/helper';
// import {AppointmentService} from '../../../services/appointment.service';
// import { RatingComponent } from 'src/app/@core/rating/rating.component';
// import { MatDialog } from "@angular/material";
// import { Country } from '../../../@core/services/country';
// import { ConfirmDialogComponent } from 'src/app/@core/confirm-dialog/confirm-dialog.component';
// import { ChatService } from '../../../@core/services/chat.service';
//  const mediaDevices = navigator.mediaDevices as any;
//  import * as momentTz from 'moment-timezone';

// @Component({
//   selector: 'app-open-consultation',
//   templateUrl: './open-consultation.component.html',
//   styleUrls: ['./open-consultation.component.scss']
// })
// export class OpenConsultationComponent implements OnInit {
//   @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
// 	waiting = true;
// 	consult = false;
//   accessToken = "";
//   isStartButtonDisabled =  true;
//   roomName = ""
//   localParticipant = {};
//   isOtherUserJoined =false;
//   room : any = "";
//   isSubmitButtonDisabled = true;
//   newSignature = false;
//   isSignatureUploaded = false;
//   isAuthorised = false;
//   doctorName = "";
//   patientName = "";
//   isMobile =  false;
//   userId = "";
//   isScreenSharingDisabled = false;
//   isCameraDisabled = false;
//   appointmentId = "";
//   doctorId = "";
//   isMicDisabled = false;
//   timeLeft = "";
//   intervalRef;
//   timeZone ="";
//   chatMessages = [];
//   text = "";
//   isShowSettings = false;
//   listOfWebCamDevices = [];
//   webcamSelected = '';
//   listOfMics = [];
//   micSelected = '';
//   isUserAuthenticated = false;
//   patientFullName = "";
//   password = "";
//   purpose : any = "";
//   purposeList = [
//     {name:"Follow Up Appointment", value:"follow_up_appointment"},
//     {name:"First-Time Visit", value:"first_time_visit"},
//     {name:"Ongoing Treatment", value:"ongoing_treatment"},
//     {name:"Medicine Review", value:"medicine_review"},
//     {name:"Prescreption Write Up", value:"prescreption_write_up"},
//     {name:"EMDT", value:"emdt"}
//   ];
//   appointmentStartTime : any;
//     // full screen variables
//     @ViewChild('fs',{ static: false }) fs: ElementRef;
//     @HostBinding('class.is-fullscreen') isFullscreen = false;
//     isActive = false;
//     // full screen end
//   participantList = [];
//   constructor(
//     private _videoService : VideoService,
//     private activatedRoute: ActivatedRoute,
//     private notificationService: NotificationService,
//     private appointmentService: AppointmentService,
//     private dialog: MatDialog,
//     private router: Router,
//     private country: Country,
//     private chatService: ChatService
//   ) { }

//   ngOnInit() {
//     this.getIdFromRoute();
//     this.checkIfMobile();
//     //this.getMediaDevices();
//     this.country.getCurrentCountry().subscribe((response) => {
//       this.timeZone = response['timezone'];
//       });
//   }

//   startConsult() {
//     if(!this.isSignatureUploaded){
// this.notificationService.errorToast("Please Submit Signature");
// return
//     }
// if(this.isOtherUserJoined && this.isAuthorised && this.isSignatureUploaded){
//   this.waiting = false;
//   this.consult = true;
// }

//   }

//   getIdFromRoute() {
//     this.activatedRoute.params.subscribe((params) => {
//       this.roomName = params['id'];
//       this.getAppointmentDetails()
  
//     });
//   }

//   getToken(){
//  let params = {purpose: this.purpose, roomName : this.roomName, timeZone: this.timeZone, password: this.password}
//   this._videoService.videoCallOpen(params).subscribe((res) => {
//   this.accessToken = res['token'];
//   this.joinRoom();
//   }, (err) => {
//   console.log(err);
//   });
//   }

 


//   joinRoom(){
//     connect(this.accessToken, {   
//       audio: true,
//       name:this.roomName,
//       video: { width: 400 } }).then(room => {
//         this.isUserAuthenticated =  true;
//         let purposeoFCall: any = this.purposeList.find((obj) =>{
// if(obj.value == this.purpose){
//   return true
// }
//         })
//         this.text = `Hi, This session is regarding ${purposeoFCall['name']}`;
//         this.sendMessage()
//         this.room = room;
//       console.log(`Successfully joined a Room: ${room}`);
//                 // Log any Participants already connected to the Room
//   room.participants.forEach(participant => {
//     if(participant['identity'].split("_")[0] == 'phone'){
//       this.participantList.push({isPhone: true, sid: participant.sid, identity: participant['identity'].split("_")[1]})
//       this.notificationService.successToast(`${participant['identity'].split("_")[1]} has joined the call`)
//     }else{
//       this.participantList.push({isPhone: false, sid: participant.sid, identity: participant.identity})
//     }
//     if(participant && participant.identity){
//   this.isStartButtonDisabled = false;
//   this.isOtherUserJoined =true;
//   this.notificationService.successToast("Other User Has already Joined the call, Please click on start");
//     }
//   participant.tracks.forEach(publication => {
//     if (publication.track) {
//       // @ts-ignore
//      document.getElementById('remote-media-div').appendChild(publication.track.attach());
//     }
//   });
  
//   participant.on('trackSubscribed', track => {
//     if(track.name.split("_")[0] == "screenShare"){
//        let mainDiv = document.getElementById('remote-media-div');
//        let nodeList = mainDiv.querySelectorAll('div');
//        if(nodeList.length > 0){
//          for(let i=0; i < nodeList.length; i++){
//            nodeList[i].style.display = "none";
//                    }
//        }
//         this.isScreenSharingDisabled = true
//       }
//      let div = document.createElement("div");
//      div.id =  track['sid'];
//      document.getElementById('remote-media-div').appendChild(div);
//         // @ts-ignore
//         document.getElementById(track.sid).appendChild(track.attach());
//  });


//  participant.on('trackUnsubscribed', track => {
//    if(track.name.split("_")[0] == "screenShare"){
//      let mainDiv = document.getElementById('remote-media-div');
//      let nodeList = mainDiv.querySelectorAll('div');
//      if(nodeList.length > 0){
//        for(let i=0; i < nodeList.length; i++){
//          nodeList[i].style.display = "block";
//                  }
//      }
//      this.isScreenSharingDisabled = false;
//    }
//         // @ts-ignore
//    track.detach().forEach(element => element.remove());
//  });

//   });
//        this.localParticipant = room.localParticipant;
//        room.localParticipant.tracks.forEach(publication => {


//         if (publication.track) {
//         // @ts-ignore
//          document.getElementById('local-media-div').appendChild(publication.track.attach());
//         }
//       });
//   console.log(`Connected to the Room as LocalParticipant "${this.localParticipant['identity']}"`);
  
  
 
//   room.on('participantConnected', participant => {
//     if(participant['identity'].split("_")[0] == 'phone'){
//       this.participantList.push({isPhone: true, sid: participant.sid, identity: participant['identity'].split("_")[1]})
//       this.notificationService.successToast(`${participant['identity'].split("_")[1]} has joined the call`)
//     }else{
//       this.participantList.push({isPhone: false, sid: participant.sid, identity: participant.identity})
//     }
//   console.log(`A remote Participant connected: ${participant}`);
//   participant.tracks.forEach((publication, index) => {
//     this.isStartButtonDisabled = false;
//     this.isOtherUserJoined =true;
//     if(participant['identity'].split("_")[0] != 'phone'){
//       this.notificationService.successToast("Other User Has Joined the call Please click on start");
//     }

//     if (publication.isSubscribed) {
//       const track = publication.track;
//         // @ts-ignore
//      document.getElementById('remote-media-div').appendChild(track.attach());
  
    
//     }
//   });
  
//   participant.on('trackSubscribed', track => {
//     if(track.name.split("_")[0] == "screenShare"){
//       let mainDiv = document.getElementById('remote-media-div');
//       let nodeList = mainDiv.querySelectorAll('div');
//       if(nodeList.length > 0){
//         for(let i=0; i < nodeList.length; i++){
//           nodeList[i].style.display = "none";
//                   }
//       }
//        this.isScreenSharingDisabled = true
//      }
//      let div = document.createElement("div");
//       div.id =  track['sid'];
//       document.getElementById('remote-media-div').appendChild(div);
//         // @ts-ignore
//       document.getElementById(track.sid).appendChild(track.attach());
 
//   });
  

//   participant.on('trackUnsubscribed', track => {
//     if(track.name.split("_")[0] == "screenShare"){
//       let mainDiv = document.getElementById('remote-media-div');
//       let nodeList = mainDiv.querySelectorAll('div');
//       if(nodeList.length > 0){
//         for(let i=0; i < nodeList.length; i++){
//           nodeList[i].style.display = "block";
//                   }
//       }
//       this.isScreenSharingDisabled = false;
//     }
//  //   track.detach().forEach(element => element.remove());
//   });
  
//   });

//   room.on('disconnected', (room, error) => {
// if(room['state'] == "disconnected"){
//   this.endSession();
// }
//   });

//   room.on('participantDisconnected', participant => {

//     participant.tracks.forEach((publication, index) => {
//       let element = document.getElementById(publication.trackSid);
//       if(element){
//         element.parentNode.removeChild(element);
//       }
     
//     });

//    let participantIndex: any  = "" ;
//  let particp =  this.participantList.find((item, i)=>{
// if(participant.sid == item.sid){
//   participantIndex =  i
//   return item;
// }
//     })
//     this.participantList.splice(participantIndex,1)
//     console.log(`A remote Participant disconnected: ${participant}`);
//     participant.tracks.forEach(track => {
      
//    //   track.detach().forEach(element => element.remove());
//     });
  
 
    
//     });
  
  
//     }, error => {
//       console.error(`Unable to connect to Room: ${error.message}`);
//     });
//   }
  
//   ngOnDestroy(){
//     if(this.room){
//       this.room.disconnect();
//     }
//     clearInterval(this.intervalRef);
//   }

//   drawComplete() {
//     this.isSubmitButtonDisabled = false;
//   }

//   drawStart() {
//     this.newSignature = true;
//   }

//   submitConsent(){
//     this.signatureUpload();
//   }

//   signatureUpload() {
//     if(!this.isAuthorised){
// this.notificationService.errorNotification("please Check Authorization Checkbox");
// return;
//     }
//     if ( this.newSignature != true){
//       if(!this.isSignatureUploaded){
//         this.notificationService.errorNotification("Please Sign Before Submit")
//       }
// return;
//     }
//     const dataURI = this.signaturePad.toDataURL('image/png');
//     const arr = convertDataURIToBinary(dataURI);
//     const blob = new Blob([arr], { type: 'image/png' });
//     const formData = new FormData();
//     formData.append('staff_image', blob, this.roomName);
//     formData.append('password', this.password);
//     this.appointmentService.uploadSignatureOpen(formData).subscribe((res) => {
      
//       if(res['code'] == 200){
//         this.isSignatureUploaded = true
//         this.newSignature = false;
//         this.notificationService.successNotification("Signature Saved Successfully")
//       }
//       else{
//         this.notificationService.errorNotification(res.message)
//       }
       
//     });
//   }

//   getDoctorName(){
//     let obj = {roomName: this.roomName}
//     this.appointmentService.getAppointmentDetailsWithRoomNameOpen(obj).subscribe((res) => {
// this.getTimeZone(res['data']['endTimeUTC']);
// this.getChats();
//     }, (err) => {
// console.log(err);
//     })
//   }

//   getAppointmentDetails(){
//     let obj = {roomName: this.roomName}
//     this.appointmentService.getAppointmentDetailsWithRoomNameOpen(obj).subscribe((res) => {
// this.doctorName = res['data'].doctorName;
// this.patientName = res['data'].patientName;
// this.appointmentId = res['data'].appointmentId;
// this.doctorId = res['data'].doctorId;
// this.userId = res['data'].userId;
// this.appointmentStartTime = new Date(res['data']['startTimeUTC'])
//     }, (err) => {
// console.log(err);
//     })
//   }

//   startTimer(endTimeUTC){
//     let endTime = new Date(endTimeUTC);
//     let endTimeSeconds = endTime.getTime();
//     let timeZone = this.timeZone;
//     this.intervalRef =  setInterval(()=>{
//       let minutes = this.timeLeft.split(":")[0];
//       let seconds = this.timeLeft.split(":")[1];
//       if(parseInt(minutes) < 1 && parseInt(seconds) < 1){
//         this.room.disconnect();
//         clearInterval(this.intervalRef);
//       }

//       let usersDateString = new Date().toLocaleString('en-US', { timeZone: timeZone });
//       let usersDate = new Date(usersDateString);
//        let mSeconds = endTimeSeconds - usersDate.getTime();
//      this.timeLeft =  this.millisToMinutesAndSeconds(mSeconds);
//     }, 1000)

//   }

//   clearSign() {
//     this.signaturePad.clear();
//   }


//   checkIfMobile(){
//     let type = navigator.userAgent;
//     if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(type)){
//       this.isMobile = true;
//     }
    
//   }

// // Mute Local Audio
//   muteAudio(){
//     this.room.localParticipant.audioTracks.forEach(publication => {
//       publication.track.disable();
//       this.isMicDisabled =  true;
//     });
// }

// // Unmute Local Audio
// unmuteAudio(){
//   this.room.localParticipant.audioTracks.forEach(publication => {
//     publication.track.enable();
//     this.isMicDisabled =  false;
//   });
// }

// // Mute Local Video
// muteVideo(){
//   this.room.localParticipant.videoTracks.forEach(publication => {
//     publication.track.stop();
//     publication.unpublish();
//     this.isCameraDisabled = true;
//   });
// }

// // UnMute Local Video
// unmuteVideo(){
//   createLocalVideoTrack().then(localVideoTrack => {
//     return this.room.localParticipant.publishTrack(localVideoTrack);
//   }).then(publication => {
//     this.isCameraDisabled = false;
//     console.log('Successfully unmuted your video:', publication);
//   });
// }

// endSession(){
//   let dialogRef = this.dialog.open(RatingComponent, {
//     width: '650px',
//     data: {
//       type: "endSession"
//     }
//   });
//   dialogRef.afterClosed().subscribe(result => {
//     if (result) {
//       let obj =result;
//       obj['appointmentId'] =  this.appointmentId;
//       obj['objectType'] =  'doctor';
//       obj['objectId'] = this.doctorId;
//      this.saveFeedback(obj);
//     }
//     else{
//       this.router.navigate(['/lafiya/my-appointments']);
//     }

//   });
// }

// saveFeedback(obj){
//   this.appointmentService.saveFeedback(obj).subscribe((res) => {
// if(res['code'] == 200){
//   this.notificationService.successToast("feedback submitted");
// }else{
//   this.notificationService.errorToast("somethig went wrong");
// } 
// this.router.navigate(['/lafiya/my-appointments']);
//   }, (err) => {
//     this.notificationService.errorToast("somethig went wrong");
//   })
// }

// millisToMinutesAndSeconds(milliSecondss) {
//   let minutes = Math.floor(milliSecondss / 60000);
//   let seconds: any = ((milliSecondss % 60000) / 1000).toFixed(0);
//   return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
// }

// getTimeZone(endTimeUTC){
//   this.country.getCurrentCountry().subscribe((response) => {
//     this.timeZone = response['timezone'];
//     this.startTimer(endTimeUTC);
//     this.getToken();
//     });
// }



// openPhoneCallConfirmDialog(){
//   let dialogRef = this.dialog.open(ConfirmDialogComponent, {
//     width: '800px',
//     data: {
//       type: "inviteOthers"
//     }
//   });


//   dialogRef.afterClosed().subscribe((result) => {
//     if(result){
//       let obj = result;
//       obj['appointmentId'] = this.appointmentId;
//       this.inviteOtherUser(obj);
//     }

//   });


// }

// inviteOtherUser(obj){
//   this._videoService.inviteOtherUser(obj).subscribe((res) => {
// if(res.code == 200){
// this.notificationService.successToast("User Invited");
// }
// else{
//   this.notificationService.errorToast(res.message || "something went wrong");
// }
//   }, (err) => {
// this.notificationService.errorToast(err.message || "something went wrong")
//   });
// }
// fullscreen(){
  
// }

// subscribeChat(){
//  this.chatService
//   .getMessages()
//   .subscribe((message: any) => {
//     this.chatMessages.push(message);
//   });
// }

// joinChat(){
//   this.chatService.joinRoom({roomName: this.roomName})
// }

// getChats(){
//   this._videoService.getChatsWithAppointmentIdOpen({appointmentId: this.appointmentId, password: this.password}).subscribe((res) => {
// if(res && res.code == 200 && res.data){
// this.chatMessages = res['data'];
// }
  
//   }, (err) => {
//     console.log(err);
//   });
// }

// sendMessage(event?){
//   if(event){
//     event.stopPropagation();
//   }

//     if(!this.text || this.text == ""){
// this.notificationService.errorToast("please enter message");
// return;
//     }
//     let obj = {
//     timeZone: this.timeZone,
//     senderName: this.patientName,
//     sender: this.userId || this.appointmentId,
//     roomName: this.roomName,
//     message:this.text,
//     appointment: this.appointmentId,
//     isFile: false
//   }
// this.chatService.sendMessage(obj);
// this.text = "";
//   }


//   onFileSelect(event){
//     let files = [];
//     let fileArray = event.target.files;
// for(let i =0; i < fileArray.length ; i++) {
//  let fileObj = {
//    timeZone: this.timeZone,
//    senderName: this.patientName,
//    sender: this.userId,
//    roomName: this.roomName,
//    message:"",
//    appointment: this.appointmentId,
//    isFile: true
//  }
//  fileObj['originalFilename'] = fileArray[i].name;
//  fileObj['fileExtension'] = fileArray[i].type.split('/')[1];
//  fileObj['fileName'] = this.generateRandomString(this.doctorName.split(' ')[0], fileObj['fileExtension']);
//  fileObj['file'] = fileArray[i];
//  fileObj['size'] = fileArray[i].size;
//  files.push(fileObj);
// };
// this.chatService.sendMessage({isFile: true, roomName: this.roomName ,files});
// (event.target as HTMLInputElement).value = null;
//   }

//    generateRandomString(dName, ext) {
//    let name = dName.replace(/\s+/g, '');
//    let length = 10
//    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
//    var result = '';
//    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
//    return name + result + Date.now() + "." + ext;
//  }


// showSettings(){
//   this.isShowSettings =  true;
// }

// getMediaDevices(){
//   navigator.mediaDevices.enumerateDevices()
//   .then((res) =>{
//   this.listOfWebCamDevices = res.filter((obj) => obj.kind == 'videoinput');
//   this.listOfMics = res.filter((obj) => obj.kind == 'audioinput');
//   }, (err)=>{
//     console.log(err)
//   });
// }

// setMediaDevices(){
//   let constraints = {
//     audio: {
//       deviceId: {exact: this.micSelected}
//     },
//     video: {
//       deviceId: {exact: this.webcamSelected}
//     }
//   };

//   navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
//     window['stream'] = stream;
//   }, (err) => {
//     console.log(err)
//   })


// }
// //full screen Functionality
//   openFullscreen(): void {
//     this.isFullscreen = true;
//     const el = this.fs.nativeElement;
//     let document:any=Document;

//     if (!document.fullscreenElement &&    // alternative standard method
//       !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
//       if (el.requestFullscreen) {
//         el.requestFullscreen();
//       } else if (el.mozRequestFullScreen) {
//         el.mozRequestFullScreen();
//       } else if (el.webkitRequestFullscreen) {
//         // el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
//       } else if (el.msRequestFullscreen) {

//         el.msRequestFullscreen();
//       }
//     }

//     setTimeout(() => {
//       this.isActive = true;
//     }, 500);
//   }

//   closeFullscreen(): void {
//     this.isFullscreen = false;
//     this.isActive = false;
//     const closeScreen = document as Document & {
//     mozCancelFullScreen(): Promise<void>;
//     webkitExitFullscreen(): Promise<void>;
//     msExitFullscreen(): Promise<void>;
//     };
//     this.isFullscreen = false;
//     if (closeScreen.exitFullscreen) {
//     closeScreen.exitFullscreen();
//     } else if (closeScreen.msExitFullscreen) {
//     closeScreen.msExitFullscreen();
//     } else if (closeScreen.mozCancelFullScreen) {
//     closeScreen.mozCancelFullScreen();
//     } else if (closeScreen.webkitExitFullscreen) {
//     closeScreen.webkitExitFullscreen();
//     }
//   }
//   //End Full screen 


//   joinConsultation(){
//     if(!this.patientFullName){
//       this.notificationService.errorToast("please enter Full name");
//       return;
//     }
//     if(!this.password || this.password.length != 4){
//       this.notificationService.errorToast("please enter 4 Digit passcode Received in Email");
//       return;
//     }
//     if(!this.purpose){
//       this.notificationService.errorToast("please select purpose");
//       return;
//     }
// let obj = {roomName: this.roomName, password: this.password}
// this.appointmentService.authenticatePatientOpen(obj).subscribe((res) => {
//  if(res && res['code'] != 200){
//   this.notificationService.errorToast("Passcode Incorrect");
//   return;
//  }

//  this.checkTime();

// }, (err) => {
//   this.notificationService.errorToast("Passcode Incorrect")
// })

   
//   }

//   checkTime(){
//     let obj = {roomName: this.roomName}
//     this.appointmentService.getAppointmentDetailsWithRoomNameOpen(obj).subscribe((res) => {
//       let startUTCString =  res['data']['startTimeUTC'];
//       let startDate =  momentTz(startUTCString);
//       let startTimeMilliseconds  = startDate.tz(this.timeZone).valueOf();
      
//       let endUTCString =  res['data']['endTimeUTC'];
//       let endDate =  momentTz(endUTCString);
//       let endTimeMilliseconds  = endDate.tz(this.timeZone).valueOf();

//       if(Date.now() >= endTimeMilliseconds){
//         this.notificationService.errorNotification("Appointment Time is Over");
//         this.router.navigate(['/']);
//       }

//   //    if((startTimeMilliseconds - Date.now()) > 120000){
      
//   //       this.notificationService.errorToast("Please note you can only join the Teleconsultation room 2 minutes prior to your booked time slot only");
//   //  return;
    
//   //     }
//       this.joinChat();
//       this.subscribeChat();
//       this.getDoctorName();

//     }, (err) => {
//       this.notificationService.errorToast("something went wrong");
// console.log(err);
//     })
//   }
// }

