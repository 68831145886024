import { environment } from "src/environments/environment";

/*
Description: Following is used to all constants of API(url)
*/
export class ElesAppConst {
  private static appurl =  environment.url;
  public static get APP_URL(): string {
    return this.appurl;
  }
  //video call services
  // public static get CALL_TURN_SERVER(): string {
  //   return environment.turn_url;
  // }
  public static get VIDEO_CALL(): string {
    return this.appurl + "video-call";
  }
  public static get VIDEO_CALL_OPEN(): string {
    return this.appurl + "f/video-call-open";
  }
  public static get MAKE_ROOM_COMPLETE(): string {
    return this.appurl + "make-room-complete";
  }

  public static get INVITE_OTHER_USER(): string {
    return this.appurl + "invite-other-user";
  }
  public static get GET_CHAT_WITH_APPOINTMENT_ID(): string {
    return this.appurl + "get-chat-with-appointmentId";
  }
  public static get GET_CHAT_WITH_APPOINTMENT_ID_OPEN(): string {
    return this.appurl + "f/get-chat-with-appointmentId-open";
  }
  public static get GET_RECORDINGS_LIST(): string {
    return this.appurl + "get-recordings-list";
  }
  public static get DELETE_RECORDINGS(): string {
    return this.appurl + "delete-recording";
  }
  public static get DOWNLOAD_RECORDING(): string {
    return this.appurl + "download-recording";
  }
  public static get PLAY_RECORDING(): string {
    return this.appurl + "play-recording";
  }
  public static get START_TYTO_VISIT(): string {
    return this.appurl + "start_tyto_visit";
  }
  public static get TYTO_REVIEW_URL(): string {
    return this.appurl + "get_tyto_review_url";
  }
  //staff login
  public static get USER_LOGIN(): string {
    return this.appurl + "f/staff/login";
  }
  public static get STAFF_LOGOUT(): string {
    return this.appurl + "staff/logout";
  }
  public static get FORGOT_PASSWORD(): string {
    return this.appurl + "f/resetPassword";
  }
  public static get RESET_PASSWORD(): string {
    return this.appurl + "f/changePassword";
  }
  public static get ADMIN_FORGOT_PASSWORD(): string {
    return this.appurl + "f/adminresetPassword";
  }
  public static get ADMIN_RESET_PASSWORD(): string {
    return this.appurl + "f/adminupdatepassword";
  }
  //define all the roles and type service here
  public static get GET_USER_ROLE_TYPE(): string {
    return this.appurl + "roleType";
  }
  public static get ADD_USER_ROLE_TYPE(): string {
    return this.appurl + "addRoleType";
  }
  public static get GET_USER_ROLETYPES(): string {
    return this.appurl + "getRoleTypes";
  }

  /* Sagar rokade 31 july 2019 */
  public static get GET_ALL_SUBSCRIPTION_DATA(): string {
    return this.appurl + "f/subscription/getAllSubscription";
  }
  public static get ADD_NEW_SUBSCRIPTION_DATA(): string {
    return this.appurl + "f/subscription/addSubscription";
  }
  public static get Delete_SUBSCRIPTION_DATA(): string {
    return this.appurl + "f/subscription/addSubscription";
  }
  public static get Update_SUBSCRIPTION_DATA(): string {
    return this.appurl + "f/subscription/updateSubscription";
  }
  public static get GET_SUBSCRIPTION_BY_COUNTRY(): string {
    return this.appurl + "f/subscription/country";
  }
  public static get GET_SUBSCRIPTION_BY_CURRENCY(): string {
    return this.appurl + "f/subscription/currency";
  }
  /* Sagar rokade 31 july 2019 */
  public static get GET_ALL_PLAN_DATA(): string {
    return this.appurl + "f/plan/getAllPlan";
  }
  public static get ADD_NEW_PLAN_DATA(): string {
    return this.appurl + "f/plan/addPlan";
  }
  public static get Delete_PLAN_DATA(): string {
    return this.appurl + "f/plan/addPlan";
  }
  public static get Update_PLAN_DATA(): string {
    return this.appurl + "f/plan/updatePlan";
  }
  public static get GET_PLAN_BY_COUNTRY(): string {
    return this.appurl + "f/plan/country";
  }
  public static get GET_PLAN_BY_CURRENCY(): string {
    return this.appurl + "f/plan/currency";
  }
  /* Sagar rokade 31 july 2019 */

  /* Sagar rokade 9 Aug 2019 */
  public static get SAVE_SCHEDULE_DATA(): string {
    return this.appurl + "f/schedule/saveschedule";
  }
  public static get GET_SCHEDULE_DATA(): string {
    return this.appurl + "f/schedule/getSaveSchedule";
  }
  /* Sagar rokade 9 Aug 2019 */

  /* Sagar rokade 23 sep 2019 */
  public static get SAVE_APPOINTMENT_DATA(): string {
    return this.appurl + "f/appointment/bookAppointment";
  }
  public static get BOOK_APPOINTMENT_BY_AGENT(): string {
    return this.appurl + "appointment/bookAppointmentByAgent";
  }
  public static get BOOK_APPOINTMENT_BY_DOCTOR(): string {
    return this.appurl + "appointment/bookAppointmentByDoctor";
  }
  public static get GET_APPOINTMENT_DATA(): string {
    return this.appurl + "f/appointment/getAppointment";
  }
  public static get GET_PATIENT_APPOINTMENT_DATA(): string {
    return this.appurl + "f/appointment/getPatientAppointment";
  }
  public static get GET_DOCTOR_APPOINTMENT_DATA(): string {
    return this.appurl + "f/appointment/getDoctorAppointment";
  }
  public static get GET_LAB_APPOINTMENT_DATA(): string {
    return this.appurl + "f/appointment/getLabAppointment";
  }
  public static get GET_HOSPITAL_LAB(): string {
    return this.appurl + "hospital/getHospitalLab";
  }
  public static get CREATE_HOSPITAL_LAB(): string {
    return this.appurl + "hospital/createHospitalLab";
  }
  public static get BOOK_LAB_APPOINTMENT(): string {
    return this.appurl + "f/booking_request_lab";
  }
  public static get CONFIRM_BOOK_MONNIFY(): string {
    return this.appurl + "monnify-webhook-client";
  }
  
  public static get JOIN_CONF_AS_PHONE_CALL(): string {
    return this.appurl + "f/appointment/join_as_phone_call";
  }
  public static get UPDATE_CONN_STATE(): string {
    return this.appurl + "f/appointment/update_conn_state";
  }
  public static get MAKE_TEST_FROM_APPOINTMENT(): string {
    return this.appurl + "f/appointment/makeTestFromAppointment";
  }
  public static get CANCELED_APPOINTMENT(): string {
    return this.appurl + "f/appointment/cancelAppointment";
  }
  public static get GET_ALL_APPOINTMENT(): string {
    return this.appurl + "f/appointment/getAllAppointment";
  }
  public static get RE_SCHEDULED_APPOINTMENT(): string {
    return this.appurl + "f/appointment/reScheduledAppointment";
  }
  public static get GET_HOSPITAL_PROVIDER_APPOINTMENT(): string {
    return this.appurl + "f/appointment/getHospitalProviderAppointments";
  }
  public static get UPLOAD_PATIENT_APPOINTMENT_DOC(): string {
    return this.appurl + "f/appointment/uploadAppointmentDocument";
  }

  /* Sagar rokade 23 sep 2019 */

  /* Sagar rokade 24 sep 2019 */
  public static get DELETE_APPOINTMENT_DATA(): string {
    return this.appurl + "f/appointment/deleteAppointment";
  }
  public static get GET_APPOINTMENT_DETAILS_DATA(): string {
    return this.appurl + "f/appointment/getAppointmentDetails";
  }

  public static get GET_SCHEDULE_BY_DATE_DATA(): string {
    return this.appurl + "f/schedule/getsavescheduleByDate";
  }

  /* Sagar rokade 24 sep 2019 */

  /* Sagar rokade  3 OCT 2019 */

  public static get VIDEO_RECORDE(): string {
    return this.appurl + "f/video/saveRecording";
  }

  /* Sagar rokade  3 OCT 2019 */

  //define all the email template here
  public static get GET_EMAIL_TEMPLATES(): string {
    return this.appurl + "getEmailTemplates";
  }
  public static get ADD_EMAIL_TEMPLATES(): string {
    return this.appurl + "addEmailTemplate";
  }
  public static get UPDATE_EMAIL_TEMPLATES(): string {
    return this.appurl + "emailTemplateUpdate";
  }
  public static get DELETE_EMAIL_TEMPLATES(): string {
    return this.appurl + "addEmailTemplate";
  }
  //define all the commission here
  public static get GET_ALL_COMMISSION_DATA(): string {
    return this.appurl + "getCommisssion";
  }
  public static get ADD_COMMISSION_DATA(): string {
    return this.appurl + "addCommission";
  }
  public static get UPDATE_COMMISSION_DATA(): string {
    return this.appurl + "commissionUpdate";
  }
  public static get DELETE_COMMISSION_DATA(): string {
    return this.appurl + "addCommission";
  }
  // define all test catalogue here
  public static get ADD_LAB_TEST_CATALOGUE_DATA(): string {
    return this.appurl + "addLabTestCatalogue";
  }
  public static get GET_ALL_LAB_TEST_CATALOGUE_DATA(): string {
    return this.appurl + "getLabTestCatalogue";
  }
  public static get DELETE_LAB_TEST_CATALOGUE_DATA(): string {
    return this.appurl + "deleteLabTestCatalogue";
  }
  public static get UPDATE_LAB_TEST_CATALOGUE_DATA(): string {
    return this.appurl + "updateLabTestCatalogue";
  }
  public static get GET_RECOMMEND_LAB_TEST_CATALOGUE_DATA(): string {
    return this.appurl + "getRecommendLabTestCatalogue";
  }
  public static get ADD_RECOMMEND_LAB_TEST_CATALOGUE_DATA(): string {
    return this.appurl + "addRecommendLabTestOffer";
  }
  public static get ADD_PATIENT_FROM_LAB(): string {
    return this.appurl + "addPatientFromLab";
  }
  public static get GET_PATIENT_RECOMMEND_LAB_TEST_CATALOGUE_DATA(): string {
    return this.appurl + "getPatientRecommendLabTestCatalogue";
  }
  public static get BOOK_LAB_TEST_ORDER(): string {
    return this.appurl + "bookLabTestOrder";
  }
  public static get UPLOAD_LAB_TEST_RESULT(): string {
    return this.appurl + "uploadLabTestResult";
  }
  public static get UPLOAD_LAB_TEST_RESULT_JSON(): string {
    return this.appurl + "uploadLabTestResultJSON";
  }
  public static get PUSH_RESULT_TO_PATIENT(): string {
    return this.appurl + "pushTestResultToPatient";
  }
  public static get CANCEL_LAB_TEST_ORDER(): string {
    return this.appurl + "cancelLabTestOrder";
  }
  public static get GET_LAB_TEST_COUNT(): string {
    return this.appurl + "getLabTestCount";
  }
  //define all the medicine here
  public static get GET_ALL_MEDICINE_DATA(): string {
    return this.appurl + "getMedicine";
  }
  public static get ADD_MEDICINE_DATA(): string {
    return this.appurl + "addMedicine";
  }
  public static get UPDATE_MEDICINE_DATA(): string {
    return this.appurl + "updateMedicine";
  }
  public static get DELETE_MEDICINE_DATA(): string {
    return this.appurl + "addMedicine";
  }
  //define all the medicineCatalogue here
  public static get GET_ALL_MEDICINE_CATALOGUE_DATA(): string {
    return this.appurl + "getMedicineCatalogue";
  }
  public static get GET_MEDICINE_CATALOGUE_BY_ID(): string {
    return this.appurl + "getMedicineCatalogueById";
  }
  public static get GET_FILTERED_DRUGS_DATA(): string {
    return this.appurl + "f/getDrugCatalogues";
  }
  public static get ADD_MEDICINE_CATALOGUE_DATA(): string {
    return this.appurl + "addMedicineCatalogue";
  }
  public static get UPLOAD_MEDICINE_CATALOGUE_DATA(): string {
    return this.appurl + "uploadMedicineCatalogue";
  }
  public static get UPDATE_MEDICINE_CATALOGUE_DATA() {
    return this.appurl + "updateMedicineCatalogue";
  }
  public static get DELETE_MEDICINE_CATALOGUE_DATA() {
    return this.appurl + "deleteMedicineCatalogue";
  }
  //define all the prescription
  public static get GET_ALL_PRESCRIPTION_DATA(): string {
    return this.appurl + "getDoctorPrescriptionRequest";
  }
  public static get SAVE_PACKED_ORDER_DATA(): string {
    return this.appurl + "packedPrescriptionOrder";
  }
  public static get CANCEL_PRESCRIPTION_DATA(): string {
    return this.appurl + "cancelPrescriptionOrder";
  }
  public static get REFILL_PRESCRIPTION_DATA(): string {
    return this.appurl + "refillPrescriptionOrder";
  }
  public static get ADD_PRESCRIPTION_DATA(): string {
    return this.appurl + "addDoctorPrescriptionRequest";
  }
  public static get UPDATE_PRESCRIPTION_DATA(): string {
    return this.appurl + "updateDoctorPrescriptionRequest";
  }
  public static get DELETE_PRESCRIPTION_DATA(): string {
    return this.appurl + "deleteDoctorPrescriptionRequest";
  }
  public static get GET_PRESCRIPTION_COUNT(): string {
    return this.appurl + "getPrescriptionCount";
  }
  public static get GET_DELIVERY_FARE_GOCADA(): string {
    return this.appurl + "f/getDeliveryFareGogada";
  }
  public static get GET_PRESCRIPTION_DELIVERY_STATUS(): string {
    return this.appurl + "getPrescriptionDeliveryStatus";
  }
  //define all the CMS's here
  public static get GET_ABOUT_CMS_DATA(): string {
    return this.appurl + "f/getCMS";
  }
  public static get GET_ALL_CMS_DATA(): string {
    return this.appurl + "getCms";
  }
  public static get ADD_CMS_DATA(): string {
    return this.appurl + "addCms";
  }
  public static get UPDATE_CMS_DATA(): string {
    return this.appurl + "updateCms";
  }
  public static get DELETE_CMS_DATA(): string {
    return this.appurl + "addCms";
  }
  //define all the permission's here
  public static get GET_USER_ASSIGN_PERMISSION(): string {
    return this.appurl + "user/assignPermission";
  }
  public static get GET_PARENT_PERMISSIONS_LIST(): string {
    return this.appurl + "permission/parent";
  }
  public static get GET_USER_PERMISSIONS_LIST(): string {
    return this.appurl + "permission/list";
  }
  public static get GET_PERMISSION(): string {
    return this.appurl + "permission";
  }
  public static get GET_ROLE_PERMISSIONS_LIST(): string {
    return this.appurl + "user/assignPermissionToRole";
  }
  public static get ADD_ROLE_TYPE_DETAIL(): string {
    return this.appurl + "roleType";
  }
  public static get ADD_ROLE_TYPE(): string {
    return this.appurl + "addRoleType";
  }

  public static get PAY_STACK_PAYMENT(): string {
    return this.appurl + "f/payStackPayment";
  }
  public static get PAY_PAL_PAYMENT(): string {
    return this.appurl + "f/payPalPayment";
  }
  public static get EXECUTE_PAY_PAL_PAYMENT(): string {
    return this.appurl + "f/executePayment";
  }

  public static get SAVE_VIDEOROOMS_DATA(): string {
    return this.appurl + "f/videorooms/saveroomsdata";
  }
  //Patients
  public static get UPDATE_PATIENT_ALLERGY_DETAIL(): string {
    return this.appurl + "patient-allergy-detail";
  }
  public static get PATIENT_ALLERGY_DETAIL_LIST(): string {
    return this.appurl + "patient-allergy-list";
  }
  public static get DEL_PATIENT_ALLERGY_DETAIL(): string {
    return this.appurl + "delete-patient-allergy";
  }
  public static get PATIENT_ALLERGY_DETAIL_STATUS(): string {
    return this.appurl + "patient-allergy-status";
  }
  public static get PATIENT_ALLERGY_DETAIL(): string {
    return this.appurl + "patient-allergy-detail";
  }
  public static get PATIENT_MEDICAL_HISTORY(): string {
    return this.appurl + "patient-medical-history";
  }
  public static get PATIENT_FAMILY_HISTORY(): string {
    return this.appurl + "patient-family-history";
  }
  public static get PATIENT_SOCIAL_HISTORY(): string {
    return this.appurl + "patient-social-history";
  }
  public static get PATIENT_MEDICATION(): string {
    return this.appurl + "patient-medication";
  }
  public static get PATIENT_IMMUNIZATION(): string {
    return this.appurl + "patient-immunization";
  }
  public static get DEL_PATIENT_MEDICAL_HISTORY(): string {
    return this.appurl + "delete-patient-medical-history";
  }
  public static get DEL_PATIENT_FAMILY_HISTORY(): string {
    return this.appurl + "delete-patient-family-history";
  }
  public static get DEL_PATIENT_SOCIAL_HISTORY(): string {
    return this.appurl + "delete-patient-social-history";
  }
  public static get DEL_PATIENT_IMMUNIZATION(): string {
    return this.appurl + "delete-patient-immunization";
  }
  public static get DEL_PATIENT_MEDICATION(): string {
    return this.appurl + "delete-patient-medication";
  }
  public static get PATIENT_MEDICAL_HISTORY_STATUS(): string {
    return this.appurl + "patient-medical-history-status";
  }
  public static get PATIENT_MEDICAL_FAMILY_STATUS(): string {
    return this.appurl + "patient-family-history-status";
  }
  public static get PATIENT_SOCIAL_HISTORY_STATUS(): string {
    return this.appurl + "patient-social-history-status";
  }
  public static get PATIENT_IMMUNIZATION_STATUS(): string {
    return this.appurl + "patient-immunization-status";
  }
  public static get PATIENT_MEDICATION_STATUS(): string {
    return this.appurl + "patient-medication-status";
  }
  public static get PATIENT_VERIFICATION(): string {
    return this.appurl + "patient-verification";
  }
  public static get PATIENT_MEDICAL_HISTORY_LIST(): string {
    return this.appurl + "patient-medical-history-list";
  }
  public static get PATIENT_MEDICAL_FAMILY_LIST(): string {
    return this.appurl + "patient-family-history-list";
  }
  public static get PATIENT_SOCIAL_FAMILY_LIST(): string {
    return this.appurl + "patient-social-history-list";
  }
  public static get PATIENT_IMMUNIZATION_LIST(): string {
    return this.appurl + "patient-immunization-list";
  }
  public static get PATIENT_MEDICATION_LIST(): string {
    return this.appurl + "patient-medication-list";
  }
  public static get PHARMACY_LIST(): string {
    return this.appurl + "pharmacy-list";
  }
  public static get PREFERRED_PHARMACY_LIST(): string {
    return this.appurl + "patient-preferred-pharmacy";
  }
  public static get DOCTORS_LIST(): string {
    return this.appurl + "f/doctors-list";
  }
  public static get PATIENT_TELEDIAGNOSIS_DATA(): string {
    return this.appurl + "f/getTelediagnosisV2";
  }
  public static get DOCTOR_FEEDBACK_TELEDIAGNOSIS_DATA(): string {
    return this.appurl + "doctorFeedbackTelediagnosisReport";
  }
  public static get PATIENT_MEDICAL_DATA(): string {
    return this.appurl + "get_patient_medical_data";
  }
  public static get PATIENT_PAIR_TYTO_DEVICE(): string {
    return this.appurl + "patient-pair-tyto_device";
  }

  public static get PATIENT_CHECK_TYTO_DEVICE(): string {
    return this.appurl + "patient-check-tyto_device";
  }
  public static get BOOK_AI_HEALTH_CHECKER(): string {
    return this.appurl + "book-patient-ai-healthchecker";
  }
  public static get SAVE_AI_HEALTH_CHECKER_RESULT(): string {
    return this.appurl + "save-patient-ai-health-record";
  }
  public static get SHARE_AI_HEALTH_CHECKER_RESULT(): string {
    return this.appurl + "share-patient-ai-health-record";
  }
  public static get GET_AI_HEALTH_CHECKER_RESULT(): string {
    return this.appurl + "get-patient-ai-health-record";
  }

  public static get GET_AI_HEALTH_CHECKER_RESULT_COUNT(): string {
    return this.appurl + "get-patient-ai-health-result-count";
  }



  // notification
  public static get SEND_NOTIFICATION(): string {
    return this.appurl + "send-notification";
  }
  public static get GET_MY_INBOX_NOTIFICATION(): string {
    return this.appurl + "get-my-inbox-notification";
  }
  public static get GET_MY_SENT_NOTIFICATION(): string {
    return this.appurl + "get-my-sent-notification";
  }
  public static get GET_UNREAD_NOTIFICATION_COUNT(): string {
    return this.appurl + "get-unread-notification-count";
  }
  public static get UPDATE_NOTIFICATION_STATUS(): string {
    return this.appurl + "update-notification";
  }
  public static get GET_MY_NOTIFICATION(): string {
    return this.appurl + "get-my-notification";
  }
  public static get GET_MY_LATEST_ACTIVITY(): string {
    return this.appurl + "get-latest-activity";
  }

  //Staffs
  public static get ADD_STAFF(): string {
    return this.appurl + "f/add-staff";
  }
  public static get UPLOAD_MULTIPLE_STAFF(): string {
    return this.appurl + "f/upload-multiple-staff";
  }
  public static get PATCH_STAFF(): string {
    return this.appurl + "staff";
  }
  public static get GET_STAFFS(): string {
    return this.appurl + "getStaffs";
  }
  public static get REMOVE_LAFIYA_PROVIDER_FROM_CLINIC(): string {
    return this.appurl + "remove_l_provider_from_clinic";
  }
  
  public static get GET_STAFF_DETAIL(): string {
    return this.appurl + "f/get-details";
  }
  public static get GET_MY_DETAIL(): string {
    return this.appurl + "f/get-my-details";
  }
  public static get GET_STAFF_SUBSCRIPTION(): string {
    return this.appurl + "getStaffSubscriptions";
  }
  public static get GET_STAFF_PLANS(): string {
    return this.appurl + "getStaffPlans";
  }
  public static get GET_STAFF_PLAN(): string {
    return this.appurl + "getStaffPlan";
  }
  
  public static get GET_MY_PAYMENT(): string {
    return this.appurl + "getMyPayments";
  }
  public static get GET_MY_WALLET(): string {
    return this.appurl + "f/getMyWallet";
  }

  public static get GET_PATIENT_REPORT(): string {
    return this.appurl + "f/getPatientReport";
  }
  public static get GET_DOCTOR_REPORT(): string {
    return this.appurl + "f/getDoctorReport";
  }

  public static get GET_MY_WALLET_BALANCE(): string {
    return this.appurl + "f/getMyWalletBalance";
  }
  public static get GET_ALL_WALLET(): string {
    return this.appurl + "getAllWallet";
  }
  public static get GET_ADMIN_WALLET(): string {
    return this.appurl + "f/AdminWallet";
  }
  public static get GET_ADMIN_WALLET_BALANCE(): string {
    return this.appurl + "f/getAdminWalletBalance";
  }
  public static get GET_ADMIN_ALL_WALLET(): string {
    return this.appurl + "getAdminAllWallet";
  }
  public static get GET_ADMIN_PAYMENT(): string {
    return this.appurl + "getAdminPayments";
  }
  public static get GET_ADMIN_PAYMENT_REPORT(): string {
    return this.appurl + "f/getAdminPaymentReport";
  }
  public static get GET_ADMIN_EARNING_REPORT(): string {
    return this.appurl + "f/getAdminEarningReport";
  }
  public static get GET_ADMIN_PROVIDER_REPORT(): string {
    return this.appurl + "f/getAdminProviderReport";
  }
  public static get GET_ADMIN_APPOINTMENT_REPORT(): string {
    return this.appurl + "f/getAdminAppointmentReport";
  }
  public static get GENERATE_VOUCHER(): string {
    return this.appurl + "f/generateVouchers";
  }

  public static get GET_LOTS(): string {
    return this.appurl + "f/lots";
  }

  public static get GET_VOUCHERS(): string {
    return this.appurl + "f/vouchers";
  }

  public static get GET_ADMIN_PAYMENT_TOTAL(): string {
    return this.appurl + "getAdminPaymentTotal";
  }
  public static get GET_ADMIN_REVENUE(): string {
    return this.appurl + "getAdminRevenue";
  }

  public static get GET_PAYMENT_METHODS(): string {
    return this.appurl + "paymentMethod";
  }
  public static get ADD_PAYMENT_INTENT(): string {
    return this.appurl + "f/stripCreateIntent";
  }
  public static get STRIP_PAYMENT_METHOD(): string {
    return this.appurl + "f/stripCreatePaymentMethod";
  }

  public static get GET_ACTIVE_PAYMENT_METHODS(): string {
    return this.appurl + "activePaymentMethod";
  }
  public static get GET_MY_CARD(): string {
    return this.appurl + "getMyCards";
  }
  public static get GET_STAFF_CARD_BY_ADMIN(): string {
    return this.appurl + "getStaffCardsByAdmin";
  }
  public static get ADD_CARD_DETAIL(): string {
    return this.appurl + "f/card";
  }
  public static get UPDATE_CARD_DETAIL(): string {
    return this.appurl + "f/card";
  }
  public static get DELETE_CARD_DETAIL(): string {
    return this.appurl + "f/card";
  }
  public static get ADD_WALLET_DETAIL(): string {
    return this.appurl + "f/MyWallet";
  }
  public static get UPDATE_WALLET_DETAIL(): string {
    return this.appurl + "f/Wallet";
  }
  public static get ADD_ADMIN_WALLET_DETAIL(): string {
    return this.appurl + "f/AdminWallet";
  }
  public static get ADD_MEDICENE_PAYMENT(): string {
    return this.appurl + "f/MedicenePayment";
  }
  public static get UPDATE_ADMIN_WALLET_DETAIL(): string {
    return this.appurl + "f/AdminWallet";
  }
  public static get UPLOAD_STAFF_IMAGE(): string {
    return this.appurl + "f/StaffImageUpload";
  }
  public static get UPLOAD_STAFF_SIGNATURE(): string {
    return this.appurl + "uploadStaffSignature";
  }
  public static get UPLOAD_STAFF_DOCUMENT(): string {
    return this.appurl + "f/uploadStaffDocuments";
  }
  public static get UPLOAD_STAFF_MULTIPLE_DOCUMENT(): string {
    return this.appurl + "f/uploadStaffMultipleDocuments";
  }
  public static get UPLOAD_STAFF_REPRESENTATIVE_DOCUMENT(): string {
    return this.appurl + "uploadStaffRepresentativeDocument";
  }
  public static get GET_MY_DOCUMENTS(): string {
    return this.appurl + "get-my-documents";
  }
  public static get UPDATE_STAFF_DETAIL(): string {
    return this.appurl + "f/update-patient";
  }
  public static get UPDATE_STAFF_SUBSCRIPTION_PLAN(): string {
    return this.appurl + "staff/subscription-update";
  }
  public static get UPDATE_STAFF_PLAN(): string {
    return this.appurl + "staff/plan-update";
  }
  public static get ADD_STAFF_DETAIL(): string {
    return this.appurl + "f/register-patient";
  }
  public static get SAVE_DOCTOR_SCHEDULE(): string {
    return this.appurl + "save-doctor-schedule";
  }
  public static get SAVE_STAFF_REPRESENTATIVE(): string {
    return this.appurl + "saveStaffRepresentative";
  }
  public static get GET_STAFF_REPRESENTATIVE(): string {
    return this.appurl + "getStaffRepresentative";
  }
  public static get SAVE_DOCTOR_CONSULTANCY_FEE(): string {
    return this.appurl + "save-staff-consultancy-fees";
  }
  public static get GET_DOCTOR_CONSULTANCY_FEE(): string {
    return this.appurl + "get-staff-consultation-fees";
  }
  public static get GET_CONSULTANCY_FEE(): string {
    return this.appurl + "get-consultation-fees";
  }
  public static get GET_DOCTOR_SCHEDULE(): string {
    return this.appurl + "get-doctor-schedule";
  }
  public static get REQUEST_OTP(): string {
    return this.appurl + "f/register-patient";
  }
  public static get SETUP_CONSULTATION_DURATION(): string {
    return this.appurl + "setupConsultationDuration";
  }
  public static get GET_CONSULTATION_DURATION(): string {
    return this.appurl + "getConsultationDuration";
  }
  public static get SETUP_AI_HEALTHCHECKER_FEES(): string {
    return this.appurl + "setupAiHealthCheckerFees";
  }
  public static get GET_AI_HEALTHCHECKER_FEES(): string {
    return this.appurl + "getAiHealthCheckerFees";
  }
  public static get VERIFY_OTP(): string {
    return this.appurl + "f/verifyOtp";
  }

  public static get ADD_DOCTOR_DETAIL(): string {
    return this.appurl + "f/add-staff";
  }
  public static get REGISTERT_STAFF(): string {
    return this.appurl + "f/registerStaff";
  }
  public static get ACTIVATE_ACCOUNT(): string {
    return this.appurl + "f/accountActivation";
  }
  public static get REGISTER_STAFF(): string {
    return this.appurl + "f/add-staff";
  }

  public static get RESETPASSWORD_STAFF(): string {
    return this.appurl + "f/reset-password";
  }

  public static get GET_STAFF(): string {
    return this.appurl + "f/get-details";
  }

  public static get GET_COUNTRY_BY_IP(): string {
    return this.appurl + "f/country_by_ip";
  }

  public static get GET_COUNTRIES(): string {
    return this.appurl + "f/get-countries";
  }
  public static get GET_STATES(): string {
    return this.appurl + "f/get-states";
  }
  public static get GET_CITIES(): string {
    return this.appurl + "f/get-cities";
  }
  public static get GET_USERS_BY_ROLES(): string {
    return this.appurl + "get-users-by-roles";
  }
  public static get GET_LIST_CURRENCY(): string {
    return this.appurl + "get-currencies";
  }
  public static get GET_CURRENCY(): string {
    return this.appurl + "currency";
  }
  public static get GET_CONVERTED_CURRENCY(): string {
    return this.appurl + "convert-currency";
  }
  public static get GET_HOSPITAL_PROVIDERS(): string {
    return this.appurl + "getHospitalProviders";
  }
  public static get UPDATE_HOSPITAL_PROVIDER(): string {
    return this.appurl + "updateHospitalProvider";
  }
  public static get GET_LAFIYA_PROVIDERS(): string {
    return this.appurl + "getLafiyaProviders";
  }
  
  public static get ADD_LAFIYA_PROVIDERS_TO_CLIINIC(): string {
    return this.appurl + "addLafiyaProvidersToClinic";
  }
  public static get GET_HOSPITAL_PATIENTS(): string {
    return this.appurl + "hospital/getHospitalPatients";
  }
  public static get GET_HMO_PATIENTS(): string {
    return this.appurl + "hmo/getHMOPatients";
  }
  public static get GET_STAFF_COUNT(): string {
    return this.appurl + "count/getRoleCount";
  }
  public static get GET_APPOINTMENT_COUNT(): string {
    return this.appurl + "appointment/getAppointmentCount";
  }
  public static get GET_DOCTOR_PATIENTS(): string {
    return this.appurl + "getDoctorPatients";
  }
  public static get SEARCH_PATIENT(): string {
    return this.appurl + "f/search-patient";
  }
  public static get GET_STAFF_SCHEDULE_SLOTS(): string {
    return this.appurl + "getStaffScheduleSlots";
  }
  public static get GET_DOCTOR_SCHEDULE_SLOTS(): string {
    return this.appurl + "getDoctorScheduleSlots";
  }
  public static get GET_DOCTOR_FEEDBACK_COUNT(): string {
    return this.appurl + "getAggregateDoctorFeedback";
  }
  // CMS

  public static get GET_CMS_HOME_PAGE(): string {
    return this.appurl + "f/getCmsHome";
  }
  public static get POST_CONTACT_US(): string {
    return this.appurl + "f/contact-us";
  }

  // Blogs
  public static get GET_BLOGS(): string {
    return this.appurl + "f/get-blogs";
  }

  public static get SAVE_COMMENT(): string {
    return this.appurl + "f/save-comment";
  }

  // news letter
  public static get SUBSCRIBE_NEWSLETTER(): string {
    return this.appurl + "f/subscribe-newsletter";
  }

  public static get GET_SUBSCRIBE_NEWSLETTER_USERS(): string {
    return this.appurl + "get-subscribe-newsletter-user";
  }

  public static get SAVE_NEWSLETTER(): string {
    return this.appurl + "save-newsletter";
  }

  public static get GET_NEWSLETTER(): string {
    return this.appurl + "get-newsletter";
  }

  public static get DELETE_NEWSLETTER(): string {
    return this.appurl + "delete-newsletter";
  }

  public static get UPDATE_NEWSLETTER(): string {
    return this.appurl + "update-newsletter";
  }

  public static get UPLOAD_GALLERY_IMAGE(): string {
    return this.appurl + "gallery-image/upload";
  }

  public static get UPDATE_GALLERY_IMAGE(): string {
    return this.appurl + "gallery-image/update";
  }

  public static get GET_GALLERY_IMAGES(): string {
    return this.appurl + "f/gallery-image/get";
  }

  public static get DELETE_GALLERY_IMAGE(): string {
    return this.appurl + "gallery-image/delete";
  }

  public static get SAVE_PATIENT_COUNTRY_RULE(): string {
    return this.appurl + "save-patient-country-rule";
  }

  public static get UPDATE_PATIENT_COUNTRY_RULE(): string {
    return this.appurl + "update-patient-country-rule";
  }

  public static get GET_PATIENT_COUNTRY_RULES(): string {
    return this.appurl + "get-patient-country-rules";
  }

  public static get DELETE_PATIENT_COUNTRY_RULE(): string {
    return this.appurl + "delete-patient-country-rule";
  }

  public static get GET_GEO_CORDINATE(): string {
    return this.appurl + "f/getGeoCordinate";
  }
  //verification
  public static get GET_PENDING_PROFILES(): string {
    return this.appurl + "get-users";
  }
  public static get APPROVE_USER(): string {
    return this.appurl + "approve-user";
  }
  public static get APPROVE_DOC(): string {
    return this.appurl + "approve-document";
  }
  public static get SEND_VERIFICATION_COMMENT_NOTIFICATION(): string {
    return this.appurl + "send-verification-comment-notification";
  }
  public static get UPLOAD_CONSENT_SIGNATURE(): string {
    return this.appurl + "saveConsent";
  }
  public static get UPLOAD_SCREENSHOT(): string {
    return this.appurl + "upload_screenshot";
  }
  public static get UPLOAD_MEDICAL_DATA(): string {
    return this.appurl + "create_patient_medical_data";
  }
  public static get UPLOAD_CONSENT_SIGNATURE_OPEN(): string {
    return this.appurl + "f/saveConsent-open";
  }
  public static get GET_APPOINTMENT_DETAILS_WITH_ROOM_NAME(): string {
    return this.appurl + "get-appintment-details-with-roomName";
  }
  public static get GET_APPOINTMENT_DETAILS_WITH_ROOM_NAME_OPEN(): string {
    return this.appurl + "f/get-appintment-details-with-roomName-open";
  }
  public static get UPDATE_APPOINTMENT_WITH_ROOM_NAME(): string {
    return this.appurl + "update-appointment";
  }
  public static get GET_HEALTH_RECORDS(): string {
    return this.appurl + "fetch-health-records";
  }
  public static get SAVE_VITAL_INFORMATION(): string {
    return this.appurl + "saveVitalInformation";
  }
  public static get GET_VITAL_INFORMATION(): string {
    return this.appurl + "getVitalInformation";
  }

  public static get SEARCH_MEDICINE(): string {
    return this.appurl + "search-medicine";
  }
  public static get STAFF_REFERRAL(): string {
    return this.appurl + "staff-referral";
  }
  public static get GET_STAFF_REFERRAL(): string {
    return this.appurl + "get-staff-referral";
  }
  public static get GET_ALL_STAFF_REFERRAL(): string {
    return this.appurl + "get-all-staff-referral";
  }
  public static get SAVE_FEEDBACK(): string {
    return this.appurl + "save-feedback";
  }
  public static get GET_USER_FEEDBACKS(): string {
    return this.appurl + "user-feedbacks";
  }
  public static get GET_PATIENT_MEDICAL_RECORDS(): string {
    return this.appurl + "fetch-medical-records";
  }

  public static get AUTHENTICATE_PATIENT_OPEN(): string {
    return this.appurl + "f/authenticate-patient-open";
  }

  public static get DOCTOR_FEEDBACKS(): string {
    return this.appurl + "f/doctor-feedbacks";
  }

  public static get GENERATE_MEMBERSHIP_ID(): string {
    return this.appurl + "generateMembershipId";
  }

  public static get GET_MEMBERSHIP_ID(): string {
    return this.appurl + "getMemberships";
  }

  public static get DELETE_MEMBERSHIP_ID(): string {
    return this.appurl + "deleteMembership";
  }

  public static get PATIENT_REGISTER_MEMBERSHIP_ID(): string {
    return this.appurl + "patientRegisterMembershipId";
  }

  public static get GET_REQUESTED_MEMBERSHIP_ID(): string {
    return this.appurl + "getRequestedMemberships";
  }

  public static get APPROVE_REQUESTED_MEMBERSHIP_ID(): string {
    return this.appurl + "approveMembershipRequest";
  }

  public static get GET_PATIENT_MEMBERSHIP_ID(): string {
    return this.appurl + "getPatientMembershipId";
  }

  public static get POST_BLOG(): string {
    return this.appurl + "create-blog";
  }

  public static get UPDATE_BLOG(): string {
    return this.appurl + "update-blog";
  }

  public static get DELETE_BLOG(): string {
    return this.appurl + "delete-blog";
  }

  public static get GET_BLOGS_LIST(): string {
    return this.appurl + "get-blogs";
  }
}
