import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
import { ForgetPasswordComponent } from "./auth/forget-password/forget-password.component";
// import { MobileTelehealthComponent } from './auth/mobile-telehealth/mobile-telehealth.component';
import { PaymentsuccessComponent } from "./paymentsuccess/paymentsuccess.component";
import { PaymenterrComponent } from "./paymenterr/paymenterr.component";
import { OpenConsultationComponent } from "./layout/users/open-consultation/open-consultation.component";
import { USGScannerComponent } from './layout/users/usg_scanner/usg_scanner_component';
import { OpenTeleconsultationGuard } from './@core/guards/teleconsultation-open.guard';
import { AuthGuard } from "./@core/guards/auth.guard";

const routes: Routes = [
  {
    path: "ht",
    //loadChildren: () =>
    // import("./auth/admin-login/admin-login.module").then(
    //   m => m.AdminLoginModule
    // )
    loadChildren: () =>
      import("./shared/clinicLand/clinicLand.module").then(
        m => m.ClinicLandModule
      )
  },
  {
    path: "video",
    loadChildren: () =>
      import("./shared/video/video.module").then(video => video.VideoModule)
  },
  {
    path: "recordcall",
    loadChildren: () =>
      import("./shared/recordcall/recordcall.module").then(
        recordcall => recordcall.RecordcallModule
      )
  },
  {
    path: "rooms",
    loadChildren: () =>
      import("./shared/allroomsinfo/allroomsinfo.module").then(
        rooms => rooms.AllroomsinfoModule
      )
  },
  // AllroomsinfoModule
  {
    path: "lafiya",
    loadChildren: () =>
      import("./layout/layout.module").then(m => m.LayoutModule)
  },
  {
    path: "password/:id",
    loadChildren: () =>
      import("./password/password.module").then(m => m.PasswordModule)
  },
  {
    path: "activation/:id",
    loadChildren: () =>
      import("./activation/activation.module").then(m => m.ActivationModule)
  },
  {
    path: "lafiya-admin",
    loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: { expectedRole: "Admin" }
  },
  {
    path: "",
    loadChildren: () =>
      import("./auth/home/home.module").then(m => m.HomeModule)
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./auth/admin-login/admin-login.module").then(
        m => m.AdminLoginModule
      )
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./auth/signup/signup.module").then(m => m.SignupModule),
      data: {
        seo: {
          title: 'SignUp | Lafiya Telehealth',
          metaTags: [
            { name: 'description', content: 'SignUp on our website Lafiya Telehealth ' },
          ]
        }
      }
  },
  {
    path: "error",
    loadChildren: () =>
      import("./auth/server-error/server-error.module").then(
        m => m.ServerErrorModule
      )
  },
  {
    path: "access-denied",
    loadChildren: () =>
      import("./access-denied/access-denied.module").then(
        m => m.AccessDeniedModule
      )
  },
  {
    path: "not-found",
    loadChildren: () =>
      import("./auth/not-found/not-found.module").then(m => m.NotFoundModule)
  },
  { path: "change-password/:id", component: ChangePasswordComponent },
  { path: "forget-password", component: ForgetPasswordComponent },
  // { path: 'mobile-telehealth', component: MobileTelehealthComponent },
  { path: "changePassword", component: ChangePasswordComponent },
  { path: "paymentsuccess", component: PaymentsuccessComponent },
  { path: "open-consultation/:id", canActivate: [OpenTeleconsultationGuard],  component: OpenConsultationComponent },
  { path: "paymenterr", component: PaymenterrComponent },
  { path: 'carepointshop', loadChildren: () => import('./auth/carepoint-shop/carepoint-shop.module').then(m => m.CarepointShopModule) },
  {path: "usg_scanner/:id", component: USGScannerComponent },
  { path: "**", redirectTo: "not-found" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
