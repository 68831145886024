import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { ElesAppConst } from '../../@core/utils/eles-app-constant'

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    public http: HttpClient
  ) { }

  getSubscriptionList(): Observable<any> {
    return this.http.post(ElesAppConst.GET_ALL_SUBSCRIPTION_DATA, {});
  }

  payStackPayment(data: any): Observable<any> {
    return this.http.post(ElesAppConst.PAY_STACK_PAYMENT, data);
  }

  payPalPayment(): Observable<any> {
    return this.http.post(ElesAppConst.PAY_PAL_PAYMENT,{});
  }

  executePayment(data: any): Observable<any> {
    return this.http.post(ElesAppConst.EXECUTE_PAY_PAL_PAYMENT,data);
  }

  getHomeCmsData(data: any): Observable<any> {
    return this.http.post(ElesAppConst.GET_CMS_HOME_PAGE,data);
  }

  contactUs(data:any):Observable<any>{
    return this.http.post(ElesAppConst.POST_CONTACT_US,data);
  }

  getBlogs(data={}):Observable<any>{
    return this.http.post(ElesAppConst.GET_BLOGS,data);
  }

  saveComment(data={}):Observable<any>{
    return this.http.post(ElesAppConst.SAVE_COMMENT,data);
  }

  saveNewsletter(data={}):Observable<any>{
    return this.http.post(ElesAppConst.SUBSCRIBE_NEWSLETTER,data);
  }

  subscribeNewsletter(data={}):Observable<any>{
    return this.http.post(ElesAppConst.SUBSCRIBE_NEWSLETTER,data);
  }

}
