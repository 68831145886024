import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { ElesAppConst } from '../@core/utils/eles-app-constant';
import { StringConst } from '../@core/utils/string-constants';
import { EncriptionDecriptionService } from '../@core/services/encription.decription.service';
import { AbastractData } from '../@core/services/abstract_data';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(public http: HttpClient, private encriptionDecriptionService: EncriptionDecriptionService) { }
    abastractData = new AbastractData();
    loginUser(value): Observable<any> {
        if (value) {
            return this.http.post(ElesAppConst.USER_LOGIN, this.abastractData.loginData(value));
        } else {
            return Observable.create((observer: Observer<any>) => observer.error(StringConst.INVALID_INFORMATION));
        }
    }
    logout(): Observable<any> {
      let staff_id=sessionStorage.getItem("staffId");
        //@ts-ignore
        if(global.presence_obj) {
        //@ts-ignore
        global.presence_obj.socketDisconnect();
      }
    return this.http.post(ElesAppConst.STAFF_LOGOUT, {staff_id});
    }
    forgetPassword(value): Observable<any> {
        if (value) {
            return this.http.post(ElesAppConst.FORGOT_PASSWORD, value);
        } else {
            return Observable.create((observer: Observer<any>) => observer.error(StringConst.INVALID_INFORMATION));
        }
    }

    changePassword(value): Observable<any> {
        if (value) {
            return this.http.post(ElesAppConst.RESET_PASSWORD, this.abastractData.changePasswordData(value));
        } else {
            return Observable.create((observer: Observer<any>) => observer.error(StringConst.INVALID_INFORMATION));
        }
    }
}
