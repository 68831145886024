import { SharedData } from './shared.config';

export class AbastractData {
    config = new SharedData();
    loginData(object) {
        return Object.assign({}, {
            email: this.config.encriptData(object.email),
            password: this.config.encriptData(object.password)
        });
    }
    changePasswordData(object) {
        return Object.assign({}, {
            id: this.config.encriptData(object.id),
            password: this.config.encriptData(object.password),
            confirm_password: this.config.encriptData(object.confirm_password),
        });
    }
    returnCurrentPageName(page) {
        return new Promise((resolve, reject) => {
            if (page === 'faq') {
               resolve('FAQ');
            } if (page === 'home') {
                resolve('Home');
            } if (page === 'aboutus') {
                resolve('About Us');
            } if (page === 'mobilekit') {
                resolve('Mobile TeleHealth Station Kit');
            } if (page === 'ourservices') {
                resolve('Our Services');
            } if (page === 'howitworks') {
                resolve('How It Works');
            } if (page === 'ourdoctor') {
                resolve('Our Doctor');
            } if (page === 'pricing') {
                resolve('Pricing');
            } if (page === 'contactUs') {
                resolve('Contact Us');
            } if (page === 'termsandcondition') {
                resolve('Terms And Condition');
            } if (page === 'privacypolicy') {
                resolve('Privacy Police');
            } if (page === 'images') {
                resolve('Images');
            } else {
                reject('');
            }
        });
    }
}

