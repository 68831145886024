/*

Description: Following is used to validate name, mobile number etc using REGEX
*/

import { ValidatorFn, FormGroup, FormGroupDirective, FormControl, NgForm, ValidationErrors, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';

/*

Description: Following is used to validate name, mobile number etc using REGEX
*/

export class TnFormErrorService {
    static childrenEqual: ValidatorFn = (formGroup: FormGroup) => {
        const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {});
        const isValid = otherControlNames.every(controlName => formGroup.get(controlName).value === formGroup.get(firstControlName).value);
        return isValid ? null : { childrenNotEqual: true };
    }
    // static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    //     return (control: AbstractControl): { [key: string]: any } => {
    //       if (!control.value) {
    //         // if control is empty return no error
    //         return null;
    //       }
      
    //       // test the value of the control against the regexp supplied
    //       const valid = regex.test(control.value);
      
    //       // if true, return no error (no error), else return error passed in the second parameter
    //       return valid ? null : error;
    //     };
    // }
    // static passwordMatchValidator(control: AbstractControl) {
    //     const password: string = control.get('password').value; // get password from our password form control
    //     const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    //     // compare is the password math
    //     if (password !== confirmPassword) {
    //       // if they don't match, set an error in our confirmPassword form control
    //       control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    //     }
    //   }
}

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.parent.invalid && control.touched && control.dirty;
    }
}

/**
* Collection of reusable RegExps
*/
export const regExps: { [key: string]: RegExp } = {
   password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
   confirmPassword: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
   email:  /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z]([a-z]*[a-z]\.[a-z]([a-z]*[a-z])?)*$/i,
   name: /^[a-zA-Z \.]*$/i,
   number: /^(0|[1-9]\d*)$/,
   mobile: /^\d{3}\d{3}\d{4}$/,

};

/**
 * Collection of reusable error messages
 */
export const errorMessages: { [key: string]: string } = {
    'password': 'Password must be between 7 and 15 characters, and contain at least one number and special character',
    'confirmPassword': 'Passwords must match',
    'email': 'Email must be a valid email address (username@domain)',
    'name': 'Please enter valid name',
    'number': 'Please enter valid Number',
    'mobile': 'Please enter valid phone number',
};

export const validation_messages = {
    'username': [
      { type: 'required', message: 'Username is required' },
      { type: 'minlength', message: 'Username must be at least 5 characters long' },
      { type: 'maxlength', message: 'Username cannot be more than 25 characters long' },
      { type: 'pattern', message: 'Your username must contain only numbers and letters' },
      { type: 'validUsername', message: 'Your username has already been taken' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid email' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Confirm password is required' },
      { type: 'areEqual', message: 'Password mismatch' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 5 characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
    ]
}


// 'email': [
//     { type: 'required', message: 'Email is required' },
//     { type: 'pattern', message: 'Enter a valid email' }
//   ],
//   'confirm_password': [
//     { type: 'required', message: 'Confirm password is required' },
//     { type: 'areEqual', message: 'Password mismatch' }
//   ],
//   'password': [
//     { type: 'required', message: 'Password is required' },
//     { type: 'minlength', message: 'Password must be at least 5 characters long' },
//     { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
//   ],