import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { LoginService } from "../../services/login.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/@core/services/notification.service";
import { EncriptionDecriptionService } from "src/app/@core/services/encription.decription.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
  public changePassword: FormGroup;
  public hide = true;
  public hideC = true;
  public _id: any;
  public isLoading = false;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private encrtiption: EncriptionDecriptionService
  ) {
    if (this.activatedRoute.snapshot.params["id"]) {
      this._id = this.activatedRoute.snapshot.params["id"];

    }
  }
  ngOnInit() {
    this.changePassword = this.formBuilder.group(
      {
        password: [
          null,
          [
            Validators.required,
            Validators.minLength(6),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{6,20}$/
            )
          ]
        ],
        confirm_password: [
          null,
          [
            Validators.required,
            Validators.minLength(6),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{6,20}$/
            )
          ]
        ]
      },
      { validators: this.checkPasswords }
    );
  }

  checkPasswords(RGF: AbstractControl) {
    if (RGF.get("password").value !== RGF.get("confirm_password").value) {
      return RGF.get("confirm_password").setErrors({ notmatch: true });
    } else {
      return RGF.get("confirm_password").setErrors(null);
    }
  }

  changePasswordForm() {
    if (this.changePassword.valid) {
      this.isLoading = true;
      const obj = this.changePassword.value;
      obj.id = this._id;
      this.loginService.changePassword(obj).subscribe(res => {
        this.isLoading = false;
        if (res["code"] === 200) {
          this.notificationService.successToast(res["message"]);
          this.router.navigate(["/login"]);
        } else {
          this.notificationService.infoToast(res["message"]);
        }
      });
    }
  }
}
