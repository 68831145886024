import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "src/app/@core/services/notification.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  ratingForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<RatingComponent>,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit() {
    this.ratingForm = this.fb.group({
      treatment: ["", Validators.required],
      behaviour: ["", Validators.required],
      conversation: ["", Validators.required],
      procedure: ["", Validators.required],
      satisfied: ["", Validators.required],
      comment: [""]
    });
  }

  submitFeedback(data) {
    this.dialogRef.close(this.ratingForm.value);
    this.ratingForm.reset();
  }

  onNoClick(data): void {
    this.dialogRef.close();
    this.ratingForm.reset();
  }
  onRatingOneSet(data) {
    this.ratingForm.get("treatment").setValue(data);
    this.ratingForm.updateValueAndValidity();
  }
  onRatingTwoSet(data) {
    this.ratingForm.get("behaviour").setValue(data);
    this.ratingForm.updateValueAndValidity();
  }
  onRatingThreeSet(data) {
    this.ratingForm.get("conversation").setValue(data);
    this.ratingForm.updateValueAndValidity();
  }
  onRatingFourSet(data) {
    this.ratingForm.get("procedure").setValue(data);
    this.ratingForm.updateValueAndValidity();
  }
  onRatingFiveSet(data) {
    this.ratingForm.get("satisfied").setValue(data);
    this.ratingForm.updateValueAndValidity();
  }

}
