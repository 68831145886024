import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: 'reminder-visit-dialog',
  templateUrl: './reminder-visit.html',
  styleUrls: ['./reminder-visit.scss']
})
export class ReminderVisitComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReminderVisitComponent>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }
  cancel(){
    this.dialogRef.close(true);
  }
  sendOk(){
    this.dialogRef.close(true);
    if(this.data.doctor){
      this.router.navigate(['/lafiya/doctor-consultation/'+this.data.roomName]);
    }else{
      this.router.navigate(['/lafiya/consultation/'+this.data.roomName]);
    }
  }
}
