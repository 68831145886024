export default {
  production: false,
  frontendUrl: "http://localhost:4200",
  aiCheckerUrl: "https://checker.lafiyatelehealth.com",
  url: "http://localhost:3000/api/",
  apiUrl: "http://localhost:3000",
  vidoe_socket_url: "https://localhost:5957/",
  Imageurl: "http://localhost:3000/upload/",
  currentLocation: {
    url: "https://ipinfo.io",
    token: "08972fe7a63b11",
  },
  //Stripe Publishable key
  stripKey:
    "pk_test_51HMHNMCv80spFZlu2dYotEEwhKK6XT5qb0G9SiGId5bnevhRPzGqOXIOkv9gDI1kGvDxbWpvctijwE2nzxPAn5oY00eT0n6l9F",
  monnifyKey: "MK_TEST_XZQCWA7X8D",
  monnifyContractCode: "9459124916",
  monnifyTestMode: true,
  //Paypal Cliend ID
  paypalClientId:
    "AV9Fi_QpEvVhWNnGDy9f6GmJrWsZtxcpE6hFh1Ybtgh86VKSZLuwqXsJNIZXvRece61SisH7SkTqYpWB",
};
